import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import arrowDown from '../../assests/admin/assets/img/arrow-down.png';
import CheckPermission from '../frontend/auth/CheckPermission';
import CTE from "react-click-to-edit"
import { useFormik, validateYupSchema, Form, Field, Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import {BASE_URL} from '../Config';



function FunnelFlow() {
    CheckPermission()
    const navigate = useNavigate();
    const BasicScema = Yup.object().shape({
        lander_page: Yup.string().required("Must Select a Landing Page!"),
        checkout_page: Yup.string().required("Must Select a Checkout Page!"),
        confirm_page: Yup.string().required("Must Select a Confirmation Page!"),
        funnel_name: Yup.string().required("Must Provide a Funnel Name!"),
    })

    const initialValues = {
        funnel_name: "",
        lander_page: "",
        checkout_page: "",
        confirm_page: "",
        upsell1: "",
        upsell2: "",
        upsell3: "",
        upsell4: "",
        upsell5: "",
    }


    const [landerFetch, setLanderFetch] = useState([]);
    const [checkoutFetch, setCheckoutFetch] = useState([]);
    const [confirmFetch, setConfirmFetch] = useState([]);
    const [upsellFetch, setUpsellFetch] = useState([]);


    async function getLanderList() {
        axios.get(`api/listNotUsedLander`).then(res => {
            // console.warn(res.data.data);
            setLanderFetch(res.data.data)
        })

    }

    async function getCheckoutList() {
        axios.get(`api/listCheckoutFunnel`).then(res => {
            // console.warn(res.data);
            setCheckoutFetch(res.data);
        })

    }
    async function getConfrimList() {
        axios.get(`api/listConfirmation`).then(res => {
            // console.warn(res.data.data);
            setConfirmFetch(res.data.data)
        })

    }
    async function getUpsellList() {
        axios.get(`api/listUpsell`).then(res => {
            // console.warn(res.data.data);
            setUpsellFetch(res.data.data);
        })

    }

    const handaleSubmit = (values, e) => {

        // console.log(e.dataslug)
        // console.log(values.lander_page)

        const formdata = new FormData();
        formdata.append('funnel_name', values.funnel_name);
        formdata.append('lander_id', values.lander_page);
        formdata.append('checkout_id', values.checkout_page);
        formdata.append('confirmation_id', values.confirm_page);

        if (values.upsell1 != undefined && values.upsell1 != 'Select Upsell Page') {
            formdata.append('upsell_1', values.upsell1);
        }
        if (values.upsell2 != undefined && values.upsell1 != 'Select Upsell Page') {
            formdata.append('upsell_2', values.upsell2);
        }
        if (values.upsell3 != undefined && values.upsell1 != 'Select Upsell Page') {
            formdata.append('upsell_3', values.upsell3);
        }
        if (values.upsell4 != undefined && values.upsell1 != 'Select Upsell Page') {
            formdata.append('upsell_4', values.upsell4);
        }
        if (values.upsell5 != undefined && values.upsell1 != 'Select Upsell Page') {
            formdata.append('upsell_5', values.upsell5);
        }
        //console.log(Object.fromEntries(formdata));
        axios.post(`api/add-flow`, formdata).then(res => {
            if (res.data.status === true) {

                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                navigate('/admin/funnelflowlist')

            }
        }).catch(err => {
            // console.log(err.response)
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });

    }
    const [upsell1, setUpsell1] = useState(false)
    const [upsell2, setUpsell2] = useState(false)
    const [upsell3, setUpsell3] = useState(false)
    const [upsell4, setUpsell4] = useState(false)
    const [upsell5, setUpsell5] = useState(false)
    const [count, setCount] = useState(1);
    const [landerURL, setlanderURL] = useState("")


    const handleOnChange = (e) => {
        var urlPrefix = BASE_URL+'brain/';
        if (e.target.name == 'lander_page') {
            const index = e.target.selectedIndex;
            const el = e.target.childNodes[index]
            var slug = el.getAttribute('data-slug');
            urlPrefix += slug
            console.warn(urlPrefix)
        }
    }

    useEffect(() => {
        getLanderList()
        getCheckoutList()
        getConfrimList()
        getUpsellList()
    }, []);


    return (
        <>
            <div className='col-md-12 '>
                <div className="pageheader card mb-4">
                    <div className="card-body row align-items-center">
                        <div className="page-name col-md-3">Add Funnel Flow </div>
                        {/* <div className="buttun_div  col-md-9"><button type="button" className="btn btn-primary" onClick={handleAddShow}>Add Crm Instance</button></div> */}
                    </div>
                </div>
            </div>
            <div className='row mx-0 my-4'>
                <div className='col-md-9 mx-auto'>
                    <div className='card cardBg-1'>
                        <Formik initialValues={initialValues} validationSchema={BasicScema} onSubmit={handaleSubmit}>
                            <Form className='funnelFlow-form'>
                                <div className='card '>
                                    <div className="card-header custom_card_hdr d-none">
                                        <span className='stepTitle'>Funnel Name</span>
                                    </div>
                                    <div className='card-body'>
                                        <div class="row align-items-center">
                                            <div class="page-name col-lg-3 col-md-4 mb-2 mb-md-0">Add Funnel Name</div>
                                            <div class="headerCus col-md-9">
                                                <Field className='form-control' type="text" name="funnel_name" ></Field>
                                            </div>
                                            <div class="col-md-9 ms-auto ">
                                                <p class="error-msg text-danger mb-0 "><ErrorMessage name="funnel_name" /> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card '>
                                    <div className="card-header custom_card_hdr">
                                        <span className='stepTitle'>Step 1 </span>
                                    </div>
                                    <div className='card-body'>
                                        {/* <span className='close-icon'>x</span> */}
                                        <div className="row align-items-center">
                                            <div className="page-name col-lg-3 col-md-4 mb-2 mb-md-0">Lander Page</div>
                                            <div className="headerCus col-md-9">
                                                <Field component="select" name="lander_page" className="form-control">
                                                    <option >Select Lander Page</option>
                                                    {landerFetch?.map(items => {
                                                        return (
                                                            <option value={items.id} key={items.id} data-slug={items.confirmation_slug} >
                                                                {items.lander_name}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>
                                            </div>
                                            <div className='col-md-9 ms-auto'><p className='error-msg text-danger mb-0'><ErrorMessage name="lander_page" /> </p></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='arrow_next'>
                                    <img src={arrowDown} className="img-fluid" alt="" />
                                </div>

                                <div className='card'>
                                    <div className="card-header custom_card_hdr">
                                        <span className='stepTitle'>Step 2 </span>
                                    </div>
                                    <div className='card-body'>
                                        {/* <span className='close-icon'>x</span> */}

                                        <div className="row align-items-center">
                                            <div className="page-name col-lg-3 col-md-4 mb-2 mb-md-0">Checkout Page</div>
                                            <div className="headerCus  col-md-9">
                                                <Field component="select" name="checkout_page" className="form-control" >
                                                    <option >Select Checkout Page</option>

                                                    {checkoutFetch?.map(items => {
                                                        return (
                                                            <option value={items.id} key={items.id} >
                                                                {items.funnel_name}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>

                                            </div>
                                            <div className='col-md-9 ms-auto'><p className='error-msg text-danger m-0'><ErrorMessage name="checkout_page" /> </p></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='arrow_next'>
                                    <img src={arrowDown} className="img-fluid" alt="" />
                                </div>

                                <div className={upsell1 ? 'card' : 'card d-none'}>
                                    <span className='close-icon' onClick={() => {
                                        setUpsell1(false)
                                        setCount(count - 1)
                                    }}>x</span>
                                    <div className="card-header custom_card_hdr">
                                        <span className='stepTitle'>Step 3 </span>
                                    </div>
                                    <div className='card-body'>
                                        <div className="row align-items-center">
                                            <div className="page-name col-lg-3 col-md-4 mb-2 mb-md-0">Upsell Page</div>
                                            <div className="headerCus  col-md-9">
                                                <Field component="select" name="upsell1" className="form-control" >
                                                    <option >Select Upsell Page</option>

                                                    {upsellFetch?.map(items => {
                                                        return (
                                                            <option value={items.id} key={items.id} >
                                                                {items.upsell_name}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>

                                            </div>
                                            <div className='col-md-9 ms-auto'><p className='error-msg text-danger m-0'><ErrorMessage name="checkout_page" /> </p></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={upsell1 ? 'arrow_next' : 'arrow_next d-none'}>
                                    <img src={arrowDown} className="img-fluid" alt="" />
                                </div>

                                <div className={upsell2 ? 'card' : 'card d-none'}>
                                    <span className='close-icon' onClick={() => {
                                        setUpsell2(false)
                                        setCount(count - 1)
                                    }}>x</span>
                                    <div className="card-header custom_card_hdr">
                                        <span className='stepTitle'>Step 4 </span>
                                    </div>
                                    <div className='card-body'>
                                        <div className="row align-items-center">
                                            <div className="page-name col-lg-3 col-md-4 mb-2 mb-md-0">Upsell Page</div>
                                            <div className="headerCus  col-md-9">
                                                <Field component="select" name="upsell2" className="form-control" >
                                                    <option >Select Upsell Page</option>

                                                    {upsellFetch?.map(items => {
                                                        return (
                                                            <option value={items.id} key={items.id} >
                                                                {items.upsell_name}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>

                                            </div>
                                            <div className='col-md-9 ms-auto'><p className='error-msg text-danger m-0'><ErrorMessage name="checkout_page" /> </p></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={upsell2 ? 'arrow_next' : 'arrow_next d-none'}>
                                    <img src={arrowDown} className="img-fluid" alt="" />
                                </div>

                                <div className={upsell3 ? 'card' : 'card d-none'}>
                                    <span className='close-icon' onClick={() => {
                                        setUpsell3(false)
                                        setCount(count - 1)
                                    }}>x</span>
                                    <div className="card-header custom_card_hdr">
                                        <span className='stepTitle'>Step 5 </span>
                                    </div>
                                    <div className='card-body'>
                                        <div className="row align-items-center">
                                            <div className="page-name col-lg-3 col-md-4 mb-2 mb-md-0">Upsell Page</div>
                                            <div className="headerCus  col-md-9">
                                                <Field component="select" name="upsell3" className="form-control" >
                                                    <option >Select Upsell Page</option>

                                                    {upsellFetch?.map(items => {
                                                        return (
                                                            <option value={items.id} key={items.id} >
                                                                {items.upsell_name}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>

                                            </div>
                                            <div className='col-md-9 ms-auto'><p className='error-msg text-danger m-0'><ErrorMessage name="checkout_page" /> </p></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={upsell3 ? 'arrow_next' : 'arrow_next d-none'}>
                                    <img src={arrowDown} className="img-fluid" alt="" />
                                </div>

                                <div className={upsell4 ? 'card' : 'card d-none'}>
                                    <span className='close-icon' onClick={() => {
                                        setUpsell4(false)
                                        setCount(count - 1)
                                    }}>x</span>
                                    <div className="card-header custom_card_hdr">
                                        <span className='stepTitle'>Step6 </span>
                                    </div>
                                    <div className='card-body'>

                                        <div className="row align-items-center">
                                            <div className="page-name col-lg-3 col-md-4 mb-2 mb-md-0">Upsell Page</div>
                                            <div className="headerCus  col-md-9">
                                                <Field component="select" name="upsell4" className="form-control" >
                                                    <option >Select Upsell Page</option>

                                                    {upsellFetch?.map(items => {
                                                        return (
                                                            <option value={items.id} key={items.id} >
                                                                {items.upsell_name}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>

                                            </div>
                                            <div className='col-md-9 ms-auto'><p className='error-msg text-danger m-0'><ErrorMessage name="checkout_page" /> </p></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={upsell4 ? 'arrow_next' : 'arrow_next d-none'}>
                                    <img src={arrowDown} className="img-fluid" alt="" />
                                </div>

                                <div className={upsell5 ? 'card' : 'card d-none'}>
                                    <span className='close-icon' onClick={() => {
                                        setUpsell5(false)
                                        setCount(count - 1)
                                    }}>x</span>
                                    <div className="card-header custom_card_hdr">
                                        <span className='stepTitle'>Step 7 </span>
                                    </div>
                                    <div className='card-body'>
                                        <div className="row align-items-center">
                                            <div className="page-name col-lg-3 col-md-4 mb-2 mb-md-0">Upsell Page</div>
                                            <div className="headerCus  col-md-9">
                                                <Field component="select" name="upsell5" className="form-control" >
                                                    <option >Select Upsell Page</option>

                                                    {upsellFetch?.map(items => {
                                                        return (
                                                            <option value={items.id} key={items.id} >
                                                                {items.upsell_name}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>

                                            </div>
                                            <div className='col-md-9 ms-auto'><p className='error-msg text-danger m-0'><ErrorMessage name="checkout_page" /> </p></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={upsell5 ? 'arrow_next' : 'arrow_next d-none'}>
                                    <img src={arrowDown} className="img-fluid" alt="" />
                                </div>


                                <div className={count >= 6 ? 'm-4 text-center text-md-end  d-none ' : 'm-4 text-center text-md-end'}>
                                    <button type='button' className='btn btn-primary ' onClick={() => {

                                        setCount(count + 1)
                                        switch (count) {
                                            case 1:
                                                setUpsell1(true)
                                                break;
                                            case 2:
                                                setUpsell2(true)
                                                break;
                                            case 3:
                                                setUpsell3(true)
                                                break;
                                            case 4:
                                                setUpsell4(true)
                                                break;
                                            case 5:
                                                setUpsell5(true)
                                                break;

                                        }
                                        console.log("count-->", count)
                                    }}> Add Upsell</button>
                                </div>


                                <div className='card'>
                                    <div className="card-header custom_card_hdr">
                                        <span className='stepTitle'>Final Step</span>
                                    </div>
                                    <div className='card-body'>
                                        {/* <span className='close-icon'>x</span> */}
                                        <div className="row align-items-center">
                                            <div className="page-name col-lg-3 col-md-4 mb-2 mb-md-0">Confirmation Page</div>
                                            <div className="headerCus  col-md-9">
                                                <Field component="select" name="confirm_page" className="form-control" >
                                                    <option value="">Select Confirmation Page</option>

                                                    {confirmFetch?.map(items => {
                                                        return (
                                                            <option value={items.id} key={items.id} >
                                                                {items.confirmation_name}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>

                                            </div>
                                            <div className='col-md-9 ms-auto'><p className='error-msg text-danger'><ErrorMessage name="confirm_page" /> </p></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center text-md-end m-4'>
                                    <button className='btn btn-primary btn-large' type='sumbit'> Save!</button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </>

    )
}

export default FunnelFlow