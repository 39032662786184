import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactTooltip from 'react-tooltip';


function SurveyFeedback(){
    const [List, setList] = useState([]);
    const [pending, setPending] = useState(true);
    const [Details, setDetails] = useState([]);
    const [search, setSearch] = useState("");
    const [searchData, setsearchData] = useState([]);

    useEffect(() => {
        getSurveyFeedbackList();
    }, []);

    useEffect(() => {
        const result = List && List?.filter(email => {
            return email.email.toLowerCase().match(searchData.toLowerCase());
        })
        setSearch(result);
    }, [searchData]);

    function getSurveyFeedbackList() {
        setPending(true);
        axios.get(`api/getSurveyFeedbackList`).then(res => {
            setList(res.data.data);
            setSearch(res.data.data);
            setPending(false);
        }).catch(err => {
            //console.log(err.response)
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    const columns = [
        {
            name: "Email",
            selector: row => row.email
        },
        {
            name: "Created date",
            cell: row => (
                <span>{row.created_at.split("T")[0]}</span>
            )
        },
        {
            name: "Feedback",
            selector: row => {
                const feedbackData = JSON.parse(row.feedback);
                const bulletPoints = Object.entries(feedbackData).map(([key, value]) => (
                    key !== 'email' && (
                        <li key={key}><strong>{key}:</strong> {value}</li>
                    )
                ));
                return <ul>{bulletPoints}</ul>;
            }
        },
        
    ]
    return (
        <div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Survey Feedback List</div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable surveyDataTable'>
                                <DataTable
                                    noDataComponent="No data found"
                                    columns={columns}
                                    data={search}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="search by email" value={searchData} onChange={(e) => { setsearchData(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SurveyFeedback;