import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../../assests/Lander/assets/css/skincare6pLead.css';

import checkProduct1 from '../../assests/Lander/assets/images/skincare6p/productImg-check.png';
import checkProduct2 from '../../assests/Lander/assets/images/skincare6p/productImg-check2.png';
import checkProduct3 from '../../assests/Lander/assets/images/skincare6p/productImg-check3.png';
import moneyBckSeal from '../../assests/Lander/assets/images/skincare6p/MoneyBackSeal.png';
import testimonial1 from '../../assests/Lander/assets/images/skincare6p/skincare-img-42.png';
import testimonial2 from '../../assests/Lander/assets/images/skincare6p/skincare-img-56.png';

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import Copyright from '../Copyright';
import { Helmet } from 'react-helmet';


function BlueForrestLead(){
    // 10 min countdown 
    const [seconds, setSeconds] = useState(600);
    const [loadershow, setloadershow] = useState(false);
    const [states, setStates] = useState([]);
    const location = useLocation();
    let params = location.search;
    let URL = window.location.href;

    const handleCountryChange2 = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };
    
    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    useEffect(() => {
        const interval = setInterval(() => {
        if (seconds > 0) {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }
        }, 1000);

        return () => clearInterval(interval);
    }, [seconds]);

    useEffect(() => {
        //document.documentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        //document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
        document.documentElement.scrollTo(0,0);
        handleCountryChange2('239');
        localStorage.setItem("pack","3");
    }, []);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    function handleSelect(key) {
        localStorage.setItem("pack",key);
    }

    function confirmSubmit(values) {
        let campaign_id = 71;
        setloadershow(true);
        const formdata = new FormData();
        formdata.append('firstName',values.firstName);
        formdata.append('lastName',values.lastName);
        formdata.append('email',values.email);
        formdata.append('phone',values.phone);
        formdata.append('tag1','skin-prospect');
        formdata.append('shippingAddress',values.shippingAddress1);
        formdata.append('shippingCity',values.shippingCity);
        formdata.append('shippingCountry',values.country === '239' ? 'US' : 'CA');
        formdata.append('shippingState',values.shippingState);
        formdata.append('shippingZip',values.shippingZip);
        formdata.append('campaign_id',campaign_id);

        let affid = new URLSearchParams(location.search).get('affid');
        let sid = new URLSearchParams(location.search).get('sid');
        let s1 = new URLSearchParams(location.search).get('s1');
        let s2 = new URLSearchParams(location.search).get('s2');
        let s3 = new URLSearchParams(location.search).get('s3');
        let click_id = new URLSearchParams(location.search).get('click_id');
        let notes = new URLSearchParams(location.search).get('notes');

        localStorage.setItem('affid',affid);
        localStorage.setItem('sid',sid);
        localStorage.setItem('s1',s1);
        localStorage.setItem('s2',s2);
        localStorage.setItem('s3',s3);
        localStorage.setItem('click_id',click_id);
        localStorage.setItem('notes',notes);

        formdata.append('affid', affid);
        formdata.append('sid', sid);
        formdata.append('s1', s1);
        formdata.append('s2', s2);
        formdata.append('s3', s3);
        formdata.append('click_id', click_id);
        formdata.append('notes', notes);
        
        //console.log(Object.fromEntries(formdata));

        axios.post(`api/maropost_tag_add`, formdata).then(res => {
            if (res.data.status === true) {
                axios.post(`api/create-prospect`, formdata).then(result => {
                    if(result.data.status === true) {
                        localStorage.setItem('prospectId',result.data.prospectId);
                        setloadershow(false);
                        window.location.href = '/' + localStorage.getItem('c_s')+params;
                    }
                })
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    }

    return (
        <>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <div className="skincare6pLead_wrapper">
                <div className="skincare6pLead_container container">
                    <div className="resize1">
                        <div className="stepwizard2">
                            <div className="stepwizard-row2 step-wizard-2">
                                <ul className='row'>
                                    <li className="active bsFlexWrapClass col-xs-6">
                                        <div className="stepwizard-step2-div">
                                            <div className="stepwizard-step2">
                                                <a href="#" className="btn btn-circle active-step btn-primary">COMPLETE ORDER </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="bsFlexWrapClass col-xs-6">
                                        <div className="stepwizard-step2-div">
                                            <div className="stepwizard-step2">
                                                <a href="#" className="btn btn-default deactive btn-circle">SUMMARY</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="skincareCheckStep step-2">
                            <div className="row">
                                {/* Col Begin 1 */}
                                <div className="col-lg-8">
                                    {/* Top Offer  */}
                                    <div className="tab_offer">
                                        <div className="row">
                                            <div className="col-7 text-center">
                                                <p>Your order is reserved for :</p>
                                            </div>
                                            <div className="col-3 text-center">
                                                <p><span className="count-up">{formatTime(seconds)}</span> </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Top Offer end  */}
                                    <div className="tracking_offer_col">
                                        <h1 className='col_title'><span>Great Job! </span> You’re taking your first step towards a firmer and more sculpted neckline. Act now so you don’t miss out on this offer!</h1>
                                        <div className="availabilty_col">
                                            <div className="row bsFlexWrapClass">
                                                <div className="col-md-7 text-center">
                                                    <p>Current Availability:</p>
                                                    <div className="meter_col">
                                                        <div className="low"></div>
                                                    </div>
                                                    <span className="meter_text">Low</span>
                                                </div>
                                                <div className="col-md-5 text-center">
                                                    <p>Sell Out Risk: </p><span className="meter_text">High</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="txt-red chk-premium">
                                            <p><strong>PREMIUM UPGRADE:</strong> We are upgrading the formulation for even faster and more dramatic results!</p>
                                        </div>
                                        {/* Selected Package : Begin  */}
                                        <div className="selected_package">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <h2>Your Selected Package</h2>
                                                </div>
                                                <div className="col-md-12 tabed_area col-xs-12 pull-right text-center">
                                                <Tabs
                                                    defaultActiveKey={3}
                                                    id="uncontrolled-tab-example"
                                                    className="mb-3"
                                                    onSelect={handleSelect}
                                                    >
                                                        <Tab eventKey={1} title="1 Jar - $69">
                                                            <div className="product_info tab_cont">
                                                                <div className="row">
                                                                    <div className="col-md-7">
                                                                        <div className="product_img text-center">
                                                                            <img src={checkProduct1} alt='' className='' />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="product_info__div">
                                                                            <h1 className='pricePerJar'><span>$69</span>/JAR</h1>
                                                                            <h2 className="sale">WAS : <span id="iyqdwf" className="sale_price">USD <span id="ccdynamiclabel127">$69.00</span></span> </h2>
                                                                            <h2 className="regular">NOW : USD <span id="ccdynamiclabel131">$69</span> </h2>
                                                                            <h2 className="free">( Free Shipping ) </h2>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                            
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey={2} title="3 Jars - $99">
                                                            <div className="product_info tab_cont">
                                                                <div className="row">
                                                                    <div className="col-md-7">
                                                                        <div className="product_img text-center">
                                                                            <img src={checkProduct2} alt='' className='' />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="product_info__div">
                                                                            <h1 className='pricePerJar'><span>$33.66</span>/JAR</h1>
                                                                            <h2 className="sale">WAS : <span id="iyqdwf" className="sale_price">USD <span id="ccdynamiclabel127">$269.00</span></span> </h2>
                                                                            <h2 className="regular">NOW : USD <span id="ccdynamiclabel131">$99</span> </h2>
                                                                            <h2 className="free">( Free Shipping ) </h2>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                            
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey={3} title="5 Jars - $149">
                                                            <div className="product_info tab_cont">
                                                                <div className="row">
                                                                    <div className="col-md-7">
                                                                        <div className="product_img xt-center">
                                                                            <img src={checkProduct3} alt='' className='' />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="product_info__div">
                                                                            <h1 className='pricePerJar'><span>$29.60</span>/JAR</h1>
                                                                            <h2 className="sale">WAS : <span id="iyqdwf" className="sale_price">USD <span id="ccdynamiclabel127">$449.00</span></span> </h2>
                                                                            <h2 className="regular">NOW : USD <span id="ccdynamiclabel131">$149</span> </h2>
                                                                            <h2 className="free">( Free Shipping ) </h2>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                            
                                                            </div>
                                                        </Tab>
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Selected Package : End  */}

                                        {/* Money Back : Begin  */}
                                        <div className="money_back_sec checkout_moneyback forLargeDevice">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div id="ibu4nm" className="seal_div">
                                                        <img src={moneyBckSeal} className="moneybackLogo" alt="Money Back" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <h2><span className="gjs-selected">INCLUDES A 30 DAYS 100%</span><br />MONEY BACK GUARANTEE</h2>
                                                    <p>Blue Forrest Beauty comes with a
                                                        100% 30 Days Money Back Guarantee. That means if you change your mind about this decision  all you need to do is
                                                        contact us, and we’ll refund your purchase.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Money Back : End  */}

                                    </div>
                                </div>
                                {/* Col End 1 */}

                                {/* Col Begin 2 */}
                                <div className="col-lg-4">
                                    {/* Sidebar Begin  */}
                                    <div className="sidebar">
                                        <div className="final_step_heading">
                                            <h1>SHIPPING DETAILS</h1>
                                            <h2>DELIVERY ADDRESS</h2>
                                        </div>
                                        <div className="sidebar_resize">
                                            <div className="prospect_form">
                                            <Formik initialValues={{
                                                    firstName: '',
                                                    lastName: '',
                                                    shippingAddress1: '',
                                                    shippingZip: '',
                                                    shippingCity: '',
                                                    shippingState: '',
                                                    email: '',
                                                    phone: '',
                                                    country:'239'
                                                }}
                                                validationSchema={Yup.object({
                                                    firstName: Yup.string().required('First name is required'),
                                                    lastName: Yup.string().required('Last name is required'),
                                                    shippingAddress1: Yup.string().required('Address is required'),
                                                    //shippingZip: Yup.string().required('Zip code is required').matches(/^[a-zA-Z0-9]+$/, 'Zip code must contain only letters and numbers'),
                                                    shippingZip: Yup.string().when('country', {
                                                        is: '239',
                                                        then: Yup.string()
                                                          .required('Zip code is required')
                                                          .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
                                                        otherwise: Yup.string().when('country', {
                                                          is: '41',
                                                          then: Yup.string()
                                                            .required('Zip code is required')
                                                            .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                                                            .min(6, 'Zip code should contain at least 6 characters')
                                                            .max(6, 'Zip code should contain at most 6 characters'),
                                                        }),
                                                    }),
                                                    shippingCity: Yup.string().required('City is required'),
                                                    shippingState: Yup.string().required('State is required'),
                                                    email: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
                                                    phone: Yup.string().required('Phone no is required')
                                                        .matches(/^[0-9]+$/, "Please enter only number!")
                                                        .min(10, 'Please enter a valid contact number!')
                                                        .max(10, 'Please enter a valid contact number!'),
                                                    country: Yup.string().required('Country is required')
                                                })}
                                                onSubmit={(values) => {
                                                    //console.log(values);
                                                    localStorage.setItem('firstName', values.firstName);
                                                    localStorage.setItem('lastName', values.lastName);
                                                    localStorage.setItem('shippingAddress1', values.shippingAddress1);
                                                    localStorage.setItem('shippingZip', values.shippingZip);
                                                    localStorage.setItem('shippingState', values.shippingState);
                                                    localStorage.setItem('shippingCity', values.shippingCity);
                                                    localStorage.setItem('phone', values.phone);
                                                    localStorage.setItem('email', values.email);
                                                    localStorage.setItem('country', (values.country === '239') ? 'US' : 'CA');
                                                    confirmSubmit(values);
                                                }}
                                            >
                                                {(formProps) => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <Field type="email" name="email" id="email" placeholder="Email Address" className="required input_form" />
                                                            <CustomErrorMsg name="email" />
                                                            {/* <span className='validated'></span> */}
                                                        </div>
                                                        <div className="form-group">
                                                            <Field type="tel" name="phone" id="phone" placeholder="Phone" className="required input_form" maxLength={10} />
                                                            <CustomErrorMsg name="phone" />
                                                        </div>
                                                        <div className="form-group">
                                                            <Field type="text" name="firstName" id="firstName" placeholder="First Name" className="required input_form" />
                                                            <CustomErrorMsg name="firstName" /> 
                                                        </div>
                                                        <div className="form-group">
                                                            <Field type="text" name="lastName" id="lastName" placeholder="Last Name" className="required input_form" />
                                                            <CustomErrorMsg name="lastName" />
                                                        </div>
                                                        <div className="form-group">
                                                            <Field type="text" name="shippingAddress1" id="shippingAddress1" placeholder="Your Address" className="required input_form" />
                                                            <CustomErrorMsg name="shippingAddress1" />
                                                        </div>
                                                        <div className="form-group">
                                                            <Field
                                                                name="country"
                                                                as="select"
                                                                placeholder="Select an option*"
                                                                className="required input_form"
                                                                onChange={(event) => {
                                                                    formProps.handleChange(event);
                                                                    handleCountryChange(event);
                                                                }}
                                                            >
                                                                <option value="239" selected="selected">United States</option>
                                                                <option value="41" >Canada</option>
                                                            </Field>
                                                            <CustomErrorMsg name="country" />
                                                        </div>
                                                        <div className="form-group">
                                                            <Field
                                                                name="shippingState"
                                                                as="select"
                                                                id="shippingState"
                                                                placeholder="Select an option*"
                                                                className="required input_form"
                                                            >
                                                                <option value="" >Select state</option>
                                                                {states.map((state) => (
                                                                    <option key={state.id} value={state.abbreviation}>
                                                                        {state.state}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                            <CustomErrorMsg name="shippingState" />
                                                        </div>
                                                        <div className="form-group">
                                                            <Field type="text" name="shippingCity" id="shippingCity" placeholder="Your City" className="required input_form" />
                                                            <CustomErrorMsg name="shippingCity" />
                                                        </div>
                                                        <div className="form-group">
                                                            <Field type="text" name="shippingZip" id="shippingZip" placeholder="Zip Code" className="required input_form" maxLength="6"/>
                                                            <CustomErrorMsg name="shippingZip" />
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className='btn btn-proceed-checkout'>SAVE & CONTINUE</button>
                                                        </div>
                                                    </Form>
                                                )}
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Sidebar End  */}
                                    {/* Testimonial Block : Begin  */}
                                    <div className="testSmBlock">
                                        <img src={testimonial1} alt="img1" className="" />
                                        <div className="testSmBlock_quote">
                                            <i>
                                            This works! After just a few weeks my neck looks years younger and I've
                                            only just begun using this!
                                            </i>
                                        </div>
                                        <div className="testSmBlock_name">
                                            <p> <b>Susan C, 63, Delaware </b></p>
                                        </div>
                                    </div>
                                    {/* Testimonial Block : End  */}
                                    {/* Testimonial Block : Begin  */}
                                    <div className="testSmBlock">
                                        <img src={testimonial2} alt="img1" className="" />
                                        <div className="testSmBlock_quote">
                                            <i>
                                            I am a 67-year-old who found myself staying indoors and avoiding going out. Now, I call myself a "social butterfly" and am busier than ever in my community...
                                            </i>
                                        </div>
                                        <div className="testSmBlock_name">
                                            <p> <b>Jackie Z …the “social butterfly” at age 67.</b></p>
                                        </div>
                                    </div>
                                    {/* Testimonial Block : End  */}
                                    {/* Money Back : Begin  */}
                                    <div className="money_back_sec checkout_moneyback forSmallDevice">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div id="ibu4nm" className="seal_div">
                                                        <img src={moneyBckSeal} className="moneybackLogo" alt="Money Back" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <h2><span className="gjs-selected">INCLUDES A 30 DAYS 100%</span><br />MONEY BACK GUARANTEE</h2>
                                                    <p>Skincare Packages comes with a
                                                        100% 30 Days Money Back Guarantee. That means if you change your mind about this decision  all you need to do is
                                                        contact us, and we’ll refund your purchase.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Money Back : End  */}
                                </div>
                                {/* Col End 2 */}
                            </div>
                        </div>

                    </div>
                </div>                
            </div>
            <Copyright/>

                                               

        </>
    )
   
}


export default BlueForrestLead;