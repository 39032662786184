import React, {useState, useEffect, useRef } from "react";
import styles from '../../assests/admin/css/Admin.module.css';
import plus from '../../assests/admin/assets/img/plus.png';
import { DateRangePicker } from 'react-date-range';
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';


function ReportByProduct(){
    const [divVisibility, setDivVisibility] = useState({});
    const [pending, setPending] = useState(true);
    const today = new Date();
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);
    //const [data, setData] = useState({});
    const [showDatePicker, setShowDatePicker] = useState(false);
    const dateRangeRef = useRef(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    
    const handleSelect = (ranges) => {
        const startDate = moment(ranges.selection.startDate);
        const formattedStartDate = startDate.format('YYYY-MM-DD');
        const endDate = moment(ranges.selection.endDate);
        const formattedEndDate = endDate.format('YYYY-MM-DD');
        //setData({'startDate':formattedStartDate, 'endDate':formattedEndDate});
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
    }

    const toggleVisibility = (divId) => { 
        setDivVisibility(prevState => ({ 
          ...prevState, 
          [divId]: !prevState[divId] 
        })); 
    };

    const data = [
        { 
            id: 1, 
            product: 'SynapticXR 5 Bottles Pack', 
            sku: 'SXR5BP',
            category: 'Supplement',
            initial: '1',
            initialAmount: '$132',
            subscription: '0',
            subscriptionAmount: '$0.00',
            total: '1',
            totalAmount: '$50',
            pending: '1',
            pendingAmount: '$50',
            hold: '0',
            holdAmount: '$0.00',
            void: '0',
            voidAmount: '$0.00',
            affid: 'AFFID'
        },
        { 
            id: 2, 
            product: 'SynapticXR 5 Bottles Pack', 
            sku: 'SXR5BP',
            category: 'Supplement',
            initial: '1',
            initialAmount: '$132',
            subscription: '0',
            subscriptionAmount: '$0.00',
            total: '1',
            totalAmount: '$50',
            pending: '1',
            pendingAmount: '$50',
            hold: '0',
            holdAmount: '$0.00',
            void: '0',
            voidAmount: '$0.00',
            affid: 'AFFID'
        }
    ];

    const columns = [
        {
            name: 'Product',
            selector: row => row.product,
            sortable: true,
        },
        {
            name: 'SKU',
            selector: row => row.sku,
            sortable: true,
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true,
        },
        {
            name: 'Initial#',
            selector: row => row.initial,
            sortable: true,
        },
        {
            name: 'Initial$',
            selector: row => row.initialAmount,
            sortable: true,
        },
        {
            name: 'Subscription#',
            selector: row => row.subscription,
            sortable: true,
        },
        {
            name: 'Subscription$',
            selector: row => row.subscriptionAmount,
            sortable: true,
        },
        {
            name: 'Total#',
            selector: row => row.total,
            sortable: true,
        },
        {
            name: 'Total$',
            selector: row => row.totalAmount,
            sortable: true,
        },
        {
            name: 'Pending#',
            selector: row => row.pending,
            sortable: true,
        },
        {
            name: 'Pending$',
            selector: row => row.pendingAmount,
            sortable: true,
        },
        {
            name: 'Hold#',
            selector: row => row.hold,
            sortable: true,
        },
        {
            name: 'Hold$',
            selector: row => row.holdAmount,
            sortable: true,
        },
        {
            name: 'Void#',
            selector: row => row.void,
            sortable: true,
        },
        {
            name: 'Void$',
            selector: row => row.voidAmount,
            sortable: true,
        },
        {
            name: 'Affiliate Breakdown',
            selector: row => row.affid,
            sortable: true,
        },
    ];

    function initialProductReport() {
        setPending(true);
        axios.get('api/get-initial-product-report').then(res => {
            console.log(res.data.data);
        }).catch(error => {
            Swal.fire({
                title: 'Warning',
                html: error.data.message,
                icon: 'warning'
            })
        })
    }

    useEffect(()=>{
        initialProductReport();
    },[]);
   

    return(
        <>
            <div className={styles.pageWrapper}>  
                <div className={styles.reportSerachBarOutter}>
                    <div className={styles.filterRow}>
                        <div className={styles.filterCol_left}>
                            <div className={`row justify-content-start ${styles.reportSerachBar}`} >
                                <div className="col">
                                    <span className={styles.customDropDown__label}>Date:</span>
                                    <div className={styles.customDropDown}>
                                        <div className={styles.customDropDownBtn} onClick={() => toggleVisibility('div1')}>
                                            {startDate && endDate ? startDate+'-'+endDate : 'Filter By Date'}
                                        </div>
                                        {divVisibility['div1'] && (
                                            <div className={styles.customDropDownBody}>
                                                <div className={styles.customDropDownCard}>
                                                    <div className={styles.customDropDownCardInner}>
                                                        <div className={styles.formInner} ref={dateRangeRef}>
                                                            <DateRangePicker
                                                                onChange={(item) => {
                                                                    setState([item.selection]);
                                                                    handleSelect(item);
                                                                }}
                                                                showSelectionPreview={true}
                                                                moveRangeOnFirstSelection={false}
                                                                ranges={state}
                                                                maxDate={today}
                                                                direction="horizontal"
                                                            />
                                                        </div>
                                                        {/* <span className={styles.addBtn}><img src={plus}  /></span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col">
                                    <span className={styles.customDropDown__label}>Product ID:</span>
                                    <div className={styles.customDropDown}>
                                        <div className={styles.customDropDownBtn} onClick={() => toggleVisibility('div2')}>
                                            {'is any value'}
                                        </div>
                                        {divVisibility['div2'] && (
                                            <div className={styles.customDropDownBody}>
                                                <div className={styles.customDropDownCard}>
                                                    <div className={styles.customDropDownCardInner}>
                                                        <div className={styles.formInner} ref={dateRangeRef}>
                                                            <select className='form-control'>
                                                                <option>is Any Value</option>
                                                            </select>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                placeholder="Campaign ID"
                                                                value={''} 
                                                                
                                                            />                                                    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}                              
                                    </div>
                                </div>
                                <div className="col">
                                    <span className={styles.customDropDown__label}>Product Name:</span>
                                    <div className={styles.customDropDown}>
                                        <div className={styles.customDropDownBtn} onClick={() => toggleVisibility('div3')}>
                                            {'is any value'}
                                        </div>
                                        {divVisibility['div3'] && (
                                            <div className={styles.customDropDownBody}>
                                                <div className={styles.customDropDownCard}>
                                                    <div className={styles.customDropDownCardInner}>
                                                        <div className={styles.formInner} ref={dateRangeRef}>
                                                            <select className='form-control'>
                                                                <option>is Any Value</option>
                                                            </select>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                placeholder="Campaign ID"
                                                                value={''} 
                                                            />                                                    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}                              
                                    </div>
                                </div>
                                <div className="col">
                                    <span className={styles.customDropDown__label}>Product Category:</span>
                                    <div className={styles.customDropDown}>
                                        <div className={styles.customDropDownBtn} onClick={() => toggleVisibility('div4')}>
                                            {'is any value'}
                                        </div>
                                        {divVisibility['div4'] && (
                                            <div className={styles.customDropDownBody}>
                                                <div className={styles.customDropDownCard}>
                                                    <div className={styles.customDropDownCardInner}>
                                                        <div className={styles.formInner} ref={dateRangeRef}>
                                                            <select className='form-control'>
                                                                <option>is Any Value</option>
                                                            </select>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                placeholder="Campaign ID"
                                                                value={''} 
                                                            />                                                    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}                              
                                    </div>
                                </div>
                                <div className="col">
                                    <span className={styles.customDropDown__label}>SKU:</span>
                                    <div className={styles.customDropDown}>
                                        <div className={styles.customDropDownBtn} onClick={() => toggleVisibility('div5')}>
                                            {'is any value'}
                                        </div>
                                        {divVisibility['div5'] && (
                                            <div className={styles.customDropDownBody}>
                                                <div className={styles.customDropDownCard}>
                                                    <div className={styles.customDropDownCardInner}>
                                                        <div className={styles.formInner} ref={dateRangeRef}>
                                                            <select className='form-control'>
                                                                <option>is Any Value</option>
                                                            </select>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                placeholder="Campaign ID"
                                                                value={''} 
                                                            />                                                    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}                              
                                    </div>
                                </div>
                                <div className="col">
                                    <span className={styles.customDropDown__label}>Billing Cycle:</span>
                                    <div className={styles.customDropDown}>
                                        <div className={styles.customDropDownBtn} onClick={() => toggleVisibility('div6')}>
                                            {'is any value'}
                                        </div>
                                        {divVisibility['div6'] && (
                                            <div className={styles.customDropDownBody}>
                                                <div className={styles.customDropDownCard}>
                                                    <div className={styles.customDropDownCardInner}>
                                                        <div className={styles.formInner} ref={dateRangeRef}>
                                                            <select className='form-control'>
                                                                <option>is Any Value</option>
                                                            </select>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                placeholder="Campaign ID"
                                                                value={''} 
                                                            />                                                    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}                              
                                    </div>
                                </div>
                                <div className="col">
                                    <span className={styles.customDropDown__label}>Campaign Id:</span>
                                    <div className={styles.customDropDown}>
                                        <div className={styles.customDropDownBtn} onClick={() => toggleVisibility('div7')}>
                                            {'is any value'}
                                        </div>
                                        {divVisibility['div7'] && (
                                            <div className={styles.customDropDownBody}>
                                                <div className={styles.customDropDownCard}>
                                                    <div className={styles.customDropDownCardInner}>
                                                        <div className={styles.formInner} ref={dateRangeRef}>
                                                            <select className='form-control'>
                                                                <option>is Any Value</option>
                                                            </select>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                placeholder="Campaign ID"
                                                                value={''} 
                                                            />                                                    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}                              
                                    </div>
                                </div>
                                <div className="col">
                                    <span className={styles.customDropDown__label}>Campaign Name:</span>
                                    <div className={styles.customDropDown}>
                                        <div className={styles.customDropDownBtn} onClick={() => toggleVisibility('div8')}>
                                            {'is any value'}
                                        </div>
                                        {divVisibility['div8'] && (
                                            <div className={styles.customDropDownBody}>
                                                <div className={styles.customDropDownCard}>
                                                    <div className={styles.customDropDownCardInner}>
                                                        <div className={styles.formInner} ref={dateRangeRef}>
                                                            <select className='form-control'>
                                                                <option>is Any Value</option>
                                                            </select>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                placeholder="Campaign ID"
                                                                value={''} 
                                                            />                                                    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}                              
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className={styles.filterCol_right}>
                            <div className="row">
                                <div className="col">
                                    <button type="button" className={styles.iconBtn} >
                                        <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 bWRyML"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M17.65 6.35A7.958 7.958 0 0012 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0112 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path></svg>
                                    </button>
                                </div>
                                <div className="col">
                                    <Dropdown className={styles.reportDropdown}>
                                        <Dropdown.Toggle variant="" id="dropdown-basic" className={`${styles.option} ${styles.iconBtn}`}>
                                            <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 bWRyML"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Download</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Reset Filter</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className={styles.dataTable}>
                    <div className='datatable'>
                        <DataTable
                            noDataComponent="No Data Available"
                            columns={columns} 
                            data={data} 
                        />
                    </div>             
                </div> 
            </div> 
        </>
    )   

    
}


export default ReportByProduct;