import React from 'react'
import { Link } from 'react-router-dom';
import Copyright from '../Copyright';
import { BASE_URL } from '../Config';



function PrivacyPolicy() {

   

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
    };

    return (
        <>
            <div className='infoPages term-and-cond'>
                <div className='container'>
                    <div className='breadcrumbs_nav text-center  mb-4'>
                        <Link to='#'>HOME</Link>
                        <Link to='#' className='lastElem'>Privacy Policy</Link>
                    </div>
                    <div className="infoPage-container">
                        <p className="text-justify">
                            This Privacy Policy (“Privacy Policy”) describes the data collection, use, sharing and protection practices of Genius Wholesale, LLC (hereafter referred to as “Genius Wholesale Club,” “we,” “our,” “us”) and applies to all websites
                            operated by Genius Wholesale, including but not limited to <a href={BASE_URL}>“www.wholesalehealthclub.com,”</a> any sub domains thereof, affiliated websites, and mobile applications (collectively, the “Website”), and any content, products
                            or services made available through the Website. We refer to the information, products and services made available through the Website as “Services” in this Privacy Policy. We use the words “you” and “your” to mean you, the reader,
                            and other visitors to our Website and users of our Services who are, in all cases, over the age of 18. This age requirement is discussed in more detail later in this Privacy Policy.
                        </p>
                        <p className="text-justify">
                            By visiting the Website and requesting Services through the Website, you agree to the terms of this Privacy Policy, which may periodically be updated. It is your sole responsibility to be aware of any changes to this Privacy Policy.
                            If we feel it is appropriate, or if the law requires, we will also provide a summary of changes made to the Privacy Policy. Genius Wholesale Club does not provide notice of changes in any manner other than by posting the changes at
                            this Website. This Privacy Policy is incorporated into, and part of, the Website Terms and Conditions which govern your use of this Website in general. This Website is intended for users who are located in the United States of
                            America. The Privacy Policy shall be interpreted under the laws of the United States.
                        </p>
                        <h3 className="subTitile">NAVIGATING THROUGH THIS PRIVACY POLICY</h3>
                        <p className="text-justify">You can use the links below to navigate to areas of this Privacy Policy that apply specifically to you, or which may otherwise be of interest:</p>

                        <ul>
                            <li>THE INFORMATION WE COLLECT</li>
                            <li>HOW WE USE YOUR INFORMATION</li>
                            <li>DATA ANALYTICS AND ADVERTISING</li>
                            <li>HOW WE SHARE YOUR INFORMATION</li>
                            <li>THIRD-PARTY LINKS AND CONTENT</li>
                            <li>DATA SECURITY</li>
                            <li>SUBMITTING INFORMATION FROM OUTSIDE THE UNITED STATES</li>
                            <li>DATA PRIVACY AND PROTECTION LAWS: THE CCPA AND GDPR</li>
                            <li>CHILDREN’S PRIVACY</li>
                            <li>CHANGES TO THIS PRIVACY STATEMENT</li>
                            <li>CONTACT US</li>
                        </ul>

                        <h3 className="subTitile">THE INFORMATION WE COLLECT</h3>
                        <p className="text-justify">
                            Genius Wholesale Club obtains information about you when you use our Services. Please note that we need certain types of information so that we can provide Services to you. If you do not provide us with such information, or ask us
                            to delete it, you may no longer be able to access or use all or part of our Services.
                        </p>

                        <p className="titleTag"><strong>Information You Voluntarily Provide to Us </strong></p>
                        <p className="text-justify">Genius Wholesale Club collects information directly from you in a variety of ways including, but not limited to:</p>

                        <ul>
                            <li>The account registration process and the administration of your account;</li>
                            <li>Receiving the questions, communications, or feedback you submit to us via email or online forms;</li>
                            <li>Your participation in research and surveys</li>
                            <li>Requests you submit for customer support and technical assistance, including through online chat functions, message, and text messages.</li>
                        </ul>

                        <p className="text-justify">Genius Wholesale Club directly collects information from you, including but not limited to the following categories::</p>
                        <ul>
                            <li>Name, mailing address, telephone number and email address;</li>
                            <li>Billing information, such as credit or debit card number, bank account and routing numbers, and identity verification information that is collected though our payment processors on our behalf;</li>
                            <li>Log-in credentials;</li>
                            <li>Information about purchases or other transactions with Genius Wholesale Club;</li>
                            <li>Information about your customer service and maintenance interactions with us;</li>
                            <li>User-generated content you post in public online forums on our Services; and</li>
                            <li>Any other information you choose to directly provide to us in connection with you use of the Services.</li>
                        </ul>

                        <p className="titleTag"><strong>Information We Collect Through Automated Means</strong></p>
                        <p className="text-justify">
                            Genius Wholesale Club and our services providers (third-party companies that work on our behalf) use a variety of technologies to assist in collecting information about your use of the Services and the devices you use to access the
                            Services.
                        </p>

                        <p className="text-justify">
                            <em>Visitor Data.</em> When you use our Website, we collect information that will help us understand and analyze your experience interacting with our Services. For example, we may collect information related to domain names, the
                            type of browsers used to access the Services, operating system software, browser plug-ins, cookie preferences, search engine keywords, the pages you viewed and amount of time spent on particular pages, terms you use in you searches
                            on our Website, and similar information.
                        </p>
                        <p className="text-justify">
                            <em>Location Information.</em> When you use the Services, we and our service providers may automatically collect general location information (e.g., IP address, city/state and or postal code associated with an IP address) from your
                            computer or mobile device. This information allows us to enable access to content that varies based on a user’s general location (e.g., to provide you with accurate sales tax information and to deliver content customized to your
                            location).
                        </p>
                        <p className="text-justify">
                            <em>Cookies.</em> “Cookies” are a feature in your browser software. If enabled, we, or our service providers, may write cookies that may store small amounts of data on your computer about your visit to any of the pages of the
                            Website. Cookies assist us in tracking which of our features appeal the most to you and what content you may have viewed on past visits. When you visit this Website again, cookies can enable us to customize our content according to
                            your preferences.
                        </p>
                        <p className="text-justify">We may use cookies to:</p>
                        <ul>
                            <li>keep track of the number of return visits to this Website;</li>
                            <li>accumulate and report aggregate, statistical information on Website usage;</li>
                            <li>deliver specific content to you based on your interests or past viewing history</li>
                            <li>save your password for ease of access to the Website.</li>
                        </ul>

                        <p className="text-justify">
                            You can disable cookies, although the Website may not function properly for you. Your browser preferences can be modified to accept or reject all cookies, or request a notification when a cookie is set. You may read more about
                            cookies at <a href="http://cookiecentral.com">http://cookiecentral.com</a>. In order to use all of the features and functionality of Genius Wholesale Club’s Website, you need to accept cookies.
                        </p>
                        <p className="text-justify">
                            <em>Web Beacons.</em> A web beacon, sometimes called a tracking pixels, is a code that can be used to display an image on a web page, but can also be used to transfer your unique user identification to a database and associate you
                            with previously acquired information about an individual in a database. This allows Genius Wholesale Club and our third-party service providers to track certain websites you visit. Web beacons are used to track online behavioral
                            habits for marketing purposes to determine products or services you may be interested in. In addition to using web beacons on web pages, we also use web beacons in email messages sent to individuals listed in Genius Wholesale Club’s
                            database.
                        </p>
                        <p className="text-justify">
                            <em>Other Online Tools.</em> Genius Wholesale Club may also use a web server log or an SDK. A webserver log is a file where website activity is stored. An SDK is a set of tools and/or code that we embed in our software to allow
                            third parties to collect information about how people interact with our Services.
                        </p>
                        <p className="text-justify">As we continue to develop the Website and our Services, Genius Wholesale Club may implement additional methods to gather information about the Genius Wholesale Club community.</p>

                        <p className="titleTag"><strong>Information We Collect From Social Media and other Content Platforms</strong></p>

                        <p className="text-justify">
                            Genius Wholesale Club maintains a presence on a variety of social media platforms. When you “like” or “follow” us on Facebook, Instagram, Twitter, or other social media platforms, we may collect some information about you. This
                            information includes your name, email address, any comments or content you post that is relevant to Genius Wholesale Club. We also collect your information if you sign up for a promotion and submit information to us through one of
                            our social media accounts.
                        </p>
                        <p className="text-justify">Genius Wholesale Club also maintains several blogs as a part of its Services. When you comment or post content to any of our blogs, we will collect your name and email address.</p>

                        <h3 className="subTitile">HOW WE USE YOUR INFORMATION</h3>
                        <p className="text-justify">The following paragraphs describe how Genius Wholesale Club uses your information in connection with our Services.</p>
                        <p className="text-justify">
                            <em>General Use.</em> Genius Wholesale Club will use your information as necessary to carry out and manage its Services. This includes, using your information to verify and administer your account, including processing payments and
                            fulfilling orders (if applicable). We will use your information to communicate with you about Genius Wholesale Club’s Services, your use of the Services or your inquiries related to the Services. Genius Wholesale Club will use your
                            information to ensure that content from our Services is presented in the most effective manner for you and for your computer or device, allow you to participate in interactive features of our Services (when you choose to do so), and
                            as part of our efforts to keep our Services safe and secure.
                        </p>
                        <p className="text-justify">
                            <em>Improve Genius Wholesale Club’s Services.</em> Genius Wholesale Club continuously seeks to improve its Services. To do so, we conduct research to understand the effectiveness of our Services, improve our Services, and to better
                            understand the Genius Wholesale Club community. If we publish the results of our research to others, such research will be presented in a de-identified and aggregate form such that individual users cannot be identified.
                        </p>
                        <p className="text-justify">
                            <em>Promotional Communications.</em> We will use your personal information to communicate with you by email, postal mail, phone, or text message about surveys, promotions, special events or our products and Services and those of our
                            affiliates or third-party partners, and any of their related businesses. By creating an account and using this Website, you are giving your express permission to Genius Wholesale Club and their agents or vendors the right to contact
                            you with targeted advertising materials by email, text message (SMS), telephone or cellphone, including the use of automated, predictive, programmable, or similar (“robodialers”) dialers or dialing software, or any other means of
                            communication to provide promotional offers. To the extent applicable, you expressly acknowledge that you are solely responsible for all charges billed by your mobile service provider. If you no longer wish to receive promotional
                            communications by email, please follow the unsubscribe link provided in every email message.
                        </p>
                        <p className="text-justify">
                            <em>Marketing/Performance Analysis &amp; Data Analytics.</em> We will use your information to help us better understand your interests and needs, such as by engaging in analysis and research regarding use of the Services. We may use
                            your information to measure or understand the effectiveness of advertising and content we serve to you and others, and to deliver and customize relevant advertising and content to you.
                        </p>
                        <p className="text-justify">
                            <em>Combined Information.</em> For the purposes discussed in this Privacy Policy, we may combine the information that we collect through the Services with information that we receive from other sources, both online and offline, and
                            use and share such combined information in accordance with this Privacy Policy.
                        </p>
                        <p className="text-justify">
                            <em>Aggregate/De-Identified Data.</em> We may aggregate and/or de-identify any information collected through the Services so that such information can no longer be linked to you or your device (“Aggregate/De-Identified
                            Information”). We may use Aggregate/De-Identified Information for any purpose, including for research and marketing purposes, and may also share such data with any third parties, including advertisers, promotional partners, and
                            sponsors.
                        </p>

                        <h3 className="subTitile">DATA ANALYTICS AND ADVERTISING</h3>
                        <p className="titleTag"><strong>Data Analytics</strong></p>
                        <p className="text-justify">
                            We may use third-party web analytics services including, but not limited to, those provided by Google Analytics (including Google Signals, Google User-ID and other Google Analytics features) and MixPanel. on our Services to collect
                            and analyze usage information through cookies and similar tools; engage in auditing, research, or reporting; assist with fraud prevention; try to locate the same unique users across multiple browsers or devices to better tailor
                            services and features; and provide certain features to you.&nbsp; If you have a Google account with personalized advertising enabled, through Google Signals, Google will also be able to gather for us analytics and engagement
                            information from across the various devices you use to access the Services. To prevent Google from using your information for analytics (including cross-device tracking for personalization purposes), you may install the Google
                            Analytics Opt-out Browser Add-on&nbsp;by&nbsp;<a href="https://tools.google.com/dlpage/gaoptout" target="_blank">clicking here</a>. To opt out of Google Signals, please open your “Settings” app, locate and tap “Google,” select
                            “Ads,” and turn ON “Opt out of Ads Personalization.”&nbsp; You may also be able to disable cross-device tracking through your Android or Apple device-based settings.
                        </p>
                        <p className="text-justify">
                            If you receive email from us, we may use certain analytics tools to capture date, including but not limited to opening messages or clicking any links or banners contained in the messages. This data allows us to gauge the
                            effectiveness of our communications and marketing campaigns.
                        </p>

                        <p className="titleTag"><strong>Online Advertising</strong></p>
                        <p className="text-justify">
                            The Services may integrate third-party advertising technologies (e.g., ad networks and ad servers such as Facebook Ads, Google AdWords7, Criteo and others) that use cookies and other technologies to deliver relevant content and
                            advertising for products on the Services, as well as on other websites you visit and other applications you use. The ads may be based on various factors such as the content of the page you are visiting, information you enter such as
                            your searches, demographic data, and other information we collect from you. These ads may be based on your current activity or your activity over time and across other websites and online services.
                        </p>
                        <p className="text-justify">
                            We sometimes provide our customer information (such as email addresses) to service providers, who may “match” this information in de-identified form to cookies (or mobile ad identifiers) and other proprietary IDs, in order to
                            provide you with more relevant ads when you visit other websites and mobile applications.
                        </p>

                        <p className="text-justify">
                            We neither have access to, nor does this Privacy Policy govern, the use of cookies or other tracking technologies that may be placed on your device you use to access the Services by non-affiliated third-party advertising network
                            services. If you are interested in more information about tailored browser advertising and how you can generally control cookies from being put on your computer to deliver tailored advertising, you may visit the Network Advertising
                            Initiative’s Consumer Opt-Out <a href="https://optout.networkadvertising.org/?c=1" target="_blank">link</a>, the Digital Advertising Alliance’s Consumer Opt-Out&nbsp;
                            <a href="https://optout.aboutads.info/?c=2&amp;lang=EN" target="_blank">link</a>, or the Your Online Choices <a href="https://www.youronlinechoices.com/uk/your-ad-choices" target="_blank">link</a> to opt-out of receiving tailored
                            advertising from companies that participate in those programs. If you are interested in more information about Criteo’s privacy practices or opting-out of Criteo’s advertising services, you may view their Privacy Policy. To opt out
                            of Google Analytics for display advertising or customize Google display network ads, visit the Google Ads Settings page. We do not control these opt-out links or whether any particular company chooses to participate in these opt-out
                            programs. We are not responsible for any choices you make using these mechanisms or the continued availability or accuracy of these mechanisms.
                        </p>
                        <p className="text-justify">Please note that if you exercise the opt-out choices above, you will still see advertising when you use the Services, but it will not be tailored to you based on your online behavior over time.</p>

                        <p className="titleTag"><strong>Email Marketing</strong></p>

                        <p className="text-justify">
                            By submitting your email address through the Website, you are expressly consenting to receive emails from Genius Wholesale Club, including from Genius Wholesale Club’s affiliates, and from third parties concerning offers and
                            advertisements unrelated to Genius Wholesale Club. To opt-out of receiving email messages from us, from our affiliates or from other third parties, click on the “Unsubscribe” link contained in each email. Please allow up to 10
                            business days for us to process your request. Please note if you decide not to receive marketing emails from us, you may still receive transactional email messages regarding your order(s) (i.e., order confirmation, shipping
                            information, etc.). If you have questions or concerns regarding this provision, please contact us at <a href="support@wholesalehealthclub.com">support@wholesalehealthclub.com.</a>
                        </p>

                        <p className="titleTag"><strong>Notice Concerning Do Not Track</strong></p>
                        <p className="text-justify">
                            Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. We are committed to providing you with meaningful choices about the information collected on our website for third-party purposes, and that is
                            why we provide the variety of opt-out mechanisms listed above. However, we do not currently recognize or respond to browser-initiated DNT signals. To learn more about Do Not Track, you can do so
                            <a herf="https://www.eff.org/pages/understanding-effs-do-not-track-policy-universal-opt-out-tracking#:~:text=Welcome%20to%20the%20party%21%20%20%20Privacy%20Concern,if%20privacy%20compliant%20%206%20more%20rows%20" target="_blank">
                                here
                            </a>
                            .
                        </p>

                        <h3 className="subTitile">HOW WE SHARE YOUR INFORMATION</h3>
                        <p className="text-justify">Genius Wholesale Club may share your information in the following ways:</p>
                        <p className="text-justify">
                            <em>Affiliates &amp; Related Business Entities.</em> We may share the information we collect with our affiliates or related business entities for the purposes of delivering products or services to you, ensuring a consistent level of
                            service, and to enhance our products, services, and your customer experience.
                        </p>
                        <p className="text-justify">
                            <em>Service Providers and Third-Party Vendors.</em> We provide access to or share your information with select third parties who use the information to perform services on our behalf. They provide a variety of services to us,
                            including billing, sales, marketing, advertising, analytics, research, customer service, shipping and fulfillment, data storage, IT and security, fraud prevention, payment processing, and auditing and legal services.
                        </p>
                        <p className="text-justify">
                            <em>Legal Compliance.</em> By using the Service, you acknowledge and agree that we may access, retain, and disclose information we collect and maintain about you if reasonably necessary to comply with a subpoena or court order, to
                            enforce our Terms of Use, Privacy Policy, or Genius Wholesale Club’s legal rights.
                        </p>
                        <p className="text-justify">
                            <em>Business Transfers.</em> As Genius Wholesale Club continues to grow, we may buy, merge, or partner with other companies. In such transactions, your information may be among the assets that are transferred.
                        </p>
                        <p className="text-justify">
                            <em>Public Forums.</em> Certain features of our Services make it possible for you to share comments publicly with other users. Any information that you submit through such features is not confidential, and we may use it for any
                            purpose (including in testimonials or other marketing materials). For example, if you submit a product review on one of our Websites or blogs, we may display your review (along with the name provided, if any) on other Genius
                            Wholesale Club websites and on third-party websites. Any information you post openly in these ways will be available to the public at large and potentially accessible through third-party search engines. Accordingly, please take care
                            when using these features.
                        </p>
                        <p className="text-justify">
                            <em>Aggregated/Anonymized Information.</em> We reserve the right to create Aggregated/Anonymized Data from the information we collect through the services and our sharing of such Aggregated/Anonymized Data is in our sole discretion.
                        </p>

                        <h3 className="subTitile">THIRD-PARTY LINKS AND CONTENT</h3>
                        <p className="text-justify">
                            The Website and email messages that you receive from Genius Wholesale Club may sometimes contain hypertext links to the websites of third parties. Genius Wholesale Club is not responsible for the privacy practices or the content of
                            such other websites. Linked websites may contain links to websites maintained by third parties. Such links are provided for your convenience and reference only. Genius Wholesale Club does not operate or control in any respect any
                            information, software, products or services available on such third-party websites. The inclusion of a link to a website does not imply any endorsement of the services or the site, its contents, or its sponsoring organization.
                        </p>

                        <h3 className="subTitile">DATA SECURITY</h3>
                        <p className="text-justify">
                            We will implement reasonable and appropriate security procedures consistent with prevailing industry standards to protect data from unauthorized access by physical and electronic intrusion. Unfortunately, no data transmission over
                            the Internet or method of data storage can be guaranteed 100% secure. Therefore, while we strive to protect your personal information by following generally accepted industry standards, we cannot ensure or warrant the absolute
                            security of any information you transmit to us or archive at this Website.
                        </p>

                        <h3 className="subTitile">SUBMITTING INFORMATION FROM OUTSIDE THE UNITED STATES</h3>
                        <p className="text-justify">
                            We control and operate the online and mobile resources from within the United States of America (the “U.S.”). Information collected through the Website and Services may be stored and processed in the United States or any other
                            country in which we or our vendors maintain facilities. Although we do not actively block or monitor visitors from other countries, the Website is directed only at visitors from the U.S. As such, this Privacy Policy is consistent
                            with U.S. law and practice and is not adapted to other laws (including European data security and privacy laws). Genius Wholesale Club will apply the applicable laws of the U.S. including as embodied in this Privacy Policy in place
                            of data protections under your home country's law. That is, you freely and unambiguously acknowledge that this Privacy Policy, not your home country's laws, controls how we will collect, store, process, and transfer your personal
                            information. Similarly, the English language version of this Privacy Policy is the controlling version regardless of any translation you may attempt.
                        </p>

                        <h3 className="subTitile">DATA PRIVACY AND PROTECTION LAWS: THE CCPA AND GDPR</h3>
                        <p className="text-justify">
                            Privacy and data protection laws vary around the world and among the several United States. Some of those laws are more specific than others.&nbsp;Most prominently, residents of California and data subjects whose personal
                            information was obtained from them while they were in the European Economic Area, United Kingdom, and Switzerland have certain additional rights in cases where the party collecting that information is governed by the applicable law.
                            We have reviewed our business operations and data-related business practices with legal counsel in the context of the General Data Protection Regulation under European Law also known as the “GDPR” and the California Consumer Privacy
                            Act (the “CCPA”). As a result of that review, as of the effective date of this Privacy Policy, we do not believe we are governed by either the GDPR or CCPA.
                        </p>
                        <p className="text-justify">
                            We do not, for instance, collect personal information from data subjects in the European Economic Area, United Kingdom, or Switzerland or elsewhere abroad. Similarly, we do not target or tailor our Website or Services or any of our
                            other US-based business activities to appeal to, or specifically attempt to do business in those jurisdictions. In terms of California, although we may do business within the state, we do not meet any of the various statutory
                            thresholds and criteria that would bring us under the CCPA.
                        </p>
                        <p className="text-justify">
                            For general visitors, consumers and regulators who have questions about whether or how the GDPR, CCPA or other industry- or jurisdiction-specific laws apply to us, you can contact us using the contact information below.&nbsp;
                        </p>

                        <h3 className="subTitile">CHILDREN’S PRIVACY</h3>
                        <p className="text-justify">
                            Federal law imposes special restrictions and obligations on commercial website operators who direct their operations toward, and collect and use information from children under the age of 13. We take those age-related requirements
                            very seriously, and, consistent with them, do not intend for our Website or Services to be used by children under the age of 18, and certainly not by anyone under the age of 13. Moreover, we do not knowingly collect personal
                            information from minors under the age of 18. If we become aware that anyone under the age of 18 has submitted personal information to us via our Website or Services, we will delete that information and not use it for any purpose
                            whatsoever. We encourage parents and legal guardians to talk with their children about the potential risks of providing personal information over the Internet.
                        </p>

                        <h3 className="subTitile">CHANGES TO THIS PRIVACY STATEMENT</h3>
                        <p className="text-justify">
                            We reserve the right to change or update this Privacy Policy from time to time. Please check our Website periodically for such changes since all information collected is subject to the Privacy Policy in place at the time of
                            collect.ion. Typically, we will indicate the effective/amendment date at the beginning of this Privacy Policy. If we feel it is appropriate, or if the law requires, we’ll also provide a summary of changes we’ve made near the end of
                            the new Privacy Policy .
                        </p>

                        <h3 className="subTitile">CONTACT US</h3>
                        <p className="text-justify">If you have questions about this Privacy Policy or Genius Wholesale Club’s privacy practices, please contact us at:</p>
                        <address>
                            Genius Wholesale, LLC<br />
                            PO BOX 90129 <br />
                            Lakeland FL 33804 <br />
                            Tel: <a href="tel:(480)573-0829">(480)573-0829</a> <br />
                            Email: <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>
                        </address>

                        <div className="text-center" onClick={scrollToTop} >
                            <a href="#" className="back-to-top">Return to Top</a>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy