import React, { useState, useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useStore from '../../store'
import { faCoffee, faBagShopping, faUserAlt, faBox, faGear, faDatabase, faUserCircle, faAngleDown, faList, faAdd, faLink, faMoneyBillTrendUp, faStar, faPenNib, faCircleQuestion, faSquarePollVertical, faFile} from '@fortawesome/free-solid-svg-icons'
function Sidebar() {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenSubmenu, setIsOpenSubmenu] = useState(null);
    let permission = useStore((state) => state.userPermission)
    var permissionModules = 0
    var permissionModulesArr = []
    var slug
    var slugArr
    var Style
    if (permission) {
        permissionModules = permission;
    }
    if (permissionModules != 0) {
        permissionModulesArr = permissionModules.split(',')
    }

    var slugArr = window.location.href.split('/')
    var slug = ''
    var slugTempArr = []
    if (slugArr.length > 3) {
        slug = slugArr[4]
        slugTempArr = slug.split('?')
        if (slugTempArr.length > 0) {
            slug = slugTempArr[0]
        }
    }

    useEffect(() => {
        if (location.pathname.includes('prospect-detail')) {
            setIsOpenSubmenu(8);
        }
    }, [location.pathname]);

    /*const toggleSubMenu = (submenuId) => {
        setIsOpen(!isOpen);
        setIsOpenSubmenu(submenuId);
    };*/
    const toggleSubMenu = (submenuId) => {
        setIsOpenSubmenu((prevSubMenu) => (prevSubMenu === submenuId ? null : submenuId));
    };

    return (
        <nav className="sb-sidenav accordion sb-sidenav-dark navbar-custom shadow" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
                <div className="nav">

                    <NavLink className={slug == 'orders' ? 'nav-link active' : 'nav-link'} to="/admin/orders" style={{ display: permissionModules == 'All' ? 'show' : permissionModulesArr.includes('orders') ? 'show' : 'none' }}>
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faBagShopping} /></div>
                        Orders
                    </NavLink>

                    {/* <NavLink className={slug == 'customers' ? 'nav-link active' : 'nav-link'} to="/admin/customers" style={{ display: permissionModules == 'All' ? 'show' : permissionModulesArr.includes('customers') ? 'show' : 'none' }}>
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faUserAlt} /></div>
                        Customers
                    </NavLink> */}

                    <Link 
                        className={isOpenSubmenu === 1 ? 'nav-link active' : 'nav-link collapsed'} 
                        to="#" 
                        //data-bs-toggle="collapse" 
                        data-bs-target="#collapseLayouts" 
                        aria-expanded="false" 
                        aria-controls="collapseLayouts" 
                        style={{ display: permissionModules == 'All' ? 'show' : permissionModulesArr.includes('Product') ? 'show' : 'none' }}
                        onClick={() => toggleSubMenu(1)}
                    >
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faBox} /></div>
                        Products
                        <div className="sb-sidenav-collapse-arrow"><FontAwesomeIcon icon={faAngleDown} /></div>
                    </Link>

                    <div 
                        className={isOpenSubmenu === 1 ? 'show' : 'collapse'} 
                        id="collapseLayouts" 
                        aria-labelledby="headingOne" 
                        data-bs-parent="#sidenavAccordion"
                    >
                        <nav className="sb-sidenav-menu-nested nav">
                            <NavLink className="nav-link" to="/admin/brand">Brand</NavLink>
                            <NavLink className="nav-link" to="/admin/category">Category</NavLink>
                            <NavLink className="nav-link" to="/admin/viewprods">Products</NavLink>
                            <NavLink className="nav-link" to="/admin/productmapping">CRM Product Mapping</NavLink>

                        </nav>
                    </div>

                    <Link 
                        className={isOpenSubmenu === 2 ? 'nav-link active' : 'nav-link collapsed'} 
                        to="#" 
                        //data-bs-toggle="collapse" 
                        data-bs-target="#collapseRgisters" 
                        aria-expanded="false" 
                        aria-controls="collapseRgisters" 
                        style={{ display: permissionModules == 'All' ? 'show' : permissionModulesArr.includes('pagesetup') ? 'show' : 'none' }}
                        onClick={() => toggleSubMenu(2)}
                    >
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faBox} /></div>
                        Page Setup
                        <div className="sb-sidenav-collapse-arrow"><FontAwesomeIcon icon={faAngleDown} /></div>
                    </Link>
                    <div 
                        className={isOpenSubmenu === 2 ? 'show' : 'collapse'} 
                        id="collapseRgisters">
                        <nav className="sb-sidenav-menu-nested nav" >
                            <Link className={slug == 'template' ? 'nav-link active d-none' : 'nav-link d-none'} to="/admin/template">Template </Link>
                            <Link className={slug == 'lander' ? 'nav-link active' : 'nav-link'} to="/admin/lander">Lander</Link>
                            <Link className={slug == 'checkoutfunnelslist' || slug == 'checkoutfunnels' || slug == 'checkoutfunnelsedit' ? 'nav-link active' : 'nav-link'} to="/admin/checkoutfunnelslist">Checkout</Link>
                            <Link className={slug == 'upsell' ? 'nav-link active' : 'nav-link'} to="/admin/upsell">Upsell</Link>
                            <Link className={slug == 'confirmation' ? 'nav-link active' : 'nav-link'} to="/admin/confirmation">Confirmation</Link>
                        </nav>
                    </div>
                    <Link 
                        className={isOpenSubmenu === 3 ? 'nav-link active d-none' : 'nav-link collapsed d-none'} 
                        to="#" 
                        //data-bs-toggle="collapse" 
                        data-bs-target="#collapseFunnels" 
                        aria-expanded="false" 
                        aria-controls="collapseFunnels" 
                        style={{ display: permissionModules == 'All' ? 'show' : permissionModulesArr.includes('pagesetup') ? 'show' : 'none' }}
                        onClick={() => toggleSubMenu(3)}
                    >
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faBox} /></div>
                        Checkout Funnels
                        <div className="sb-sidenav-collapse-arrow"><FontAwesomeIcon icon={faAngleDown} /></div>
                    </Link>

                    <div 
                        className={isOpenSubmenu === 3 ? 'show' : 'collapse'} 
                        id="collapseFunnels" 
                        aria-labelledby="headingOne" 
                        data-bs-parent="#sidenavAccordion"
                    >
                        <nav className="sb-sidenav-menu-nested nav">
                            <NavLink className="nav-link d-none" to="#">Register </NavLink>
                            <NavLink className="nav-link d-none" to="#">Register Lander</NavLink>
                            <NavLink className="nav-link" to="/admin/checkoutfunnelslist">Funnel List</NavLink>
                            <NavLink className="nav-link" to="/admin/checkoutfunnels">Add new funnel</NavLink>
                        </nav>
                    </div>

                    <NavLink className={slug == 'funnelflow' || slug == 'funnelflowedit' ? 'nav-link active' : 'nav-link'} to="/admin/funnelflowlist" style={{ display: permissionModules == 'All' ? 'show' : permissionModulesArr.includes('funnelflowsetup') ? 'show' : 'none' }}>
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faGear} /></div>
                        Funnel Flow Setup
                    </NavLink>
                    <NavLink className={slug == 'crminstance' ? 'nav-link active' : 'nav-link'} to="/admin/crminstance" style={{ display: permissionModules == 'All' ? 'show' : permissionModulesArr.includes('crminstance') ? 'show' : 'none' }}>
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faDatabase} /></div>
                        CRM intregation
                    </NavLink>
                    <NavLink className={slug == 'users' ? 'nav-link active' : 'nav-link'} to="/admin/users" style={{ display: permissionModules == 'All' ? 'show' : permissionModulesArr.includes('users') ? 'show' : 'none' }}>
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faUserCircle} /></div>
                        Users
                    </NavLink>
                    
                    <Link 
                        className={isOpenSubmenu === 4 ? 'nav-link active' : 'nav-link collapsed'} 
                        to="#" 
                        //data-bs-toggle="collapse" 
                        data-bs-target="#collapseFunnelsBonus" 
                        aria-expanded="false" 
                        aria-controls="collapseFunnels"
                        onClick={() => toggleSubMenu(4)}
                    >
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faMoneyBillTrendUp} /></div>
                        Bonus
                        <div className="sb-sidenav-collapse-arrow"><FontAwesomeIcon icon={faAngleDown} /></div>
                    </Link>
                    <div className={isOpenSubmenu === 4 ? 'show' : 'collapse'} id="collapseFunnelsBonus" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <NavLink className="nav-link" to="/admin/bonus-category">Bonus Category</NavLink>
                            <NavLink className="nav-link" to="/admin/bonus">Bonus </NavLink>
                        </nav>
                    </div>

                    <NavLink className={slug == 'product_review' ? 'nav-link active' : 'nav-link'} to="/admin/product_review" style={{ display: permissionModules == 'All' ? 'show' : permissionModulesArr.includes('users') ? 'show' : 'none' }}>
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faStar} /></div>
                        Product Review
                    </NavLink>

                    <Link 
                        className={isOpenSubmenu === 5 ? 'nav-link active' : 'nav-link collapsed'} 
                        to="#" 
                        //data-bs-toggle="collapse" 
                        data-bs-target="#collapseSapphireAddOn" 
                        aria-expanded="false" 
                        aria-controls="collapseSapphireAddOn" 
                        onClick={() => toggleSubMenu(5)}
                    >
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faPenNib} /></div>
                        Sapphire Add On
                        <div className="sb-sidenav-collapse-arrow"><FontAwesomeIcon icon={faAngleDown} /></div>
                    </Link>
                    <div 
                        className={isOpenSubmenu === 5 ? 'show' : 'collapse'} 
                        id="collapseSapphireAddOn" 
                        aria-labelledby="headingOne"
                    >
                        <nav className="sb-sidenav-menu-nested nav">
                            <NavLink className="nav-link" to="/admin/sapphire-add-on">Domains</NavLink>
                            <NavLink className="nav-link" to="/admin/domain-logs">Logs </NavLink>
                            <NavLink className="nav-link" to="/admin/add-on-queries">Add on queries </NavLink>
                        </nav>
                    </div>


                    <Link 
                        className={isOpenSubmenu === 6 ? 'nav-link active' : 'nav-link collapsed'} 
                        to="#" 
                        //data-bs-toggle="collapse" 
                        data-bs-target="#collapseFAQ" 
                        aria-expanded="false" 
                        aria-controls="collapseSapphireAddOn" 
                        onClick={() => toggleSubMenu(6)}
                    >
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faCircleQuestion} /></div>
                        FAQ
                        <div className="sb-sidenav-collapse-arrow"><FontAwesomeIcon icon={faAngleDown} /></div>
                    </Link>
                    <div 
                        className={isOpenSubmenu === 6 ? 'show' : 'collapse'} 
                        id="collapseFAQ" 
                        aria-labelledby="headingOne"
                    >
                        <nav className="sb-sidenav-menu-nested nav">
                            <NavLink className="nav-link" to="/admin/faq-category">Category</NavLink>
                            <NavLink className="nav-link" to="/admin/faq-sub-category">Sub Category </NavLink>
                            <NavLink className="nav-link" to="/admin/faq-queries">FAQ queries </NavLink>
                        </nav>
                    </div>


                    <Link 
                        className={isOpenSubmenu === 7 ? 'nav-link active' : 'nav-link collapsed'} 
                        to="#" 
                        //data-bs-toggle="collapse" 
                        data-bs-target="#collapseSurvey" 
                        aria-expanded="false" 
                        aria-controls="collapseSapphireAddOn" 
                        onClick={() => toggleSubMenu(7)}
                    >
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faSquarePollVertical} /></div>
                        Survey
                        <div className="sb-sidenav-collapse-arrow"><FontAwesomeIcon icon={faAngleDown} /></div>
                    </Link>
                    <div 
                        className={isOpenSubmenu === 7 ? 'show' : 'collapse'} 
                        id="collapseSurvey" 
                        aria-labelledby="headingOne"
                    >
                        <nav className="sb-sidenav-menu-nested nav">
                            <NavLink className="nav-link" to="/admin/survey-question">Survey Question</NavLink>
                            <NavLink className="nav-link" to="/admin/survey-feedback">Survey Feedback </NavLink>
                            <NavLink className="nav-link" to="/admin/support-logs">Support Logs </NavLink>
                        </nav>
                    </div>

                    <Link 
                        className={isOpenSubmenu === 8 ? 'nav-link active' : 'nav-link collapsed'} 
                        to="#" 
                        //data-bs-toggle="collapse" 
                        data-bs-target="#collapseReport" 
                        aria-expanded="false" 
                        aria-controls="collapseSapphireAddOn" 
                        onClick={() => toggleSubMenu(8)}
                    >
                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faFile} /></div>
                        Report
                        <div className="sb-sidenav-collapse-arrow"><FontAwesomeIcon icon={faAngleDown} /></div>
                    </Link>
                    <div 
                        className={isOpenSubmenu === 8 ? 'show' : 'collapse'} 
                        id="collapseReport" 
                        aria-labelledby="headingOne"
                    >
                        <nav className="sb-sidenav-menu-nested nav">
                            <NavLink className="nav-link" to="/admin/overview">Overview</NavLink>
                        </nav>
                    </div>

                </div>
            </div>
        </nav>
    )
}

export default Sidebar