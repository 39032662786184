import Get1hardppDesktop from "./Get1hardppDesktop";
import Get1hardppMobile from "./Get1hardppMobile";

function Get1hardpp(){
    function getDeviceType() {
        const width = window.innerWidth;
        const height = window.innerHeight;

        const isMobile = width <= 700;
        const isTablet = width > 700 && width <= 1024;
        const isDesktop = width > 1024;
    
        if (isMobile) {
            return 'mobile';
        } else if (isTablet) {
            return 'tablet';
        } else if (isDesktop) {
            return 'desktop';
        }
    }
    const deviceType = getDeviceType();
    return(
        <>
            {/* {(deviceType === 'mobile') || (deviceType === 'tablet') ? <DickPillMobile/> : <DickPillDesktop/>} */}
            {(deviceType === 'mobile')  ? <Get1hardppMobile/> : <Get1hardppDesktop/>}
        </>
    )
}
export default Get1hardpp;