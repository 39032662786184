import upsellImg2_banner from '../../assests/Lander/assets/images/skincare6p/upsell2_bannerimg.png';
import upsellImg2_banner_mob from '../../assests/Lander/assets/images/skincare6p/upsell2_bannerimg-mob.jpg';
import upsell2_Img2 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img2.jpg';
import upsell2_Img3 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img3.jpg';
import upsell2_Img4 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img4.jpg';
import upsell2_Img5 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img5.jpg';
import upsell2_Img6 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img6.jpg';
import upsell2_Img7 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img7.jpg';
import upsell2_Img8 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img8.jpg';
import upsell2_Img9 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img9.jpg';
import upsell2_Img10 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img10.jpg';
import upsell2_Img11 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img11.jpg';
import upsell2_Img12 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img12.jpg';
import upsell2_Img13 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img13.jpg';
import upsell2_Img14 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img14.jpg';
import upsell2_Img15 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img15.jpg';
import upsell2_Img16 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img16.jpg';
import upsell2_Img17 from '../../assests/Lander/assets/images/skincare6p/skincarep6_upsell2_img17.png';
import truck from '../../assests/Lander/assets/images/skincare6p/truck.png';
import badge from '../../assests/Lander/assets/images/skincare6p/circle_tick.png';
import cardImg from '../../assests/Lander/assets/images/skincare6p/card_img.png';
import upsellProduct1 from '../../assests/Lander/assets/images/skincare6p/upsell_bottle1.png';
import upsellProduct2 from '../../assests/Lander/assets/images/skincare6p/upsell_bottle2.png';
import upsellProduct3 from '../../assests/Lander/assets/images/skincare6p/upsell_bottle3.png';
import line from '../../assests/Lander/assets/images/skincare6p/line.png';
import gurantee_seal from '../../assests/Lander/assets/images/skincare6p/gurantee_seal.png';



function Skincare6pUpsell2(){
    return(
        <>
        <main className='sk6Upsell'>
            <div className="sk6Upsell_topbar">
                <div className="cont_sec">
                    <h2>WAIT, YOUR ORDER IS NOT COMPLETE – DO NOT CLICK THE BACK BUTTON</h2>
                </div>
            </div>

            <div className="sk6Upsell_banner sk6Upsell2_banner">
                <div className="cont_sec">
                    <div className="row">
                        <div className="col">
                            <div className="sk6Upsell_banner__cont">
                                <h2 className="sk6_title">Why Women of <span className="txt_pink">"All Ages"</span> Are Raving About This <span className="txt_pink">“Instant”</span> Eye Serum That Removes Under-Eye Bags And Crow's Feet Within Just Minutes… </h2>
                                <p className="sk6_subTitle">...and how you can go from a “tired and worn out” look to eyes that are lively, bright, and revitalized.</p>
                                <div className="for-small-screen">
                                    <img src={upsellImg2_banner_mob} className="sk6Upsell_banner__mobimg img_shadow" alt="" />   
                                </div>
                            </div>
                        </div>
                        <div className="col col_right">
                            <img src={upsellImg2_banner} className="sk6Upsell_banner__img" alt="" />   
                        </div>
                    </div>
                </div>
            </div>

            <div className="sk6Upsell2_sec2 py-3 py-4">
                <div className="cont_sec">
                    <div className="upsellCont">
                        <p>I have one other gem to share with you that will enhance your new youthful and radiant look...</p>
                        <p><strong>Your eyes hold the key...</strong></p>
                        <p>And because the eyes are so revealing...</p>
                        <p>Your eyes can show off your happiness, youthfulness, and sense of confidence...</p>
                        <p>...or they can reveal: tiredness, sickness, and even a sense of vulnerability...</p>
                        <p>Ever noticed eyes that look weary, heavy-lidded, drooping or puffy – what thoughts come to mind?</p>
                        <p>If you reflect on your own eyes, and wonder if they look as vibrant, refreshed, and as bright as they did in your youth, what might you think?</p>
                        <div className='row'>
                            <div className='col-md-6'>
                                <p>Now, I only ask that question because I’m about to introduce you to the answer for tired looking eyes…</p>
                                <p>…and for eyes that may have under-eye bags…</p>
                                <p>…or crow's feet…</p>
                                <p>…or puffiness and sagging.</p>
                                <p>Because if you’ve ever woken up and felt like your eyes looked just a bit sunken, droopy, or fatigued…</p>
                            </div>
                            <div className='col-md-6'>
                                <img src={upsell2_Img2} className='upsell2_img2 img_shadow mb-4' />
                            </div>
                        </div>
                        <p>…and if you wanted an instant “lift” that could tighten, revive, and refresh the eyes…</p>
                        <p>…we’ve developed an eye serum formulated to treat that delicate skin…</p>
                        <p>…to repair, rejuvenate, and brighten your eyes, instantly, allowing you to project a more youthful and self-assured look .</p>                        
                        <button className='btn btn_lg_blue'>See Your Exclusive Face Serum Offer Below!</button>
                    </div>
                </div>
            </div>

            <div className="sk6Upsell2_sec3 pb-3">
                <div className="cont_sec">
                    <div className="upsellCont">
                        <div className='imgBlock text-center'>
                            <img src={upsell2_Img3} alt='' className='mb-3 mb-md-5 img_shadow' />
                        </div>
                        <p>It’s called <strong>Age Brilliance Eye Serum</strong> , which immediately renews and refreshes your eyes so you can display a glowing radiance to match your new increased self-confidence.</p>
                        <p>So when you’re meeting up with friends or colleagues…</p>
                        <p>…you can confidently bat your eyes and showcase your new makeover leaving everyone wondering if you’ve had “some work done”?!</p>
                    </div>        
                </div>
            </div>


            <div className="sk6Upsell2_sec4">
                <div className="cont_sec contentCard px-0">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="upsellCont">                                                       
                                <h4 className='text-center sk6_subTitle2'><strong>What Is</strong></h4>
                                <h2 class="sk6_title2  text-center">Age Brilliance Eye Serum?</h2>
                                <img src={upsell2_Img4} className="w-100 up2Img-4" alt="" />  
                                <div className='inner_cont side_gap'>
                                    <p>Age Brilliance Eye Serum is an advanced eye serum that <strong class="yellowStrip1">reduces the appearance of dark circles, under- </strong> <strong class="yellowStrip1">eye bags, crows feet, and puffy skin around the delicate layer of skin around the eyes…</strong> </p>
                                    <p>…without any burning sensations, irritations, or flaky dry residue…</p>
                                    <p>And it works within just minutes for any skin type, even sensitive skin.…</p>
                                    <p>It’s already helped many women dramatically reduce wrinkles like crows feet, and immediately hydrating the skin, to give you a refreshed and rejuvenated look.</p>
                                </div>                                
                            </div>
                        </div>
                    </div>       
                </div>
            </div>

            <div className="sk6Upsell2_sec5 py-5">
                <div className="cont_sec upsellCont">
                    <h2 className='sk6_title3 text-center'>
                        Why Is <span className='txt_pink'>Age Brilliance Eye Serum</span> So Effective?
                    </h2>
                    <p>Age Brilliance Eye Serum is a powerful daily source of hydration and repair for the eyes that works instantly by targeting the root cause of wrinkles, puffiness, and dark circles at the cellular level…</p>
                    <p><strong>Age Brilliance Eye Serum also helps the delicate skin around your eyes by:</strong></p>
                    <div className="row imageCard_row mb-3 mb-md-5">
                        <div className="col-md-4 imageCard_col">
                            <div className="imageCard1">                               
                                <div className="imageCard1_fig">                            
                                    <img src={upsell2_Img5} className="up2Img-5" alt="" />   
                                </div>
                                <div className="imageCard1_cont text-center">                            
                                    <p>Promoting collagen production to smooth out fine lines and wrinkles…</p>
                                </div>                                
                            </div>
                        </div>
                        <div className="col-md-4 imageCard_col">
                            <div className="imageCard1">                               
                                <div className="imageCard1_fig">                            
                                    <img src={upsell2_Img6} className="up2Img-6" alt="" />   
                                </div>
                                <div className="imageCard1_cont text-center">                            
                                    <p>Speeding up healing and reducing inflammation on the cellular level for healthier skin…</p>
                                </div>                                
                            </div>
                        </div>
                        <div className="col-md-4 imageCard_col">
                            <div className="imageCard1">                               
                                <div className="imageCard1_fig">                            
                                    <img src={upsell2_Img7} className="up2Img-7" alt="" />   
                                </div>
                                <div className="imageCard1_cont text-center">                            
                                    <p>Boosting moisture, hydration, and suppleness, preventing aging due to skin dryness…</p>
                                </div>                                
                            </div>
                        </div>   
                        <div className="col-md-4 imageCard_col">
                            <div className="imageCard1">                               
                                <div className="imageCard1_fig">                            
                                    <img src={upsell2_Img8} className="up2Img-8" alt="" />   
                                </div>
                                <div className="imageCard1_cont text-center">                            
                                    <p>Creating brighter, healthier looking and less sensitive skin…</p>
                                </div>                                
                            </div>
                        </div>
                        <div className="col-md-4 imageCard_col">
                            <div className="imageCard1">                               
                                <div className="imageCard1_fig">                            
                                    <img src={upsell2_Img9} className="up2Img-9" alt="" />   
                                </div>
                                <div className="imageCard1_cont text-center">                            
                                    <p>Further fill in fine lines and wrinkles, as well as tighten and firm the skin…</p>
                                    <div class="inner_cont"><button class="btn btn_lg_blue">See Your Exclusive Eye Serum Offer Below!</button></div>
                                </div>                                
                            </div>
                        </div>
                        <div className="col-md-4 imageCard_col">
                            <div className="imageCard1">                               
                                <div className="imageCard1_fig">                            
                                    <img src={upsell2_Img10} className="up2Img-10" alt="" />   
                                </div>
                                <div className="imageCard1_cont text-center">                            
                                    <p>.And could help you look 10, 15 or even 20 years younger than your actual age.</p>
                                </div>                                
                            </div>
                        </div>                         
                    </div>    
                </div>
            </div>


            <div className="sk6Upsell2_sec6 py-4">
                <div className="cont_sec">
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            <div className="upsellCont">
                                <h3 className='sk6_title3 text-center text-md-start'>How is <span className='txt_pink'>Age Brilliance Eye Serum</span>  Able To Do This?</h3>
                                <img src={upsell2_Img11} className="up2Img-11 d-md-none mb-5" alt="" />    
                                <p>Age Brilliance Eye Serum’s secret is its potent anti-aging ingredients…</p>                                
                                <p>Individually these ingredients deliver incredible anti-aging boosters, but when combined, you get a formulation that has both immediate and long-term benefits for the delicate eye skin as you’ll see below…</p>
                                <p>These primary ingredients begin with Fision Instant Lift.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 d-none d-md-block"> 
                            <img src={upsell2_Img11} className="up2Img-11" alt="" />   
                        </div>
                    </div>                         
                </div>
            </div>


            <div className="sk6Upsell2_sec7">
                <div className="cont_sec upsellCont">
                    <div className="contentCard2">  
                        <div className="row mb-3">
                            <div className="col-md-5 order-md-2">
                                <img src={upsell2_Img12} className="up2Img-12  mb-3" alt="Fision Instant Lift" />   
                            </div>
                            <div className="col-md-7">
                                <h3 className='sk6_title4 txt_pink'>Fision Instant Lift</h3>                           
                                <p>Fision Instant Lift is the “Queen of Ageless Eyes”... it's a natural, non-GMO solution blend of soy protein and gums that work to <strong className='yellowStrip1'>instantly tighten and lift the skin to reduce wrinkles within just minutes.</strong></p>
                                <p>In addition to the quick, gentle firming effect and removing of fine lines… <strong>It also minimizes puffiness and under-eye bags.</strong></p>                       
                                <p>On top of that, a recent study of Fision Instant Lift reported: <strong  className='yellowStrip1'>“striking reductions in wrinkle depth… improvements in elasticity… and younger looking and more radiant” skin.</strong></p>
                            </div>
                           
                        </div>   
                        <p>Bottom line, this ingredient offers an immediate rejuvenating boost, visibly tightening and uplifting the delicate eye skin for a flawless, youthful glow.</p>
                        <p>And then the next ingredient I selected is to help long-term health and firmness by stimulating collagen and elastin production…</p>              
                    </div>

                    <p>…the next amazing ingredient is…</p>



                    <div className="contentCard2">  
                        <div className="row mb-3">
                            <div className="col-md-5 order-md-2">
                                <img src={upsell2_Img13} className="up2Img-13  mb-3" alt="Citrustem" />   
                            </div>
                            <div className="col-md-7">
                                <h3 className='sk6_title4 txt_pink'>Citrustem</h3>                           
                                <p>Citrustem is orange stem cells, often referred to as “Eye Skin Miracles”, that <strong className='yellowStrip1'>improve the elasticity around the</strong> <strong className='yellowStrip1'>eyes to give them a youthful and rejuvenated look.</strong></p>
                                <p>Because, what's special about these stem cells is, they promote the regeneration of new skin tissue… <strong>and increase both elastin and collagen production…</strong> which  <u>replace the sagging skin like under-eye bags, with firmer and smoother skin.</u></p>  
                                <p>And with increased collagen production, fine lines and wrinkles are reduced and skin texture is improved.</p>  
                            </div>                            
                        </div>   
                        <p>This “miracle” ingredient alone is priceless when it comes to pampering the delicate skin of the eyes… </p>              
                    </div>

                    <p>But we didn’t stop there because we added the next super ingredient to keep the eye skin ultra-moist…</p>

                    <div className="contentCard2">  
                        <div className="row">
                            <div className="col-md-5 order-md-2">
                                <img src={upsell2_Img14} className="up2Img-14 mb-3" alt="Hyaluronic Acid" />   
                            </div>
                            <div className="col-md-7">
                                <h3 className='sk6_title4 txt_pink'>Hyaluronic Acid</h3>                           
                                <p>Hyaluronic Acid is that <strong className='yellowStrip1'>moisture magnet” that pulls in</strong> <strong className='yellowStrip1'>moisture to give the eye skin just the right amount of</strong> <strong className='yellowStrip1'>plumpness to reveal healthy and vibrant eyes.</strong> </p>
                                <p>And on top of that, an extra addition of hydration to smooth-out wrinkles and improve skin elasticity, particularly in the fragile skin areas of the eyes.</p>  
                            </div>                            
                        </div> 
                    </div>

                    <p>And then to “top off” Age Brilliance Eye Serum, we added this next ingredient that works its magic at the cellular level…</p>

                    <div className="contentCard2">  
                        <div className="row">
                            <div className="col-md-5 order-md-2">
                                <img src={upsell2_Img15} className="up2Img-15 mb-3" alt="Hyaluronic Acid" />   
                            </div>
                            <div className="col-md-7">
                                <h3 className='sk6_title4 txt_pink'>Orchistem</h3>                           
                                <p>Orchistem are orchid stem cells, specifically selected for the delicate area around the eyes, delivering true anti-aging benefits…</p>
                                <p>Amazingly, these stem cells enhance the communication between skin cells, which means that the cells “coordinate” their activities to regenerate tissue by replacing dead and damaged cells…</p>  
                                <p><strong className='yellowStrip1'>…which leads to better cell turnover to reveal a more</strong> <strong className='yellowStrip1'>youthful and radiant glow to the skin, making the eyes</strong> <strong className='yellowStrip1'>look fresher and more vibrant.</strong></p>
                            </div>
                            
                        </div> 
                    </div>

                    <p>Like I mentioned before, Age Brilliance Eye Serum is the combination of potent natural ingredients.</p>
                    <p>And the result is a powerful serum to use for the delicate area of the eye skin…</p>
                    <p>To help your eyes look vibrant, refreshed and more lively than ever…</p>

                    <div className="text-center">
                        <img src={upsell2_Img16} className="up2Img-16 img_shadow" alt="Hyaluronic Acid" />   
                    </div>

                </div>
            </div>


            <div className="sk6Upsell2_sec8 py-4">
                <div className="cont_sec upsellCont">
                    <div className="contentCard">  
                        <p>Age Brilliance Eye Serum is my recommendation for treatment around the eyes…</p>
                        <p>And as you’ve seen for yourself, it's the perfect compliment for your beauty transformation…</p>
                        <p>Because with livelier, more vibrant and youthful looking eyes…</p>
                        <p>…you’ll now have that noticeable “sparkle” so when you show up to your next meetup with friends, or work with your co-workers, or even just shopping at the grocery store…</p>
                        <p>…the new refreshed and stunning look your eyes will have will allow you to shine with every step and have everyone else wondering what your secret is…</p>
                        <div className="row">
                            <div className="col-md-7">                          
                                <p>And this is why Age Brilliance Eye Serum deserves a prime spot in your daily beauty regimen…</p>
                                <p>Which is why we’re totally fine with just covering our cost to make it…</p>
                                <p>…if it means you’re able to get this incredible “firming and lifting” eye formulation right away.</p>
                                <p>But, you should also know that like our other products…</p>
                                <p>Age Brilliance Eye Serum requires a time consuming process to ensure each batch is highly effective…</p>
                                <p>And because of that…</p>      
                                <p>It takes up to 3 months to custom create each batch.</p>                         
                            </div>
                            <div className="col-md-5">
                                <img src={upsell2_Img17} className="up2Img-17" alt="" />   
                            </div>
                        </div>   
                        <p>Which means we frequently run the risk of out-of-stocks.</p>
                        <p>However, we want everyone that sees this page to have the opportunity to experience ageless and stunning eyes for themselves…</p>          
                        <p>That’s why we’re giving you the opportunity to stock up right now on multiple bottles if you choose to…</p>   
                        <p>For an even larger discount…</p>   
                        <p>So that you can continue to have stunning, vibrant, and more youthful eyes…</p>   
                        <p>…and let others think you’re years, and even decades, younger than you really are…</p>       
                        <p>For months and years to come.</p>
                    </div>              
                </div>
            </div>


          



            <div className="sk6Upsell2_sec9 py-4">
                <div className="cont_sec">
                    <h3 class="sk6_title3 text-center">Stock Up On Age Brilliance Eye Serum Now And <br /> <span class="txt_pink">Save Up to 60% Off</span></h3>
                    <div className="row proCard_row mt-4">
                        <div className="col-md-4 proCard_col">
                            <div className="proCard">                               
                                <div className="proCard_header">                               
                                    <h2 className='proCard_header__title'>SAMPLER PACKAGE</h2>
                                    <hr className='horizontal_line' />
                                    <h3 className='proCard_header__subtitle'>1 MONTH SUPPLY</h3>
                                </div>
                                <div className="proCard_body">    
                                    <div className="proCard_pricePer">                               
                                        <p className='proCard_price'>$47</p>
                                        <p className='per'>PER BOTTLE</p>
                                    </div>                           
                                    <div className="save_price">                               
                                        SAVE <br /> <span className='saved'>$50.00</span>			  
                                    </div> 
                                    <div className='product_img'>
                                        <img className='bottle_img' src={upsellProduct1} />
                                    </div>
                                    <div className='product_prices'>
                                        <p className='reg_price'>REGULARLY <span className='pr'>$89.00</span></p>
                                        <p className='actual_price'>TOTAL: <span className='ac_pr'>$47.00</span></p>
                                    </div>
                                    <div className='product_deal'>
                                        <div className='img_box'>
                                            <img src={truck} className='' />
                                            <span className='deal_txt'>FREE SHIPPING</span>
                                        </div>
                                        <div className='img_box'>
                                            <img src={badge} className='' />
                                            <span className='deal_txt'>180 DAY MONEY BACK GUARANTEE</span>
                                        </div>  
                                    </div>
                                    <button className='btn btn-primary btn-one-style btn-icon cart_btn'>Add to Cart</button>
                                    <img src={cardImg} className='card_img mx-auto d-block' alt='card_img' />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 proCard_col">
                            <div className="proCard">                               
                                <div className="proCard_header">                               
                                    <h2 className='proCard_header__title'>MOST POPULAR</h2>
                                    <hr className='horizontal_line' />
                                    <h3 className='proCard_header__subtitle'>1 MONTH SUPPLY</h3>
                                </div>
                                <div className="proCard_body">    
                                    <div className="proCard_pricePer">                               
                                        <p className='proCard_price'>$47</p>
                                        <p className='per'>PER BOTTLE</p>
                                    </div>                           
                                    <div className="save_price">                               
                                        SAVE <br /> <span className='saved'>$50.00</span>			  
                                    </div> 
                                    <div className='product_img'>
                                        <img className='bottle_img' src={upsellProduct2} />
                                    </div>
                                    <div className='product_prices'>
                                        <p className='reg_price'>REGULARLY <span className='pr'>$89.00</span></p>
                                        <p className='actual_price'>TOTAL: <span className='ac_pr'>$47.00</span></p>
                                    </div>
                                    <div className='product_deal'>
                                        <div className='img_box'>
                                            <img src={truck} className='' />
                                            <span className='deal_txt'>FREE SHIPPING</span>
                                        </div>
                                        <div className='img_box'>
                                            <img src={badge} className='' />
                                            <span className='deal_txt'>180 DAY MONEY BACK GUARANTEE</span>
                                        </div>  
                                    </div>
                                    <button className='btn btn-primary btn-one-style btn-icon cart_btn'>Add to Cart</button>
                                    <img src={cardImg} className='card_img mx-auto d-block' alt='card_img' />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 proCard_col">
                            <div className="proCard">                               
                                <div className="proCard_header">                               
                                    <h2 className='proCard_header__title'>BEST VALUE</h2>
                                    <hr className='horizontal_line' />
                                    <h3 className='proCard_header__subtitle'>3 MONTH SUPPLY</h3>
                                </div>
                                <div className="proCard_body">    
                                    <div className="proCard_pricePer">                               
                                        <p className='proCard_price'>$47</p>
                                        <p className='per'>PER BOTTLE</p>
                                    </div>                           
                                    <div className="save_price">                               
                                        SAVE <br /> <span className='saved'>$50.00</span>			  
                                    </div> 
                                    <div className='product_img'>
                                        <img className='bottle_img' src={upsellProduct3} />
                                    </div>
                                    <div className='product_prices'>
                                        <p className='reg_price'>REGULARLY <span className='pr'>$89.00</span></p>
                                        <p className='actual_price'>TOTAL: <span className='ac_pr'>$47.00</span></p>
                                    </div>
                                    <div className='product_deal'>
                                        <div className='img_box'>
                                            <img src={truck} className='' />
                                            <span className='deal_txt'>FREE SHIPPING</span>
                                        </div>
                                        <div className='img_box'>
                                            <img src={badge} className='' />
                                            <span className='deal_txt'>180 DAY MONEY BACK GUARANTEE</span>
                                        </div>  
                                    </div>
                                    <button className='btn btn-primary btn-one-style btn-icon cart_btn'>Add to Cart</button>
                                    <img src={cardImg} className='card_img mx-auto d-block' alt='card_img' />
                                </div>
                            </div>
                        </div>
                    </div>    
                    <p className='text-center upsellSkip'><a href=''><strong>No thank you. I’ll pass on this huge savings now and realize I will never have this opportunity again.</strong></a></p>   
                </div>
            </div>

            <div className="sk6Upsell_sec11">
                <div className="cont_sec contentCard upsellCont">
                    <div className="mobileImg d-md-none">
                        <img src={gurantee_seal} className="gurantee_mob_img mx-auto" alt="" />  
                    </div>  
                    <h2 className='sk6_title3 text-center'>180-Day Money Back Guarantee</h2>
                    <img src={line} alt="line-img"  className="mx-auto d-block mb-3 mb-md-4" />
                  
                    <div className="row">
                        <div className="col-md-8">
                            <p>If I’m not absolutely thrilled by the vibrant, refreshed, and youthful way my eyes look…</p>
                            <p>Or if I’m unimpressed by the comments others will make about my “younger looking eyes”…</p>
                            <p>Then I pay nothing.</p>
                            <p>Because my decision to invest in Age Brilliance Eye Serum is completely risk free.</p>
                            <p>And if I change my mind for any reason whatsoever…</p>        
                            <p>I can just call or email customer service and get a full refund.</p>         
                            <p>No hassles. No risks. Just results.</p>            
                        </div>
                        <div className="col-md-4">
                            <img src={gurantee_seal} className="d-none d-md-block" alt="" />  
                        </div>                      
                    </div>  
                </div>
            </div>
            
            
        </main>

        {/* <footer className="footerDark">
            <div className="footer-container">
                <ul className="footer-links">
                    <li><a href="/skincare2p">Terms And Conditions</a></li>
                    <li><a href="/skincare2p">Privacy Policy</a></li>
                    <li><a href="/skincare2p">Citations</a></li>
                    <li><a href="/contact-us"> Contact Us </a></li>
                </ul>
                <p className="copy-right-txt">Copyright © 2024, Wholesale Health Club, PO BOX 90129 Lakeland FL 33804<br/>Billing
                    descriptor: <a href="https://wholesalehealthclub.com/">wholesalehealthclub.com</a><br/>Customer Service
                    Phone: <a href="tel:(480)573-0829">(480)573-0829</a><br/>Customer Service Email: <a
                        href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a></p>
            </div>
        </footer> */}


</>
    )
}


export default Skincare6pUpsell2;