import React, { useState, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft, faEye } from '@fortawesome/free-solid-svg-icons'
import CheckPermission from '../frontend/auth/CheckPermission';
import {BASE_URL} from '../Config';
import { Formik, Field, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";

function AssignementLander() {
    CheckPermission();
    const [slug, setslug] = useState('')
    const [show, setShow] = useState(false);
    const [List, setList] = useState([]);
    const [pending, setPending] = useState(true);

    const [Details, setDetails] = useState([]);
    const [Id, setId] = useState(false);
    const [search, setSearch] = useState("");
    const [searchTable, setsearchTable] = useState([]);

    const handleClose = () => {
        setShow(false)
        setSearch('')
    }
    const handleShow = () => setShow(true);

    async function getList() {
        axios.get(`api/listLander`).then(res => {
            // console.warn(res.data.data);
            setList(res.data.data);
            setsearchTable(res.data.data)
            setPending(false);
        })
    }

    const columns = [
        {
            name: "Name",
            selector: row => row.lander_name
        },
        {
            name: "Slug",
            selector: row => row.lander_slug
        },
        {
            name: "Template Name",
            cell: row => (
                <span>{row.lander_file_name}</span>
            )
        },
        {
            name: "Created",
            cell: row => (
                <span>{row.created_at.split("T")[0]}</span>
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <a style={{ marginRight: 1 + 'em' }} target='_blank' className='btn btn-primary' href={'/' + row.lander_slug} ><FontAwesomeIcon icon={faEye} /></a>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { showSingleData(row.id) }} ><FontAwesomeIcon icon={faGear} /></button>
                    <button className='btn btn-danger' onClick={() => { deleteData(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]

    useEffect(() => {
        getList()
    }, []);


    function createModal() {
        setDetails([]);
        setId(false);
        handleShow();
    }

    const formSubmit = (formdata) => {
        axios.post(`api/createLander`, formdata).then(res => {
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getList();
            }
            else {
                Swal.fire({
                    title: 'Warning',
                    html: res.data.message,
                    icon: 'warning'
                })
            }
        }).catch(err => {
            console.log(err.response)
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });
    }

    function deleteData(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this lnder?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`api/deleteLander/${id}`).then(res => {
                    if (res.data.status) {
                        Swal.fire({
                            title: 'Deleted',
                            html: res.data.message,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        })
                        getList();
                    }
                    else {
                        Swal.fire({
                            title: 'Warning',
                            html: res.data.message,
                            icon: 'warning'
                        })
                    }

                })
            }
        })
    }

    function showSingleData(id) {
        setId(id);
        axios.get(`api/detailsLander/${id}`).then(res => {
            setslug(res.data.lander_slug);
            setDetails(res.data);
            handleShow();
        })
    }

    const navigate = useNavigate();

    const formUpdate = (formdata) => {
        axios.post(`api/updateLander/${Id}`, formdata).then(res => {
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getList();
            }
            else {
                Swal.fire({
                    title: 'Warning',
                    html: res.data.message,
                    icon: 'warning'
                })
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });
    }

    useEffect(() => {
        const result = List.filter(country => {
            return country.lander_name.toLowerCase().match(search.toLowerCase());
        })
        setsearchTable(result);
    }, [search]);

    return (
        <div>
            <div >
                <div className='modal'>


                    <Modal show={show} onHide={handleClose} className="ass_lander_modal">
                        <Modal.Header closeButton>
                            <Modal.Title>Lander Page</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <div className='card'>
                                            <div className='card-body' style={{ minHeight: 'auto', overflowY: 'hidden' }}>
                                                <Formik
                                                    enableReinitialize = {true}
                                                    initialValues={{
                                                        lander_name: Details.lander_name,
                                                        lander_slug: Details.lander_slug,
                                                        lander_file_name: Details.lander_file_name
                                                    }}
                                                    
                                                    validationSchema={Yup.object({
                                                        lander_name: Yup.string()
                                                            .required('Lander name is required')
                                                            .test(
                                                                'is-unique',
                                                                'Lander already exists',
                                                                async (value) => {
                                                                    if(!value){
                                                                        return true;
                                                                    } else {
                                                                        const res = await axios.get(`api/landerNamecheck/${value}`);
                                                                        if(Details.lander_name === value){
                                                                            return true;
                                                                        } else {
                                                                            if (res.data.status) {
                                                                                return false;
                                                                            } else {
                                                                                return true;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            ),

                                                        lander_slug: Yup.string()
                                                            .required('Slug name is required')
                                                            .test(
                                                                'unique-slug',
                                                                'Slug already exists',
                                                                async (value)  => {
                                                                    if(!value){
                                                                        return true;
                                                                    } else {
                                                                        const res = await axios.get(`api/landerSlugcheck/${value}`);
                                                                        setslug(value);
                                                                        if(Details.lander_slug === value){
                                                                            return true;
                                                                        } else {
                                                                            if (res.data.status) {
                                                                                return false;
                                                                            } else {
                                                                                return true;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            ),
                                                        lander_file_name: Yup.string().required('Select file name'),
                                                    })}


                                                    onSubmit={(values, { setSubmitting }) => {
                                                        setSubmitting(true);
                                                        //alert(JSON.stringify(values, null, 2));
                                                        {(Id) ? formUpdate(values) : formSubmit(values); }
                                                        
                                                    }}
                                                >
                                                    
                                                    <Form>
                                                        <div className='form-group md-3'>
                                                            <label>Page Name</label>
                                                            <Field 
                                                                type="text" 
                                                                name="lander_name" 
                                                                className="form-control" 
                                                            >
                                                            </Field>
                                                            <CustomErrorMsg name="lander_name" />
                                                        </div>
                                                        <div className='form-group md-3'>
                                                            <label>Slug:</label>
                                                            <Field 
                                                                type="text" 
                                                                name="lander_slug" 
                                                                className="form-control" 
                                                            >
                                                            </Field>
                                                            <CustomErrorMsg name="lander_slug" />
                                                        </div>
                                                        <div className='form-group md-3'>
                                                            URL: {BASE_URL}{slug}
                                                        </div>
                                                        <div className='form-group md-3'>
                                                            <label>Select Lander Template </label>
                                                            <Field as="select" className="form-control" name="lander_file_name">
                                                                <option value="">Select Lander</option>
                                                                <option value="Brain">Brain</option>
                                                                <option value="ACV-Gummies">ACV Gummies</option>
                                                                <option value="Facecream">Facecream</option>
                                                                <option value="Acvgummies-Mobile-Lander">Acvgummies Mobile Lander</option>
                                                                <option value="Nootropic">Nootropic</option>
                                                                <option value="Skincare">Skincare</option>
                                                                <option value="Skincare6p">Skincare6p</option>
                                                                <option value="DickPillOffer">DickPillOffer</option>
                                                                <option value="Acvgummies2">Acvgummies2</option>
                                                                <option value="Brain15p">Brain15p</option>
                                                                <option value="Brain20p">Brain20p</option>
                                                                <option value="Get1hardpp">Get1hardpp</option>
                                                                <option value="Synaptic">Synaptic</option>
                                                                <option value="Alphadrive">Alphadrive</option>
                                                                <option value="Blueforrest">Blueforrest</option>
                                                                <option value="Skincare2">Skincare2</option>
                                                                <option value="Skincare2p">Skincare2p</option>
                                                            </Field>
                                                            <CustomErrorMsg name="lander_file_name" />
                                                        </div>
                                                        <div className="form-group md-3 text-end">
                                                            <button type="submit" className="btn submitBtn btn-primary">Submit</button>
                                                        </div>
                                                    </Form>
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        {/* <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={Id ? update : submit}>
                                {Id ? "Update" : "Save"}
                            </Button>
                        </Modal.Footer> */}
                    </Modal>
                </div>

            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Lander List</div>
                            <div className="buttun_div  col-md-9">  <Button variant="primary" onClick={createModal}>
                                Create Lander
                            </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="Lander table is empty" //or your component
                                    columns={columns}
                                    data={searchTable}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignementLander