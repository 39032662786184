import React, { useState, useEffect } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import Swal from 'sweetalert2';

function AutoProductMap() {
    const [mappedproductList, setMappedProductLists] = useState([]);
    const [searchProducts, setSearchProducts] = useState([]);
    const [pending, setPending] = useState(true);
    const [search, setSearch] = useState("");
    const [show, setShow] = useState(false);
    const [products, setproducts] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [price, setPrice] = useState('');
    const [offerList, setOfferList] = useState([]);
    const [billingModelList, setBillingModelsList] = useState([]);
    const [crmProductList, setcrmProductList] = useState([]);
    const [shippingMethodList, setshippingMethodList] = useState([]);
    const [productName, setProductName] = useState("");
    const [camidName, setcamidName] = useState("");
    const [offerName, setofferName] = useState("");
    const [billingModeleName, setbillingModeleName] = useState("");
    const [CRMproductName, setCRMProductName] = useState("");
    const [shippingName, setshippingName] = useState("");
    const [loadershow, setloaderShow] = useState(false);
    const [mappedProductDetails, setMappedProductDetails] = useState([]);
    const [id, setId] = useState([]);

    async function getMappedProductList() {
        await axios.get(`api/listProductMapping`).then(res => {
            setMappedProductLists(res.data);
            setSearchProducts(res.data);
            setPending(false);
        })
    }

    function getProductList() {
        axios.get(`api/listnotmappedproduct`).then(res => {
            setproducts(res.data.data);
        })
    }

    function getCampaignList() {
        axios.get(`api/getCampaignList`).then(res => {
            setCampaigns(res.data.data);
        })
    }

    function getOfferList() {
        axios.get(`api/get-all-offers`).then(res => {
            setOfferList(res.data.data.data);
        })
    }

    function getshippingMethodList() {
        axios.get(`api/getShippingMethodList`).then(res => {
            setshippingMethodList(res.data.data);
        })
    }

    async function createProductModal() {
        setShow(true);
        setId('');
        setPrice('');
    }

    async function showSingleData(id) {
        setId(id);
        setloaderShow(true);
        setProductName("");
        setcamidName("");
        setofferName("");
        setbillingModeleName("");
        setCRMProductName("");
        setshippingName("");
        await axios.get(`api/detailsProductMapping/${id}`).then(res1 => {
            setMappedProductDetails(res1.data);
            handleOfferInputChange(res1.data.offer_id);
            setloaderShow(false);
            setShow(true);
        })
    };

    const columns = [
        {
            name: "Product Name",
            selector: row => row.name
        },
        {
            name: "CRM Product ID",
            selector: row => row.crm_product_name
        },
        {
            name: "Product Price",
            cell: row => (
                <span>${row.price}</span>
            )
        },
        {
            name: "Campaign ID",
            cell: row => (
                <span>{row.campaign_name}</span>
            )
        },
        {
            name: "Offer ID",
            cell: row => (
                <span>{row.offer_name}</span>
            )
        },
        {
            name: "Billing Model",
            cell: row => (
                <span>{row.billing_model_name}</span>
            )
        },
        {
            name: "Shipping",
            cell: row => (
                <span>{row.shipping_name}</span>
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { showSingleData(row.id) }} ><FontAwesomeIcon icon={faGear} /></button>
                    <button className='btn btn-danger' onClick={() => { deleteMappedProduct(row.id) }}><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]

    useEffect(() => {
        getMappedProductList();
        getProductList();
        getCampaignList();
        getOfferList();
        getshippingMethodList();
    }, []);

    useEffect(() => {
        const result = mappedproductList.filter(mappedProduct => {
            return mappedProduct.name.toLowerCase().match(search.toLowerCase());
        })
        setSearchProducts(result);
    }, [search]);

    const handleClose = () => {
        setShow(false);
        setSearch('');
    }

    const handleInputChange = (event, setFieldValue) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const selectedPrice = selectedOption.getAttribute('data-price');
        const selectedProduct = selectedOption.getAttribute('data-pname');
        setProductName(selectedProduct);
        setPrice(selectedPrice);
        setFieldValue('price', selectedPrice);
    }

    const handleInputCampaignSelect = (event, setFieldValue) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const selectedCampaign = selectedOption.getAttribute('data-camname');
        setcamidName(selectedCampaign);
    }

    const handleOfferInputChange = (offerId) => {
        offerList.map(function (OffersAll, i) {
            if (OffersAll['id'] == offerId) {
                setBillingModelsList(OffersAll.billing_models);
                setcrmProductList(OffersAll.products);
                setofferName(OffersAll.name);
            }
        })
    }

    const handleBillingModelSelect = (event) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const selectedBillingModel = selectedOption.getAttribute('data-billingmodel');
        setbillingModeleName(selectedBillingModel);
    }

    const handleCRMProductSelect = (event) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const selectedCRMProduct = selectedOption.getAttribute('data-crmproduct');
        setCRMProductName(selectedCRMProduct);
    }

    const handleShippingMethod = (event) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const selectedShippingMethod = selectedOption.getAttribute('data-shippingmethod');
        setshippingName(selectedShippingMethod);
    }

    const submitProductMapping = (data) => {
        handleClose();
        const formdata = new FormData();
        formdata.append('product_id', data.name);
        formdata.append('campaign_id', data.camid);
        formdata.append('offer_id', data.offerid);
        formdata.append('billing_model_id', data.billingmodid);
        formdata.append('shipping_id', data.shipid);
        formdata.append('price', data.price);
        formdata.append('crm_product_id', data.crmpid);

        formdata.append('product_name', '('+data.name+')'+productName);
        formdata.append('campaign_name', '('+data.camid+')'+camidName);
        formdata.append('offer_name', '('+data.offerid+')'+offerName);
        formdata.append('billing_model_name', '('+data.billingmodid+')'+billingModeleName);
        formdata.append('shipping_name', '('+data.shipid+')'+shippingName);
        formdata.append('crm_product_name', '('+data.crmpid+')'+CRMproductName);

        //console.log(Object.fromEntries(formdata));
        axios.post(`api/createProductMapping`, formdata).then(res => {
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                });
                getMappedProductList();
                getProductList();
            }
        }).catch(err => {
            console.log(err.response)
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });
    };

    const updateProductMapping = (data) => {
        handleClose();
        setloaderShow(true);
        const formdata = new FormData();
        formdata.append('product_id', data.name != "0" ? data.name : mappedProductDetails.product_id);
        formdata.append('campaign_id', data.camid != "0" ? data.camid : mappedProductDetails.campaign_id);
        formdata.append('offer_id', data.offerid != "0" ? data.offerid : mappedProductDetails.offer_id);
        formdata.append('billing_model_id', data.billingmodid != "0" ? data.billingmodid : mappedProductDetails.billing_model_id);
        formdata.append('shipping_id', data.shipid != "0" ? data.shipid : mappedProductDetails.shipping_id);
        formdata.append('price', price ? price : mappedProductDetails.price);
        formdata.append('crm_product_id', data.crmpid != "0" ? data.crmpid : mappedProductDetails.crm_product_id);

        formdata.append('product_name', productName ? '('+data.name+')'+productName : mappedProductDetails.product_name);
        formdata.append('campaign_name', camidName ? '('+data.camid+')'+camidName : mappedProductDetails.campaign_name);
        formdata.append('offer_name', offerName ? '('+data.offerid+')'+offerName : mappedProductDetails.offer_name);
        formdata.append('billing_model_name', billingModeleName ? '('+data.billingmodid+')'+billingModeleName : mappedProductDetails.billing_model_name);
        formdata.append('shipping_name', shippingName ? '('+data.shipid+')'+shippingName : mappedProductDetails.shipping_name);
        formdata.append('crm_product_name', CRMproductName ? '('+data.crmpid+')'+CRMproductName : mappedProductDetails.crm_product_name);
        //console.log(Object.fromEntries(formdata));
        axios.post(`api/updateProductMapping/${id}`, formdata).then(res => {
            if (res.data.status === true) {
                handleClose();
                setloaderShow(false);
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getMappedProductList();
                getProductList();
            }
        }).catch(err => {
            window.location.reload();
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });
    };

    function deleteMappedProduct(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this Mapped Product?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`api/deleteProductMapping/${id}`).then(res => {
                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    getMappedProductList();
                    getProductList();
                })
            }
        })
    };

    return (
        <div>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Map Products with CRM</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-md-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <Formik
                                            enableReinitialize = {true}
                                            initialValues={{
                                                name: mappedProductDetails.product_id,
                                                price: mappedProductDetails.price,
                                                camid: mappedProductDetails.campaign_id,
                                                offerid: mappedProductDetails.offer_id,
                                                billingmodid: mappedProductDetails.billing_model_id,
                                                crmpid: mappedProductDetails.crm_product_id,
                                                shipid: mappedProductDetails.shipping_id,
                                            }}
                                            validationSchema={Yup.object({
                                                name: Yup.string().required('Please select a product'),
                                                price: Yup.number().required('Price is required'),
                                                camid: Yup.string().required('Please select a campaign'),
                                                offerid: Yup.string().required('Please select an offer'),
                                                billingmodid: Yup.string().required('Please select billing model'),
                                                crmpid: Yup.string().required('Please select crm product'),
                                                shipid: Yup.string().required('Please select shipping for the product'),
                                            })}

                                            onSubmit={(values, { setSubmitting }) => {
                                                setSubmitting(true);
                                                //alert(JSON.stringify(values, null, 2));
                                                {(id) ? updateProductMapping(values) : submitProductMapping(values); }
                                            }}
                                        >
                                        {({ setFieldValue }) => (
                                            <Form>
                                                <div className='form-group md-3'>
                                                    <label> Name </label>
                                                    <Field 
                                                        as="select" 
                                                        name="name" 
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            setFieldValue('name', event.target.value);
                                                            handleInputChange(event, setFieldValue);
                                                        }}
                                                    >
                                                        <option value="">Select Product</option>
                                                        {mappedProductDetails.product_id ? <option value={mappedProductDetails.product_id} key={mappedProductDetails.id} data-price={mappedProductDetails.price} >{mappedProductDetails.product_name}</option> : ""}
                                                        {products && products.map(products => {
                                                            return (
                                                                <option value={products.id} key={products.id} data-price={products.price} data-pname={products.name}>
                                                                    ({products.id}){products.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Field>
                                                    <CustomErrorMsg name="name" />
                                                </div>
                                                <div className='form-group md-3'>
                                                    <label> Price </label>
                                                    <Field type="number" disabled name="price" className="form-control" value={price ? price :  mappedProductDetails.price}></Field>
                                                    <CustomErrorMsg name="price" />
                                                </div>
                                                <div className='form-group md-3'>
                                                    <label>Campaigns</label>
                                                    <Field 
                                                        as="select" 
                                                        name="camid" 
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            setFieldValue('camid', event.target.value);
                                                            handleInputCampaignSelect(event, setFieldValue);
                                                        }}
                                                    >
                                                        <option value="">Select Campaign</option>
                                                        {campaigns.map(campaigns => {
                                                            return (
                                                                <option value={campaigns.id} key={campaigns.id} data-camname={campaigns.name}>
                                                                    ({campaigns.id}){campaigns.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Field>
                                                    <CustomErrorMsg name="camid" />
                                                </div>
                                                <div className='form-group md-3'>
                                                    <label> Offers</label>
                                                    <Field 
                                                        as="select"  
                                                        name="offerid" 
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            setFieldValue('offerid', event.target.value);
                                                            handleOfferInputChange(event.target.value);
                                                        }}
                                                    >
                                                        <option value="">Select Offer</option>
                                                        {offerList?.map(offerList => {
                                                            return (
                                                                <option value={offerList.id} key={offerList.id}>
                                                                    ({offerList.id}){offerList.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Field>
                                                    <CustomErrorMsg name="offerid" />
                                                </div>
                                                <div className='form-group md-3'>
                                                    <label> Billing Model</label>
                                                    <Field 
                                                        as="select" 
                                                        name="billingmodid" 
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            setFieldValue('billingmodid', event.target.value);
                                                            handleBillingModelSelect(event);
                                                        }}
                                                    >
                                                        <option value="">Select Billing Model</option>
                                                        {billingModelList.map(billingModelList => {
                                                            return (
                                                                <option value={billingModelList.id} key={billingModelList.id} data-billingmodel={billingModelList.name}>
                                                                    ({billingModelList.id}){billingModelList.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Field>
                                                    <CustomErrorMsg name="billingmodid" />
                                                </div>
                                                <div className='form-group md-3'>
                                                    <label>CRM Products</label>
                                                    <Field 
                                                        as="select"  
                                                        name="crmpid" 
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            setFieldValue('crmpid', event.target.value);
                                                            handleCRMProductSelect(event);
                                                        }}
                                                    >
                                                        <option value="0">Select CRM Products</option>
                                                        {crmProductList.map(crmProductList => {
                                                            return (
                                                                <option value={crmProductList.id} key={crmProductList.id} data-crmproduct={crmProductList.name}>
                                                                    ({crmProductList.id}){crmProductList.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Field>
                                                    <CustomErrorMsg name="crmpid" />
                                                </div>
                                                <div className='form-group md-3'>
                                                    <label> Shipping Method</label>
                                                    <Field 
                                                        as="select" 
                                                        name="shipid" 
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            setFieldValue('shipid', event.target.value);
                                                            handleShippingMethod(event);
                                                        }}
                                                    >
                                                        <option value="0">Select Shipping Method</option>
                                                        {shippingMethodList.map(shippingMethodList => {
                                                            return (
                                                                <option value={shippingMethodList.id} key={shippingMethodList.id} data-shippingmethod={shippingMethodList.name}>
                                                                    ({shippingMethodList.id}){shippingMethodList.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Field>
                                                    <CustomErrorMsg name="shipid" />
                                                </div>
                                                <br />
                                                <div className='form-group md-3 text-end'>
                                                    <button type="submit" className='btn submitBtn btn-primary'>Save</button>
                                                </div>
                                            </Form>
                                        )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Mapped Product List</div>
                            <div className="buttun_div  col-md-9">  <Button variant="primary" onClick={createProductModal}>
                                Create Product Mapping
                            </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='datatable'>
                                <DataTable
                                    noDataComponent="Mapped Product table is empty" //or your component
                                    columns={columns}
                                    data={searchProducts}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="Search by product name" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AutoProductMap