import React, {useState, useEffect, useRef } from "react";
import DataTable from 'react-data-table-component';
import styles from '../../assests/admin/css/Admin.module.css';
import cross from '../../assests/admin/assets/img/close.png';
import plus from '../../assests/admin/assets/img/plus.png';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import Swal from 'sweetalert2';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import Button from "react-bootstrap/esm/Button";

function ProspectReportDetails() {
    const [divVisibility, setDivVisibility] = useState({});
    const [report, setReport] = useState([]);
    const [searchData, setsearchData] = useState("");
    const [search, setSearch] = useState([]);
    const [pending, setPending] = useState(true);
    const [loadershow, setloadershow] = useState(false);
    const today = new Date();
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);
    const [data, setData] = useState({});
    const [showDatePicker, setShowDatePicker] = useState(false);
    const dateRangeRef = useRef(null);
    const [campaignName, setCampaignName] = useState('');
    const [productId, setProductId] = useState('');
    const [campaignId, setCampaignId] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    
    const handleSelect = (ranges) => {
        const startDate = moment(ranges.selection.startDate);
        const formattedStartDate = startDate.format('YYYY-MM-DD');
        const endDate = moment(ranges.selection.endDate);
        const formattedEndDate = endDate.format('YYYY-MM-DD');
        setData({'startDate':formattedStartDate, 'endDate':formattedEndDate});
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
    }
    
    const toggleVisibility = (divId) => { 
        setDivVisibility(prevState => ({ 
          ...prevState, 
          [divId]: !prevState[divId] 
        })); 
    };

    function prospectReport() {
        setPending(true);
        axios.get('api/count-prospect-data').then(res => {
            //console.log(res.data.data);
            setReport(res.data.data);
            setSearch(res.data.data);
            setPending(false);
        }).catch(error => {
            //console.log(error);
            Swal.fire({
                title: 'Warning',
                html: error.data.message,
                icon: 'warning'
            })
        })
    }
    
    useEffect(()=>{
        prospectReport();
        function handleClickOutside(event) {
            if (dateRangeRef.current && !dateRangeRef.current.contains(event.target)) {
                setDivVisibility({});
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    },[dateRangeRef]);

    const handleInputCampaignName = (searchItem) => {
        setData(prevData => ({
            ...prevData,
            campaign_name: searchItem
        }));
        setCampaignName(searchItem);
        setProductId('');
        setCampaignId('');
    }

    const handleInputProduct = (searchItem) => {
        setData(prevData => ({
            ...prevData,
            product_id: searchItem
        }));
        setProductId(searchItem);
        setCampaignName('');
        setCampaignId('');
    }

    const handleInput = (searchItem) => {
        setData(prevData => ({
            ...prevData,
            campaign_id: searchItem
        }));
        setCampaignId(searchItem);
        setCampaignName('');
        setProductId('');
    }

    const handleSubmit = () => {
        if(startDate || endDate || campaignName || productId || campaignId){
            setDivVisibility({});
            setPending(true);
            axios.post(`api/get-report-by-filter`,data).then(res => {
                if(res.data.status === true){
                    //console.log(res.data.data);
                    setReport(res.data.data);
                    setSearch(res.data.data);
                    setPending(false);
                } else {
                    Swal.fire({
                        title: 'Warning',
                        html: res.data.message,
                        icon: 'warning'
                    })
                    setReport([]);
                    setSearch([]);
                    setPending(false);
                }
            }).catch(err => {
                Swal.fire({
                    title: 'Warning',
                    html: err.response.data.message,
                    icon: 'warning'
                })
            })
        } else {
            Swal.fire({
                title: 'Warning',
                html: 'Please select a input',
                icon: 'warning'
            })
        }
    }
    
    /*useEffect(() => {
        const result = report?.filter(roprtDetails => {
            return roprtDetails.campaign.toLowerCase().match(searchData.toLowerCase());
        })
        setSearch(result);
    }, [searchData]);*/

    // dummy
    const columns = [
        {
            name: "Campaign",
            selector: row => row.campaign_name
        },
        {
            name: "Prospects",
            selector: row => row.totalProspects
        },
        {
            name: "Initial Customers",
            selector: row => row.totalCustomer
        },
        {
            name: "Conversion Rate",
            selector: row => row.conversion_rate+'%'
        },
        {
            name: "Gross Revenue",
            selector: row => '$'+row.total_order_sum
        },
        {
            name: "AOV",
            selector: row => '$'+row.aov
        },
        {
            name: "Affiliate Breakdown",
            selector: row => row.affid
        },
    ];

    return (
        <div className={styles.pageWrapper}>              
            <div className={styles.reportSerachBarOutter}>
                <div className={`row justify-content-start ${styles.reportSerachBar}`} >
                    <div className="col">
                        <span className={styles.customDropDown__label}>Date:</span>
                        <div className={styles.customDropDown}>
                            <div className={styles.customDropDownBtn} onClick={() => toggleVisibility('div1')}>
                                {startDate && endDate ? startDate+'-'+endDate : 'Filter By Date'}
                            </div>
                            {divVisibility['div1'] && (
                                <div className={styles.customDropDownBody}>
                                    <div className={styles.customDropDownCard}>
                                        <div className={styles.customDropDownCardInner}>
                                            <div className={styles.formInner} ref={dateRangeRef}>
                                                <DateRangePicker
                                                    onChange={(item) => {
                                                        setState([item.selection]);
                                                        handleSelect(item);
                                                    }}
                                                    showSelectionPreview={true}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={state}
                                                    maxDate={today}
                                                    direction="horizontal"
                                                />
                                            </div>
                                            <span className={styles.addBtn}><img src={plus}  /></span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col">
                        <span className={styles.customDropDown__label}>Campaign ID:</span>
                        <div className={styles.customDropDown}>
                            <div className={styles.customDropDownBtn} onClick={() => toggleVisibility('div2')}>
                                {campaignId ? campaignId : 'is any value'}
                            </div>
                            {divVisibility['div2'] && (
                                <div className={styles.customDropDownBody}>
                                    <div className={styles.customDropDownCard}>
                                        <div className={styles.customDropDownCardInner}>
                                            <div className={styles.formInner} ref={dateRangeRef}>
                                                <select className='form-control'>
                                                    <option>is</option>
                                                </select>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder="Campaign ID"
                                                    value={campaignId} 
                                                    onChange={(e)=>handleInput(e.target.value)}
                                                />                                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}                              
                        </div>
                    </div>
                    <div className="col">
                        <span className={styles.customDropDown__label}>Product ID:</span>
                        <div className={styles.customDropDown}>
                            <div className={styles.customDropDownBtn} onClick={() => toggleVisibility('div3')}>
                                {productId ? productId : 'is any value'}
                            </div>
                            {divVisibility['div3'] && (
                                <div className={styles.customDropDownBody}>
                                    <div className={styles.customDropDownCard}>
                                        <div className={styles.customDropDownCardInner}>
                                            <div className={styles.formInnerLists}>
                                                <div className={styles.formInner} ref={dateRangeRef}>
                                                    <select className='form-control'>
                                                        <option>is</option>
                                                    </select>
                                                    <input 
                                                        list="productsName" 
                                                        className="form-control" 
                                                        placeholder="Product ID"
                                                        value={productId} 
                                                        onChange={(e)=>handleInputProduct(e.target.value)}
                                                    />      
                                                    <datalist id="productsName">
                                                        <option value="" />
                                                    </datalist>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}                                 
                        </div>
                    </div>
                    <div className="col">
                        <span className={styles.customDropDown__label}>Campaign Name:</span>
                        <div className={styles.customDropDown}>
                            <div className={styles.customDropDownBtn} onClick={() => toggleVisibility('div4')}>
                                {campaignName ? campaignName : 'is any value' }
                            </div>
                            {divVisibility['div4'] && (
                                <div className={styles.customDropDownBody}>
                                    <div className={styles.customDropDownCard}>
                                        <div className={styles.customDropDownCardInner}>
                                            <div className={styles.formInner} ref={dateRangeRef}>
                                                <select className='form-control'>
                                                    <option>is</option>
                                                </select>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder="Campaign Name" 
                                                    value={campaignName}
                                                    onChange={(e) => handleInputCampaignName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}                              
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <button type="button" className={styles.iconBtn} onClick={()=>handleSubmit()}>
                            <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="StyledIconBase-ea9ulj-0 bWRyML"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M17.65 6.35A7.958 7.958 0 0012 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0112 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path></svg>
                        </button>
                    </div>
                    <div className="col">
                        <Dropdown className={styles.reportDropdown}>
                            <Dropdown.Toggle variant="" id="dropdown-basic" className={`${styles.option} ${styles.iconBtn}`}>
                                <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 bWRyML"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Download</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Reset Filter</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className={styles.dataTable}>
                <div className='datatable'>
                    <DataTable
                        noDataComponent="No Data Available"
                        columns={columns} 
                        data={search} 
                        progressPending={pending}
                    />
                </div>             
            </div>
        </div>
    )
}

export default ProspectReportDetails;