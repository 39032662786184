import React, { useState, useEffect } from 'react'
import '../../assests/admin/css/thankyou.css';
import cartIcon from '../../assests/frontend/assets/images/shopping-cart.png';
import Table from 'react-bootstrap/Table';
import useStore from '../../store';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Link } from 'react-router-dom';
import Navbar from '../../layouts/frontend/Navbar';
import Copyright from "../Copyright";
import NavbarV2 from '../../layouts/frontend/NavbarV2';
import Footer from '../../layouts/frontend/FooterV2';
import { Helmet } from 'react-helmet';


function ThankYou() {
    const [orderData, setOrderData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [orderStatus, setOrderStatus] = useState("");
    const [div, setDiv] = useState(false);
    const [neobodId, setNeobodId] = useState('');
    const [neobodStatus, setNeobodStatus] = useState('');
    const [total, setTotal] = useState('');
    const [loadershow, setloadershow] = useState(false);
    let userDetails = useStore((state) => state.userDetails);
    const moment = extendMoment(Moment);
    let order_id = localStorage.getItem('orderId');
    let pixel = localStorage.getItem('pixel');
    

    let getOrderList = () => {
        let orderIDArray = [];
        if(localStorage.getItem('orderId')){
            orderIDArray.push(localStorage.getItem('orderId'));
        }
        if(localStorage.getItem('membership_orderId')!='false'){
            orderIDArray.push(localStorage.getItem('membership_orderId'));
        }
        if(localStorage.getItem('Upsell1_orderId')){
            orderIDArray.push(localStorage.getItem('Upsell1_orderId'));
        }
        if(localStorage.getItem('Upsell2_orderId')){
            orderIDArray.push(localStorage.getItem('Upsell2_orderId'));
        }
        
        let neobodId = localStorage.getItem('neobodOrderId');
        let neobodStatus = localStorage.getItem('neobodOrderStatus');
        if(neobodId){
            setDiv(true);
            setNeobodId(neobodId);
            setNeobodStatus(neobodStatus);
        }
        const formdata = new FormData();
        formdata.append('order_id', JSON.stringify(orderIDArray));
        axios.post(`api/get_order_list`, formdata).then(res => {
            //console.log(res.data);
            if (res.data.status === true) {
                //let OrderItems = Object.entries(res.data).map(([key, value]) => ({ value }));
                setOrderData(res.data.data.items);
                setTotal(res.data.data.total);
                localStorage.removeItem('funnel');
                localStorage.removeItem('combine_product');
                localStorage.removeItem('combine_product_id');
                localStorage.removeItem('method');
                localStorage.removeItem('CCType');
                localStorage.removeItem('CCNumber');
                localStorage.removeItem('expirationDate');
                localStorage.removeItem('cvv');
                
                localStorage.removeItem('total');
                //localStorage.removeItem('neobodOrderId');
                //localStorage.removeItem('neobodOrderStatus');
                localStorage.removeItem('neobodCid');
                localStorage.removeItem('neobodPId');
                //localStorage.removeItem('orderId');
                localStorage.removeItem('ProductId');
                localStorage.removeItem('upsellProductId');
                // localStorage.removeItem('Upsell1_orderId');
                // localStorage.removeItem('Upsell2_orderId');


                /*localStorage.removeItem('firstName');
                localStorage.removeItem('lastName');
                localStorage.removeItem('email');
                localStorage.removeItem('phone');
                localStorage.removeItem('shippingAddress1');
                localStorage.removeItem('shippingCity');
                localStorage.removeItem('shippingState');
                localStorage.removeItem('shippingZip');*/

                localStorage.removeItem('affid');
                localStorage.removeItem('sid');
                localStorage.removeItem('s1');
                localStorage.removeItem('s2');
                localStorage.removeItem('s3');
                localStorage.removeItem('click_id');
                localStorage.removeItem('notes');

                localStorage.removeItem('up1_s');
                localStorage.removeItem('up2_s');
                localStorage.removeItem('up3_s');
                localStorage.removeItem('c_s');
                localStorage.removeItem('con_s');
            }
        }).catch(err => {
            //console.log(err);
            Swal.fire({
                title: 'Warning',
                html: err.response.data,
                icon: 'warning'
            })
        });
    }

    useEffect(() => {
        getOrderList();
    },[]);

    return (
        <>
        <NavbarV2></NavbarV2>
        {pixel === 'facebook' ?
            <>
                <Helmet>
                    <script>
                        {`
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '1440871839866690');
                            fbq('track', 'PageView');
                            fbq('track', 'Purchase', {value: 0.00, currency: 'USD'});
                        `}
                    </script>
                    <noscript>
                        {`
                            <img 
                                height="1" 
                                width="1" 
                                style={{display: 'none'}}
                                src="https://www.facebook.com/tr?id=1440871839866690&ev=PageView&noscript=1"
                            />
                        `}
                    </noscript>
                </Helmet>
            </>
        : ''}
        <div className="thankYou-page">            
            <div className="container">
                <div className='row'>
                    <div className='col-md-12 col-lg-12 mx-auto  mt-5 thankYou-cont'>
                        <div className="thankyouTable-wrapper">
                            <div className="thankyouTable-hdr">
                                <h4 className='title'><span className="cartIcon"> <img src={cartIcon} className="img-fluid" /></span> Order History (most recent orders) - {moment().format('M/D/Y')}</h4>
                                {/* <Button variant="primary" onClick={handleShow}>
                                    View All
                                </Button> */}
                            </div>
                            <div className='table-responsive'>
                                <Table striped  hover>
                                    <thead>
                                        <tr>
                                            <th>ORDER #</th>
                                            <th>DESCRIPTION</th>
                                            <th>QUANTITY</th>
                                            <th className='unit_price_th'>UNIT PRICE</th>
                                            <th className='item_price_th'>AMOUNT</th>
                                            <th className='item_status_th'>STATUS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {orderData.map((item) => (
                                            <tr>
                                                <td>{item.order_id}</td>
                                                {/* <td>{moment(item.acquisition_date).format('M/D/Y')}</td> */}
                                                <td>{item.name}</td>
                                                <td className='qty'>{item.product_qty}</td>
                                                <td className='unit_price_th'>${item.unit_price}</td>
                                                <td className='item_price_th'>${item.price}</td>
                                                <td className='item_status_th'>{item.order_status}</td>
                                            </tr>
                                        ))}
                                        {div &&
                                            <tr>
                                                <td>{neobodId}</td>
                                                {/* <td>{moment().format('M/D/Y')}</td> */}
                                                <td>Neobod Upsell</td>
                                                <td>1</td>
                                                <td className='unit_price_th'>$0.00</td>
                                                <td className='item_price_th'>$0.00</td>
                                                <td className='item_status_th'>{neobodStatus}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-12 mx-auto  mt-5 thankYou-cont'>
                        <h3>Thank you for your order, {localStorage.getItem('firstName')} {localStorage.getItem('lastName')} <Link to="/bonus">GET YOUR FREE BONUSES NOW!</Link></h3>
                    </div>
                    <div className='col-md-7 col-lg-5 mx-auto thankYou-cont'>
                        <p className='thankYou-card'><strong>Your login credentials are:</strong><br/>
                            Username: {localStorage.getItem('email')}<br/>
                            Password: <em>Hidden for security purposes</em></p>
                        <p className='thankYou-card'><strong>Here are your order details:</strong><br/>
                            Name: {localStorage.getItem('firstName')} {localStorage.getItem('lastName')}<br/>
                            Email: {localStorage.getItem('email')}</p>
                        {/* <p className='thankYou-card'>Order ID: {localStorage.getItem('orderId')}<br/>
                            Subtotal: ${localStorage.getItem('total')}</p> */}
                        <p className='thankYou-card'>Order Total: ${total}</p>
                        <p className='thankYou-card'>Billing Address:<br/>
                            {localStorage.getItem('firstName')}<br/>
                            {localStorage.getItem('shippingCity')}, {localStorage.getItem('shippingState')} {localStorage.getItem('shippingZip')}<br/>
                            US</p>
                        <p className='thankYou-card'>If you have any questions concerning your order, feel free to contact us at <a
                                href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.</p>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default ThankYou