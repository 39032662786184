import React, { useState, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft, faEye } from '@fortawesome/free-solid-svg-icons'
import { MultiSelect } from "react-multi-select-component";
import CheckPermission from '../frontend/auth/CheckPermission';

function CheckoutFunnelList() {
    CheckPermission()
    const navigate = useNavigate();
    const [userList, setUserList] = useState([]);
    const [pending, setPending] = useState(true);
    const [search, setSearch] = useState("");
    const [searchUser, setSearchUser] = useState([]);
    function addCheckout() {
        navigate('/admin/checkoutfunnels')
    }
    async function getFunnelList() {
        axios.get(`api/listCheckoutFunnel`).then(res => {
            // console.warn(res.data.data);
            setUserList(res.data);
            setSearchUser(res.data)

            setPending(false);
        })

    }
    const columns = [
        {
            name: "Name",
            cell: row => (
                <span>{row.funnel_name}</span>
            )
        },
        {
            name: "Slug",
            cell: row => (
                <span>{row.funnel_slug}</span>
            )
        },
        {
            name: "Teamplete",
            cell: row => (
                <span>{row.template_id == 1 ? 'Template 1' : row.template_id == 2 ? 'Template 2' : row.template_id == 3 ? 'Template 3' : row.template_id == 5 ? 'Template 5' :  row.template_id == 6 ? 'Template 6' : 'Template 4'}</span>
            )
        },

        {
            name: "1st Product",
            cell: row => (
                <span>{row.first_product_name}</span>
            )
        },
        {
            name: "2nd Product",
            cell: row => (
                <span>{row.second_product_name}</span>
            )
        },
        {
            name: "3rd Product",
            cell: row => (
                <div>{row.third_product_name}</div>
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <a style={{ marginRight: 1 + 'em' }} target='_blank' className='btn btn-primary' href={'/' + row.funnel_slug} ><FontAwesomeIcon icon={faEye} /></a>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { navigate('/admin/checkoutfunnelsedit?checkoutFunnelId=' + row.id) }} ><FontAwesomeIcon icon={faGear} /></button>
                    <button className='btn btn-danger' onClick={() => { deleteFunnel(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]
    useEffect(() => {
        getFunnelList()
    }, []);

    function deleteFunnel(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this Checkout Funnel?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`api/deleteCheckoutFunnel/${id}`).then(res => {

                    if (res.data.status) {
                        Swal.fire({
                            title: 'Deleted',
                            html: res.data.message,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        })
                        getFunnelList();
                    }
                    else {
                        Swal.fire({
                            title: 'Warning',
                            html: res.data.message,
                            icon: 'warning'
                        })
                    }

                })
            }
        })
    }


    useEffect(() => {
        const result = userList.filter(country => {
            return country.first_product_name.toLowerCase().match(search.toLowerCase());
        })
        setSearchUser(result);
    }, [search]);

    return (
        <div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Checkout Page List</div>
                            <div className="buttun_div  col-md-9">
                                <Button variant="primary" onClick={addCheckout}>
                                    Create Checkout
                                </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="No Checkout's available " //or your component
                                    columns={columns}
                                    data={searchUser}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckoutFunnelList