import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Papa from 'papaparse';
import axios from 'axios';

const CSVUpload = () => {
    const formik = useFormik({
        initialValues: {
        csvFile: null,
        },
        validationSchema: Yup.object({
        csvFile: Yup.mixed().required('A CSV file is required'),
        }),
        onSubmit: (values) => {
        // Parsing CSV
        Papa.parse(values.csvFile, {
            complete: (result) => {
            const parsedData = result.data;
            
            // Send to backend API
            axios.post('/api/upload-csv', { data: parsedData })
                .then((response) => {
                    console.log('Data uploaded successfully:', response.data);
                })
                .catch((error) => {
                    console.error('Error uploading CSV data:', error);
                });
            },
            header: true, // assumes the first row contains headers
        });
        },
    });

    // Handling file change
    const handleFileChange = (event) => {
        const file = event.currentTarget.files[0];
        if (file && file.type === 'text/csv') {
        formik.setFieldValue('csvFile', file);
        } else {
        formik.setFieldError('csvFile', 'Please upload a valid CSV file');
        }
    };

    return (
        <form onSubmit={formik.handleSubmit}>
        <div>
            <label htmlFor="csvFile">Upload CSV</label>
            <input
            id="csvFile"
            name="csvFile"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            />
            {formik.errors.csvFile ? <div>{formik.errors.csvFile}</div> : null}
        </div>

        <button type="submit">Submit</button>
        </form>
    );
};

export default CSVUpload;
