import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

//css
import style from '../../assests/Lander/assets/css/AlphaDriveMobile.module.css';


import logo from '../../assests/Lander/assets/images/d-pill/alpha-drive-logo.png';
import s3model from '../../assests/Lander/assets/images/d-pill/home/s3-modal.png';
import doc from '../../assests/Lander/assets/images/d-pill/home/doc.png';
import bloodImg from '../../assests/Lander/assets/images/d-pill/home/blood-img.png';
import orangeBg from '../../assests/Lander/assets/images/d-pill/home/orange-bg.png';
import step1 from '../../assests/Lander/assets/images/d-pill/home/step1.png';
import step2 from '../../assests/Lander/assets/images/d-pill/home/step2.png';
import s4Img from '../../assests/Lander/assets/images/d-pill/home/s4-img.png';
import primary from '../../assests/Lander/assets/images/d-pill/primary.png';
import submitBtn from '../../assests/Lander/assets/images/d-pill/order-btn.png';
import upArrow from '../../assests/Lander/assets/images/up-arrow-white.png';
import s3Line from '../../assests/Lander/assets/images/d-pill/s3-line.png';


function AlphadriveMobile(){
    const ref = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    let params = location.search;

    const handleClickRef = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    function handleClick(){
        navigate(window.location.href = '/' + localStorage.getItem('c_s')+params);
    }
    return(
        <>
        <head>
            <meta content="width=640, user-scalable=0" name="viewport" />
            <meta name="robots" content="noindex, nofollow" />
        </head>
        <main className={style.dickPillMob} ref={ref}>
            <div className={`${style.mob_container} p-0`}>

                {/* Section 1 : Begin  */}
                <div id="section-one" className={style.section_one}>
                    <div className={style.sec_hight}>
                        <img alt="Logo" className={style.logo} src={logo} />
                        <i className={`${style.sprite} ${style.sprite_usa} ${style.usa}`}></i>
                        <p className={style.s1_txt1}>Medical Strength Male Enhancement</p>
                        <p className={style.s1_txt2}>GET MAXIMUM
                            <br /> <span>SEXUAL BENEFITS</span>
                        </p>
                        <ul className={style.s1_txt3}>
                            <li>VIRILITY</li>
                            <li>VITALITY</li>
                            <li>VIGOR</li>
                        </ul>
                        <i className={`${style.sprite} ${style.sprite_as_seen} ${style.as_seen}`}></i>
                        <ul className={style.s1_list}>
                            <li><span>Bigger &amp; Long-Lasting Erections</span></li>
                            <li><span>Increase in Penis <br />
                                Size</span>
                            </li>
                            <li><span>Surge In Sex Drive &amp; Energy</span></li>
                            <li><span>Increased Sexual Confidence</span></li>
                            {/* <!--    <li><span>Increased Sexual Confidence</span></li> --> */}
                        </ul>
                    </div>
                    <p className={style.s1_txt4}>NOW AVAILABLE WITHOUT A PRESCRIPTION!</p>
                    <ul className={style.s1_list2}>
                        <li><span>LONGER</span>
                            <br /> STAMINA
                        </li>
                        <li><span>HARDER</span>
                            <br /> ERECTIONS
                        </li>
                        <li><span>STRONGER</span>
                            <br /> PERFORMANCE
                        </li>
                    </ul>
                    <div className={style.lead_scroll}>
                        <a className={style.lead_link} data-placement="top" data-toggle="tooltip" href="#theTime" id="anchor">
                            <svg className={style.arrows}>
                                <path className={style.a1} d="M0 0 L30 32 L60 0" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path className={style.a1} d="M0 0 L30 32 L60 0" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path className={style.a2} d="M0 20 L30 52 L60 20" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path className={style.a3} d="M0 40 L30 72 L60 40" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </a>
                    </div>
                </div>
                {/* Section 1 : End  */}

                {/* Section 2 Begin  */}
                <div id="section-two" className={style.section_two}>
                    <p className={style.s2_txt1}><span>THE SEXUAL HEALTH DIVIDE</span>
                        <br /> ARE YOU SUFFERING FROM THE
                        <br /> FOLLOWING SYMPTOMS
                    </p>
                    <p className={style.s2_txt2}>Leading surveys on sexual health and satisfaction levels among American men have revealed the following:</p>
                    <div className={style.both_part}>
                        <div className={style.s2_lft}>
                            <i className={`${style.sprite} ${style.sprite_s2_img1} ${style.s2_img1}`}></i>
                        </div>
                        <div className={style.s2_rgt}>
                            <p className={style.s2_rgt_txt}>Say sexual health impacts on overall life satisfaction</p>
                        </div>
                        <p className={style.clearall}></p>
                        <div className={style.s2_rgt2}>
                            <p className={style.s2_rgt2_txt}>Of men suffer from Small Penis Syndrome</p>
                        </div>
                        <div className={style.s2_lft2}>
                            <i className={`${style.sprite} ${style.sprite_s2_img2} ${style.s2_img1}`}></i>
                        </div>
                        <p className={style.clearall}></p>
                        <div className={style.s2_lft}>
                            <i className={`${style.sprite} ${style.sprite_s2_img3} ${style.s2_img1}`}></i>
                        </div>
                        <div className={style.s2_rgt}>
                            <p className={style.s2_rgt_txt}>Believe embarrassment is a major sexual barrier</p>
                        </div>
                        <p className={style.clearall}></p>
                        <div className={style.s2_rgt2}>
                            <p className={style.s2_rgt2_txt}>Avoid sex altogether because of lack of sexual confidence</p>
                        </div>
                        <div className={style.s2_lft2}>
                            <i className={`${style.sprite} ${style.sprite_s2_img4} ${style.s2_img1}`}></i>
                        </div>
                    </div>
                </div>
                {/* Section 2 End  */}

                {/* Section 3 Begin  */}
                <div id="section-three" className={style.section_three}>
                    <p className={style.s3_txt1}><span>INTRODUCING <b>Alpha Drive Rx&nbsp;</b></span>
                        <br /> MALE ENHANCEMENT SYSTEM
                    </p>
                    <i className={`${style.sprite} ${style.sprite_s3_line} ${style.s3_line}`}></i>
                    <p className={style.s3_txt2}>Made with a blend of clinical strength ingredients, Alpha Drive Rx is a male enhancement system that has been formulated to restore your sexual youth and performance and help you experience an intense, blissful &amp; powerful sex life.
                        <br />
                        <br /> <b>Alpha Drive Rx's </b>&nbsp;dual action formula not only gives you an instant surge in sexual power &amp; performance - but also treats the root cause of sexual dysfunctions, ensuring that you are able to satisfy your partner, consistently!
                    </p>
                    <div className={style.s3_bg}>
                        <p className={style.s3_txt3}>TRIPLE INTENSITY
                            <br /> <b>MALE ENHANCEMENT</b>
                            <br /> FOR MAXIMUM RESULTS 
                        </p>
                    </div>
                    <img alt="" className={style.s3_modal} src={s3model} />
                    <p className={style.s3_txt4}>The pro-sexual nutrient matrix in Alpha Drive Rx helps boost the 3S's of Sex - Size, Stamina &amp; Satisfaction, helping you peak perform and pleasure your partner just like you did in your 20's!</p>
                    <div className={style.order_bg}>
                        <p className={style.order_bg_txt}><span>ORDER YOUR <b> Alpha Drive Rx TODAY!</b></span>
                            <br />Experience Sexual Power, Pleasure &amp; Performance
                        </p>
                    </div>
                </div>
                {/* Section 3 End  */}

                {/* Section 4 Begin  */}
                <div id="section-four" className={style.section_four}>
                    <div className={style.section4_innerDiv1}>
                        <p className={style.s3_txt1}><span>THE SCIENCE BEHIND</span>
                            <br /> BETTER, LONGER &amp; INTENSE SEX!
                        </p>
                        <i className={`${style.sprite} ${style.sprite_s3_line} ${style.s3_line}`}></i>
                        <p className={style.s4_txt1}>The blood flow to the penis is responsible for erections while the holding capacity of the penis chambers is what influences sexual stamina and staying power. Alpha Drive Rx helps boost both to help you and your partner partner enjoy intense orgasms and complete satisfaction.</p>
                        <p className={style.s4_txt2}><b>Alpha Drive Rx </b>stimulates Nitric Oxide production to boost the flow of blood to the penile chambers for harder and stronger erections. It also expands the penis chambers allowing it to hold more blood, increasing sexual stamina, strength and staying power.</p>
                        <img alt="" className={style.doc} src={doc} /> <img alt="" class={style.doc2} src="images/doc2.png" />
                        <i className={`${style.sprite} ${style.sprite_seal2} ${style.seal2}`}></i> <img alt="" className={style.blood_img} src={bloodImg} />
                        <img alt="" className={style.orange_bg} src={orangeBg} />
                        <p className={style.orange_bg_txt}><b>Alpha Drive Rx</b> utilizes a breakthrough rapid absorption and extended release technology. Rapid absorption of the ingredients into the bloodstream aid in delivering instant surge of sexual power while the extended release technology delivers sustained results that help you enjoy on command erections and stamina to last all night long. </p>
                    </div>
                    <div className={style.clearall}></div>
                    <div className={style.section4_innerDiv2}>
                        <p className={style.s4_txt3}><span><b>Alpha Drive Rx </b></span>&nbsp;WORKS BY TRIGGERING THE TWO MECHANISMS KNOWN TO INCREASE PENIS SIZE, FUNCTION AND PERFORMANCE. THESE ARE:</p>
                        <img alt="" className={style.s4_img} src={s4Img} /> <img alt="" className={style.step1} src={step1} />
                        
                        <p className={style.s4_txt4}>AN INCREASE IN
                            <br /> "FREE" TESTOSTERONE
                            <br /> AND 
                        </p>
                        <img alt="" className={style.step2} src={step2} />
                        
                        <p className={style.s4_txt4}>NITRIC OXIDE
                            <br /> PRODUCTION TO THE
                            <br /> PENIS. 
                        </p>
                        <br /> 
                    </div>
                    <div className={style.order_bg}>
                        <p className={style.order_bg_txt}><span>ORDER YOUR <b> Alpha Drive Rx TODAY!</b></span>
                            <br />Experience Sexual Power, Pleasure &amp; Performance
                        </p>
                    </div>
                </div>
                {/* Section 4 End  */}

                {/* Section 5 Begin  */}
                <div id="section-five" className={style.section_five}>
                    <p className={style.s3_txt1}><span>BENEFITS OF <b>Alpha Drive Rx&nbsp;</b></span>
                        <br /> ADVANCED MALE ENHANCEMENT!
                    </p>
                    <img alt="" className={style.s3_line} src={s3Line} />
                    <p className={style.s4_txt1}><span>Alpha Drive Rx</span> Male Enhancement System offers multiple sexual health benefits to help you enjoy hard erections, increased stamina and peak performance.</p>
                    <ul className={style.sec5_list}>
                        <li>
                            <i className={`${style.sprite} ${style.sprite_s5_img1}`} id="object1"></i> <span>IMPROVED LIBIDO &amp; SEX DRIVE</span>
                            <br /> Get ready to experience a torrent of desire &amp; passion with Alpha Drive Rx, which replenishes sexual energy stores across the body like never before.
                        </li>
                        <li>
                            <i className={`${style.sprite} ${style.sprite_s5_img5}`} id="object5"></i> <span>BIGGER, HARDER &amp; LONGER ERECTIONS</span>
                            <br /> Alpha Drive Rx lets you achieve rock hard erections on command helping you and your partner enjoy insane sexual sessions, whenever you desire.
                        </li>
                        <li>
                            <i className={`${style.sprite} ${style.sprite_s5_img2}`} id="object2"></i> <span>INCREASED STAYING POWER</span>
                            <br /> Bid goodbye to pre-mature ejaculations! Alpha Drive Rx floods your penile chambers with a gush of blood letting you last 5X more than usual and helping you last all night long!
                        </li>
                        <li>
                            <i className={`${style.sprite} ${style.sprite_s5_img3}`} id="object3"></i> <span>INCREASED PENIS SIZE</span>
                            <br /> Increase in penile chamber capacity and regular boost in blood flow may help add those inches to your penis size, both length &amp; girth wise. 
                        </li>
                        <li>
                            <i className={`${style.sprite} ${style.sprite_s5_img4}`} id="object4"></i> <span>IMPROVED SEXUAL CONFIDENCE</span>
                            <br /> Equipped with youthful sexual powers &amp; energy, you are sure to experience sexual confidence like never before, gives you greater success with the most desirable women!
                        </li>
                    </ul>
                    <div className={style.seal_bg}>
                        <img alt="" className={style.s5seal} height="120" src="images/us-seal-new.png" width="120" />
                        <p className={style.seal_txt1}>Alpha Drive Rx&nbsp;er stolt produsert i Europa på sertifiserte produksjonsanlegg for å imøtekomme lovbestemte industristandarder. <span> Hvert kjøp er støttet av en tilfredsstillelsesgaranti, slik at du kan nyte fordelene med selvtillit. </span></p>
                    </div>
                    <div className={style.order_bg}>
                        <p className={style.order_bg_txt}><span>ORDER YOUR <b> Alpha Drive Rx TODAY!</b></span>
                            <br />Experience Sexual Power, Pleasure &amp; Performance
                        </p>
                    </div>
                </div>
                {/* Section 5 Begin  */}
                {/* Section 6 Begin  */}
                <div id="section-six" className={style.section_six}>
                    <p className={style.s3_txt1}><span>POWERFUL INGREDIENTS</span>
                        <br /> FOR BIGGER RESULTS
                    </p>
                    <i className={`${style.sprite} ${style.sprite_s3_line} ${style.s3_line}`}></i>
                    <ul className={style.sec6_list}>
                        <li>
                            <i className={`${style.sprite} ${style.sprite_s6_img1}`}></i>
                            <br /> <span>L-Arginine</span>
                            <br /> Stimulates nitric oxide production to boost blood circulation to the penis helping achieve biggers and stronger erections.
                        </li>
                        <li>
                            <i className={`${style.sprite} ${style.sprite_s6_img2}`}></i>
                            <br /> <span className={style.text_red}>ASIAN RED GINGER EXTRACTS</span>
                            <br /> Positively influences mood patterns to reduce stress and promote relaxation, enabling men to perform at their peak.
                        </li>
                        <li>
                            <i className={`${style.sprite} ${style.sprite_s6_img3}`}></i>
                            <br /> <span className={style.text_indigo}>SAW PALMETTO BERRY</span>
                            <br /> Helps increase staying power ensuring you and your partner enjoy longer sessions with intense orgasms.
                        </li>
                        <li>
                            <i className={`${style.sprite} ${style.sprite_s6_img4}`}></i>
                            <br /> <span className={style.text_brown}>HORNY GOAT WEED EXTRACT</span>
                            <br /> Works synergistically with the other pro-sexual nutrients to boost blood flow to the penile chambers for improved erections. It also helps expand the chambers to increase blood holding capacity and in-turn staying power.
                        </li>
                        <li>
                            <i className={`${style.sprite} ${style.sprite_s6_img5}`}></i>
                            <br /> <span className={style.text_brown}>BIOPERINE</span>
                            <br /> Helps support the formula's quick absorption technology. This allows the key herbal ingredients that support male enhancement to be absorbed quickly into the blood stream, triggering an instant boost in sexual energy, stamina and erections. 
                        </li>
                    </ul>
                    <div className={`${style.order_bg} mt-2`}>
                        <p className={style.order_bg_txt}><span>ORDER YOUR <b> Alpha Drive Rx TODAY!</b></span>
                            <br />Experience Sexual Power, Pleasure &amp; Performance
                        </p>
                    </div>
                </div>
                {/* Section 6 End  */}
                {/* Section 7 Begin  */}
                <div id="section-seven" className={style.section_seven}>
                    <p className={style.s7_txt1}><span>REAL MEN, REAL RESULTS</span>
                        <br /> SUCCESS STORIES
                    </p>
                    <i className={`${style.sprite} ${style.sprite_line2} ${style.s7_line}`}></i>
                    <p className={style.s7_txt2}><span><b>Alpha Drive Rx </b> has helped hundreds of men</span> across all ages beat sexual dysfunction and enjoy a fuller and satisfied sex life</p>
                </div>
                {/* Section 7 End  */}
                <p className={style.clearall}></p>
                <div id="section-eight" className={style.section_eight}>
                    <img alt="" className={style.s8_logo} src={logo} />
                        <i className={`${style.sprite} ${style.sprite_usa} ${style.s8_usa}`}></i>
                    <p className={style.s8_txt1}>Medical Strength Male Enhancemen</p>
                    <p className={style.s8_txt2}>GET MAXIMUM<br />
                        <span>SEXUAL BENEFITS</span>
                    </p>
                    <ul className={style.s8_list}>
                        <li><span>Bigger &amp; Long-Lasting Erections</span></li>
                        <li><span>Increase in Penis<br /> Size</span></li>
                        <li><span>Surge In Sex Drive &amp; Energy</span></li>
                        <li><span>Increased Sexual Confidence</span></li>
                    </ul>
                   
                </div>
                <div className={style.dScrollTop} onClick={handleClickRef}><img src={upArrow}></img></div>
                <div className={style.fixbox}>
                    <div className={`${style.stick_bar} ${style.mask} ${style.pseudo} clearfix`}>      
                        <img alt="" className={style.bottle} src={primary} />
                        <Link to="javascript:void(0)" id="go-next" onClick={handleClick} className={`clearfix`}>
                            <img alt="" className={`btn ${style.submit_btn} ${style.pulse} clearfix`} src={submitBtn} />
                        </Link>
                        <i className={`${style.sprite} ${style.sprite_scure} ${style.security2}`}></i>
                    </div>
                </div>
            </div>
        </main>  
        </>
    )
}

export default AlphadriveMobile;