import upsellImg1 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img1.png';
import upsellImg2 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img2.jpg';
import upsellImg3 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img3.jpg';
import upsellImg4 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img4.png';
import upsellImg5 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img5.png';
import upsellImg6 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img6.png';
import upsellImg7 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img7.png';
import upsellImg8 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img8.png';
import upsellImg9 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img9.png';
import upsellImg10 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img10.png';
import upsellImg11 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img11.jpg';
import upsellImg12 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img12.jpg';
import upsellImg13 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img13.jpg';
import upsellImg14 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img14.jpg';
import upsellImg15 from '../../assests/Lander/assets/images/skincare6p/skincarep6_up_img15.png';
import truck from '../../assests/Lander/assets/images/skincare6p/truck.png';
import badge from '../../assests/Lander/assets/images/skincare6p/circle_tick.png';
import cardImg from '../../assests/Lander/assets/images/skincare6p/card_img.png';
import upsellProduct1 from '../../assests/Lander/assets/images/skincare6p/upsell_bottle1.png';
import upsellProduct2 from '../../assests/Lander/assets/images/skincare6p/upsell_bottle2.png';
import upsellProduct3 from '../../assests/Lander/assets/images/skincare6p/upsell_bottle3.png';
import line from '../../assests/Lander/assets/images/skincare6p/line.png';
import gurantee_seal from '../../assests/Lander/assets/images/skincare6p/gurantee_seal.png';



function Skincare6pUpsell1(){
    return(
        <>
        <main className='sk6Upsell'>
            <div className="sk6Upsell_topbar">
                <div className="cont_sec">
                    <h2>WAIT, YOUR ORDER IS NOT COMPLETE – DO NOT CLICK THE BACK BUTTON</h2>
                </div>
            </div>

            <div className="sk6Upsell_banner">
                <div className="cont_sec">
                    <div className="row">
                        <div className="col">
                            <div className="sk6Upsell_banner__cont">
                                <h2 className="sk6_title">Read Below to Discover How to Rapidly <span className="txt_pink">"Rewind the Clock" </span> on Your Facial Skin…</h2>
                                <p className="sk6_subTitle">Using this next Age-Defying Breakthrough that works in beautiful harmony with Age Brilliance Neck Cream…</p>
                            </div>
                        </div>
                        <div className="col">
                            <img src={upsellImg1} className="sk6Upsell_banner__img" alt="" />   
                        </div>
                    </div>
                </div>
            </div>

            <div className="sk6Upsell_sec2">
                <div className="cont_sec">
                    <div className="upsellCont">
                        <p className='f-30 trirong_font'>Hey it's <strong>Kai here…</strong></p>
                        <p>And this is <strong>something that could quickly help your face look at least a decade younger...</strong></p>
                        <p>But first, I want to say "Congratulations" for allowing the Age Brilliance Neck Cream to help you rewind the clock on your neck's skin...</p>
                        <p>Because I can confidently say that when it comes to firming up the loose skin around the neckline and improving your self-confidence...</p>
                        <p><strong>…this is one of the most important steps you'll ever make.</strong></p>
                        <p>And in just a moment, you'll be able to finish your order...</p>
                        <p>But right now...</p>
                        <p><strong className='yellowStrip1'>I have something special for women who “want it all”</strong> ... who not only want a beautiful neckline, which you’ll be experiencing soon, but also want to have a younger and more youthful complexion...</p>
                        <p>...that can erase facial fine lines, wrinkles and firm up sagging skin.</p>
                        <p>So after giving your neck the attention it deserves, <strong>it’s time to let your face shine too...</strong></p>
                        <p>And it's something that could <strong>quickly help your face look 10, 15, even 20 years younger...</strong></p>
                        <p>Because as your neckline becomes firmer, smoother, and more contoured, you'll notice your confidence growing and a new sense of joy emerging...</p>
                        <p>...making social gatherings much more enjoyable, and you'll actually look forward to the next one instead of shying away.</p>
                        <p>And as your self-esteem improves your new found confidence can only improve as well!</p>
                        <button className='btn btn_lg_blue'>See Your Exclusive Face Serum Offer Below!</button>
                    </div>
                </div>
            </div>

            <div className="sk6Upsell_sec3">
                <div className="cont_sec">
                    <div className="sk6Upsell_sec3Wrap">
                        <div className="row">
                            <div className="col-md-7">
                                <img src={upsellImg2} className="d-md-none mb-4 upImg-2" alt="" />
                                <div className="upsellCont">
                                    <p>To put it plainly...</p>
                                    <p><b><i>The area above your neckline, your face, deserves every bit of the same care because...</i></b></p>
                                    <p>...while makeup may be able to conceal some of the fine lines and wrinkles on the face...</p>
                                    <p>The deeper set wrinkles that may be showing on the forehead, and those “11’s” between the eyebrows, may be becoming more pronounced...</p>
                                    <p>...and the wrinkles around the corner of the mouth, the smile lines, may have settled into deeper creases...</p>
                                    <p>And as much as you try, <strong>makeup can only cover up so much...</strong></p>
                                    <p><u>It’s not your fault.</u></p>
                                    <p>Because as women age and go through hormonal changes like menopause, the body produces less collagen leading to more fine lines and deeper set wrinkles...</p>
                                    <p>...which creates that tired and worn-out look in your face making you seem years older than you really are.</p>
                                    <p>But what’s more is, estrogen levels decrease over time, and that can have dramatic consequences to your complexion...</p>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <img src={upsellImg2} className="w-100 d-none d-md-block" alt="" />   
                            </div>
                        </div>
                    </div>        
                </div>
            </div>



            <div className="sk6Upsell_sec4">
                <div className="cont_sec">
                    <div className="row">
                            <div className="col-md-7">
                                <div className="upsellCont">
                                    <p>Let me explain...</p>
                                    <p>Estrogen allocates “healthy fat” throughout the body, and when you were younger your estrogen levels were higher and that meant an even distribution of fat in your face to create a radiant and youthful “fullness”...</p>
                                    <p>...it's like nature's own “filler” that gave you those lovely plump cheeks when you were young.</p>
                                    <p>But as women age and their estrogen levels naturally drop, the “balanced” distribution of fat, which created that vibrant appearance, shifts and settles into the face giving the face a saggy and droopy look...</p>
                                    <p>And<strong> this is when makeup just doesn’t work anymore...</strong></p>
                                    <p>Because as your facial skin loses its elasticity and doesn’t “spring back” like it used to, the skin begins to settle and the sagging becomes more pronounced...</p>
                                    <p>...leaving you with just the memory of the youthful look you had from your 20’s and your 30’s.</p>
                                    <p>And the thought of feeling invisible, not being noticed, or even experiencing loneliness all over again can seem inevitable...</p>
                                    <p>But it doesn’t have to remain that way anymore...</p>
                                    <p>Because when you discover just how transformational this breakthrough can be for your facial skin...</p>
                                    <p>...and how you can have a complexion that looks decades younger, with smoother, vibrant and healthier looking skin...</p>
                                    <p>...then you can have full confidence in your appearance and higher self-esteem.</p>
                                    <p>And remember, there's a difference between facial skin and neck skin...</p>
                                    <p>...not only is facial skin thinner than the neck’s...</p>
                                    <p>But the face also has over 40 “micro-muscles”, responsible for chewing food and expressing different emotions... <strong>creating over 40 individual opportunities for wrinkles to form and skin to sag.</strong></p>                                
                                </div>
                            </div>
                            <div className="col-md-5">
                                <img src={upsellImg3} className="upImg-3" alt="" />   
                                <img src={upsellImg4} className="upImg-4" alt="" />   
                            </div>
                        </div>       
                </div>
            </div>


            <div className="sk6Upsell_sec5">
                <div className="cont_sec">
                    <div className="row">
                            <div className="col-md-7">
                                <div className="upsellCont">    
                                    <p>And that’s why a targeted treatment is needed to address wrinkles, fine lines, and sagging that’s unique to the face...</p>
                                    <p><strong>But here’s the good news...</strong></p>
                                    <p>As your confidence grows from your new beautifully contoured neckline, you can also get a more youthful, rejuvenated and revitalized facial complexion.</p>
                                    <p>And look your very best every single day...</p>
                                    <p>On top of that...</p>
                                    <p><strong className='yellowStrip1'>It's a breakthrough that works in beautiful harmony with Age Brilliance Neck Cream...</strong></p>
                                    <p>Which is why I strongly suggest that women who want to further "turn back the clock" on their facial skin... use it as well.</p>                             
                                    <p><strong>...so what is this breakthrough?</strong></p>   
                                </div>
                            </div>
                            <div className="col-md-5">
                                <img src={upsellImg5} className="upImg-5" alt="" />    
                            </div>
                        </div>       
                </div>
            </div>


            <div className="sk6Upsell_sec6">
                <div className="cont_sec contentCard">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="upsellCont">                                                       
                                <h4 className='text-center sk6_subTitle2'><strong>It’s Called</strong></h4>
                                <h2 class="sk6_title2  text-center">Age Brilliance Anti-Aging Facial Serum</h2>
                                <img src={upsellImg6} className="w-100 upImg-6" alt="" />  
                                <div className='inner_cont'>
                                    <p>Age Brilliance Anti-Aging Facial Serum is an advanced serum that’s proven to rejuvenate, repair, and revitalize your skin.</p>
                                    <p>So you can have that youthful glow with less wrinkles and firmer skin just like you had decades ago...</p>
                                </div> 
                               
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <div className="upsellCont inner_cont">   
                                <p><u>And it's perfect for any skin type and any age...</u></p>
                                <p>Age Brilliance Anti-Aging Facial Serum will smooth-out wrinkles and firm up sagging skin on your face and...</p>
                                <p>...it also deeply moisturizes your facial skin so that each morning as you wake, you’ll begin the day with a “dewy” feeling on your skin and a vibrant, more radiant glow across your face.</p>
                                <p>The reason Age Brilliance Anti-Aging Facial Serum works as well as it does is because of the powerful ingredients that have been joined together to reverse 10, 15, even 20 years of aging for women across the country.</p>                            
                            </div>
                        </div>                    
                        <div className='col-md-5'>
                            <img src={upsellImg7} className="upImg-7" alt="" /> 
                        </div>
                        <div className='col-md-12'>
                            <div className="inner_cont"> 
                                <button class="btn btn_lg_blue">See Your Exclusive Face Serum Offer Below!</button>
                            </div>
                        </div>
                    </div>       
                </div>
            </div>


            <div className="sk6Upsell_sec7">
                <div className="cont_sec upsellCont">
                    <h2 class="sk6_title3 text-center">
                        How Does Age Brilliance Anti-Aging Serum <br/>
                        <span className='txt_pink'>Reduce Facial Wrinkles & Firm Up Sagging Skin?</span>
                    </h2>
                    <p>Because I wanted the perfect compliment for Age Brilliance Neck Cream, I knew that we’d have to source the most powerful anti-aging ingredients available, so that’s exactly what we did...</p>
                    <p>The secret behind Age Brilliance Anti-Aging Serum begins with the youth-enhancing foundation of these three exclusive ingredients that work together to firm-up sagging areas, remove wrinkles and fine lines, and brighten the skin...</p>
                    <p>Starting off with resveratrol.</p>

                    <div className="contentCard2">  
                        <div className="row">
                            <div className="col-md-7">
                                <h3 className='sk6_title4 txt_pink'>Resveratrol</h3>                           
                                <p>Resveratrol is a naturally occurring compound found in plants like red grapes, blueberries and raspberries with remarkable anti-aging benefits.</p>
                                <p>For starters, recent studies have labeled it as a <strong className='yellowStrip1'>“prevention of skin aging”</strong> ... because it's a powerful antioxidant that shields the skin from harmful elements like UV rays, smoke, alcohol and other pollutants that speed up aging. Resveratrol puts a stop to them before they cause more damage.</p>                       
                                <p><strong className='yellowStrip1'>Plus, it's a “skin brightening” nutrient that helps remove dark spots from the skin.</strong></p>
                            </div>
                            <div className="col-md-5">
                                <img src={upsellImg8} className="" alt="Resveratrol" />   
                            </div>
                        </div>                 
                    </div>

                    <p>And then we wanted to reduce wrinkles and fine lines, that’s why we also included...</p>

                    <div className="contentCard2">  
                        <div className="row">
                            <div className="col-md-7">
                                <h3 className='sk6_title4 txt_pink'>Hyaluronic Acid</h3>                           
                                <p>Hyaluronic Acid is a moisture magnet molecule that keeps the skin ultra-hydrated. It’s proven to <strong className='yellowStrip1'>“increase the skin's elasticity, smooth-out fine lines and wrinkles”, while also providing a “plumping” effect to the skin.</strong></p>
                                <p>In other words, it's like nature's magic sponge for your skin, soaking up moisture to give you a fresh, plump, and youthful glow.</p>  
                            </div>
                            <div className="col-md-5">
                                <img src={upsellImg9} className="" alt="Hyaluronic Acid" />   
                            </div>
                        </div>                 
                    </div>

                    <p>And since womens collagen levels naturally decrease with age we chose this next ingredient…</p>

                    <div className="contentCard2">  
                        <div className="row">
                            <div className="col-md-7">
                                <h3 className='sk6_title4 txt_pink'>Niacinamide</h3>    
                                <p>Niacinamide, also known as Vitamin B3 is a multi-tasking, anti-aging, and skin firming nutrient.</p>                       
                                <p>It’s a builder of keratin, a special protein, that <strong className='yellowStrip1'>keeps your skin firm and healthy</strong> by supporting the collagen structures to give your complexion a more youthful look.</p>
                                <p>And when it comes to achieving that “radiant glow”, it's the Empress of ingredients that helps to preserve hydration, reduce the look of redness, and visibly fade dark spots.</p>  
                            </div>
                            <div className="col-md-5">
                                <img src={upsellImg10} className="" alt="Niacinamide" />   
                            </div>
                        </div>                 
                    </div>


                    <div className='row'>
                        <div className='col-md-8'>
                            <p>And then we topped off the formulation with these final two primary ingredients to create the only one-of-a-kind, wrinkle removing and skin firming facial serum...</p>
                            <p>First is Hibiscus Sabdariffa Flower Extract which <strong className='yellowStrip1'>increases the skin's elasticity and is often called the 'Botox plant'</strong>, giving your skin a natural lift! It keeps the skin springy and youthful by protecting the skin's natural stretchiness.</p>
                            <p>And finally there’s Gigawhite , which could have easily been included in my “top 3” ingredients.</p>
                            <p>Gigawhite is a superstar ingredient for “mature” skin. Derived from alpine plants, <strong className='yellowStrip1'>it not only brightens and evens out your skin tone, but it also diminishes the look of age spots and redness.</strong> And the best part? It lavishly pampers your skin with a gentle touch, to reveal a new revitalized glow.</p>
                        </div>
                        <div className='col-md-4'>
                            <img src={upsellImg11} className="mx-auto" alt="Hibiscus Sabdariffa" />   
                        </div>
                    </div>

                </div>
            </div>


            <div className="sk6Upsell_sec8">
                <div className="cont_sec contentCard upsellCont">
                    <h2 className='sk6_title3 text-center'>
                        Use Age Brilliance Anti-Aging Serum On Your Face Daily <br />
                        <span className='txt_pink'>To Erase Wrinkles & Firm-Up Your Skin...</span>
                    </h2>
                    <p>The more you use Age Brilliance Anti-Aging Serum...</p>
                    <div className="row imageCard_row mb-3 mb-md-5">
                        <div className="col-md-4 imageCard_col">
                            <div className="imageCard1">                               
                                <div className="imageCard1_fig">                            
                                    <img src={upsellImg12} className="" alt="" />   
                                </div>
                                <div className="imageCard1_cont text-center">                            
                                    <p>..the less wrinkles <br /> you’ll have...</p>
                                </div>                                
                            </div>
                        </div>
                        <div className="col-md-4 imageCard_col">
                            <div className="imageCard1">                               
                                <div className="imageCard1_fig">                            
                                    <img src={upsellImg13} className="" alt="" />   
                                </div>
                                <div className="imageCard1_cont text-center">                            
                                    <p>...the firmer your facial <br /> you’ll have...</p>
                                </div>                                
                            </div>
                        </div>
                        <div className="col-md-4 imageCard_col">
                            <div className="imageCard1">                               
                                <div className="imageCard1_fig">                            
                                    <img src={upsellImg14} className="" alt="" />   
                                </div>
                                <div className="imageCard1_cont text-center">                            
                                    <p>...and the better you’ll feel <br /> you’ll have...</p>
                                </div>                                
                            </div>
                        </div>                        
                    </div>    
                    <p>Simply apply 2 drops of the serum and gently massage it into your face in the morning and in the evening...</p>
                    <p>... and then you'll have firmer, smoother, and healthier looking skin for years to come.</p>
                    <p>Now because I want to make it even easier for you to get your hands on this incredible anti-aging facial serum...</p>
                    <p>And because I’m so confident it will safely and effectively help you “rewind the clock” on your face...</p>
                    <p>I’m going to give you an exclusive discount on Age Brilliance Anti-Aging Facial Serum...</p>
                    <p>And let you get it for up to 61% off of what other women gladly pay.</p>
                </div>
            </div>


            <div className="sk6Upsell_sec9">
                <div className="cont_sec">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="upsellCont">                               
                                <p>Normally, Age Brilliance Anti-Aging Serum retails for $89 for a one month’s supply...</p>
                                <p>Which is still a fair price when you consider that it contains 3 proprietary and proven anti-aging ingredients...</p>
                                <p>...that are safe and effective for all skin types.</p>
                                <p>However, because you’re a new Age Brilliance customer...</p>
                                <p>And because we’re committed to guaranteeing your incredible “age-defying” results...</p>
                                <p>When you add Age Brilliance Anti-Aging Facial Serum to your order right now...</p>
                                <p><strong className='yellowStrip1'>You’ll save up to 71% off what everyone else pays for Age Brilliance Anti-Aging Facial Serum.</strong></p>
                                <p>However, this incredible savings can only be offered to you today...</p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img src={upsellImg15} className="" alt="" />   
                        </div>
                    </div>  
                    <div className="upsellCont">
                        <p>And only on this page, so please don’t hesitate and try for yourself how Age Brilliance Anti-Aging Facial Serum can remove 10, 15, even 20 years of aging from your face.</p>
                        <p>Because once you leave, you’ll never be shown this deal again. And if you want to try Age Brilliance Anti-Aging Facial Serum at a later time...</p>
                        <p>You’ll have to pay the full retail price for a single bottle.</p>
                    </div>    
                </div>
            </div>

            <div className="sk6Upsell_sec10">
                <div className="cont_sec">
                    <div className="row proCard_row">
                        <div className="col-md-4 proCard_col">
                            <div className="proCard">                               
                                <div className="proCard_header">                               
                                    <h2 className='proCard_header__title'>SAMPLER PACKAGE</h2>
                                    <hr className='horizontal_line' />
                                    <h3 className='proCard_header__subtitle'>1 MONTH SUPPLY</h3>
                                </div>
                                <div className="proCard_body">    
                                    <div className="proCard_pricePer">                               
                                        <p className='proCard_price'>$47</p>
                                        <p className='per'>PER BOTTLE</p>
                                    </div>                           
                                    <div className="save_price">                               
                                        SAVE <br /> <span className='saved'>$50.00</span>			  
                                    </div> 
                                    <div className='product_img'>
                                        <img className='bottle_img' src={upsellProduct1} />
                                    </div>
                                    <div className='product_prices'>
                                        <p className='reg_price'>REGULARLY <span className='pr'>$89.00</span></p>
                                        <p className='actual_price'>TOTAL: <span className='ac_pr'>$47.00</span></p>
                                    </div>
                                    <div className='product_deal'>
                                        <div className='img_box'>
                                            <img src={truck} className='' />
                                            <span className='deal_txt'>FREE SHIPPING</span>
                                        </div>
                                        <div className='img_box'>
                                            <img src={badge} className='' />
                                            <span className='deal_txt'>180 DAY MONEY BACK GUARANTEE</span>
                                        </div>  
                                    </div>
                                    <button className='btn btn-primary btn-one-style btn-icon cart_btn'>Add to Cart</button>
                                    <img src={cardImg} className='card_img mx-auto d-block' alt='card_img' />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 proCard_col">
                            <div className="proCard">                               
                                <div className="proCard_header">                               
                                    <h2 className='proCard_header__title'>SAMPLER PACKAGE</h2>
                                    <hr className='horizontal_line' />
                                    <h3 className='proCard_header__subtitle'>1 MONTH SUPPLY</h3>
                                </div>
                                <div className="proCard_body">    
                                    <div className="proCard_pricePer">                               
                                        <p className='proCard_price'>$47</p>
                                        <p className='per'>PER BOTTLE</p>
                                    </div>                           
                                    <div className="save_price">                               
                                        SAVE <br /> <span className='saved'>$50.00</span>			  
                                    </div> 
                                    <div className='product_img'>
                                        <img className='bottle_img' src={upsellProduct2} />
                                    </div>
                                    <div className='product_prices'>
                                        <p className='reg_price'>REGULARLY <span className='pr'>$89.00</span></p>
                                        <p className='actual_price'>TOTAL: <span className='ac_pr'>$47.00</span></p>
                                    </div>
                                    <div className='product_deal'>
                                        <div className='img_box'>
                                            <img src={truck} className='' />
                                            <span className='deal_txt'>FREE SHIPPING</span>
                                        </div>
                                        <div className='img_box'>
                                            <img src={badge} className='' />
                                            <span className='deal_txt'>180 DAY MONEY BACK GUARANTEE</span>
                                        </div>  
                                    </div>
                                    <button className='btn btn-primary btn-one-style btn-icon cart_btn'>Add to Cart</button>
                                    <img src={cardImg} className='card_img mx-auto d-block' alt='card_img' />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 proCard_col">
                            <div className="proCard">                               
                                <div className="proCard_header">                               
                                    <h2 className='proCard_header__title'>SAMPLER PACKAGE</h2>
                                    <hr className='horizontal_line' />
                                    <h3 className='proCard_header__subtitle'>1 MONTH SUPPLY</h3>
                                </div>
                                <div className="proCard_body">    
                                    <div className="proCard_pricePer">                               
                                        <p className='proCard_price'>$47</p>
                                        <p className='per'>PER BOTTLE</p>
                                    </div>                           
                                    <div className="save_price">                               
                                        SAVE <br /> <span className='saved'>$50.00</span>			  
                                    </div> 
                                    <div className='product_img'>
                                        <img className='bottle_img' src={upsellProduct3} />
                                    </div>
                                    <div className='product_prices'>
                                        <p className='reg_price'>REGULARLY <span className='pr'>$89.00</span></p>
                                        <p className='actual_price'>TOTAL: <span className='ac_pr'>$47.00</span></p>
                                    </div>
                                    <div className='product_deal'>
                                        <div className='img_box'>
                                            <img src={truck} className='' />
                                            <span className='deal_txt'>FREE SHIPPING</span>
                                        </div>
                                        <div className='img_box'>
                                            <img src={badge} className='' />
                                            <span className='deal_txt'>180 DAY MONEY BACK GUARANTEE</span>
                                        </div>  
                                    </div>
                                    <button className='btn btn-primary btn-one-style btn-icon cart_btn'>Add to Cart</button>
                                    <img src={cardImg} className='card_img mx-auto d-block' alt='card_img' />
                                </div>
                            </div>
                        </div>
                    </div>    
                    <p className='text-center upsellSkip'><a href=''><strong>No thank you. I’ll pass on this huge savings now and realize I will never have this opportunity again.</strong></a></p>   
                </div>
            </div>

            <div className="sk6Upsell_sec11">
                <div className="cont_sec contentCard upsellCont">
                    <div className="mobileImg d-md-none">
                        <img src={gurantee_seal} className="gurantee_mob_img mx-auto" alt="" />  
                    </div>  
                    <h2 className='sk6_title3 text-center'>100% 180-Day Money Back Guarantee</h2>
                    <img src={line} alt="line-img"  className="mx-auto d-block mb-3 mb-md-4" />
                  
                    <div className="row">
                        <div className="col-md-8">
                            <p>If I’m not absolutely thrilled by the way my face looks and feels...</p>
                            <p>Or if I’m unimpressed by the comments others will make about my “younger looking skin” because...</p>
                            <p>...my wrinkles have smoothened-out</p>
                            <p>...my skin has become firmer...</p>
                            <p>...my facial skin is rejuvenated and has a healthy glow...</p>                           
                        </div>
                        <div className="col-md-4">
                            <img src={gurantee_seal} className="d-none d-md-block" alt="" />  
                        </div>                      
                    </div>  
                    <p>Then I pay NOTHING.</p>
                    <p>My decision to try Age Brilliance Anti-Aging Facial Serum is completely risk free.</p>
                    <p>And if I change my mind for any reason whatsoever...</p>
                    <p>...even if I see amazing results with every month that goes by...</p>
                    <p>I can just call or email customer service and get a full refund for any reason I have.</p>  
                    <p>There’s no strings attached and no catches.</p>
                    <p>You don’t have to explain why you want the refund.</p>  
                    <p>Either you notice your wrinkles fade away and your skin firm up – or you don’t pay a penny.</p>
                    <p><strong>No hassles. No risks. Just results.</strong></p>
                </div>
            </div>
            
            
        </main>

        {/* <footer className="footerDark">
            <div className="footer-container">
                <ul className="footer-links">
                    <li><a href="/skincare2p">Terms And Conditions</a></li>
                    <li><a href="/skincare2p">Privacy Policy</a></li>
                    <li><a href="/skincare2p">Citations</a></li>
                    <li><a href="/contact-us"> Contact Us </a></li>
                </ul>
                <p className="copy-right-txt">Copyright © 2024, Wholesale Health Club, PO BOX 90129 Lakeland FL 33804<br/>Billing
                    descriptor: <a href="https://wholesalehealthclub.com/">wholesalehealthclub.com</a><br/>Customer Service
                    Phone: <a href="tel:(480)573-0829">(480)573-0829</a><br/>Customer Service Email: <a
                        href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a></p>
            </div>
        </footer> */}


</>
    )
}


export default Skincare6pUpsell1;