import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import { Link, useNavigate, useLocation } from "react-router-dom"; // v6
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Formik, Field, Form, useField } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../../components/frontend/CustomErrorMsg";
import moment from "moment";
import useStore from '../../store';

import Product1 from '../../assests/admin/assets/img/payment_multi_btls_5.png';
import IconStisfaction from '../../assests/admin/assets/img/icon-satisfaction.png';
import IconDelivery from '../../assests/admin/assets/img/icon-delivery.png';
import PostalLogo from '../../assests/admin/assets/img/postal-logos.png';
import SecurityBadge1 from '../../assests/admin/assets/img/security-badge-1.png';
import SecurityBadge2 from '../../assests/admin/assets/img/security-badge-2.png';
import SecurityBadge3 from '../../assests/admin/assets/img/security-badge-3.png';
import SecurityBadge4 from '../../assests/admin/assets/img/security-badge-4.png';
import FormTop from '../../assests/admin/assets/img/payment_form_top_bg.png';
import PaymentCards from '../../assests/admin/assets/img/payment_multi_cards.png';
import PaymentButton from '../../assests/admin/assets/img/payment_button.png';
import paymentMultiSecure from '../../assests/admin/assets/img/payment_multi_secure.jpg';
import Guaranteed from '../../assests/admin/assets/img/guaranteed.png';
import Arrow2 from '../../assests/admin/assets/img/arrow2.png';
import privacyVerified from '../../assests/admin/assets/img/privacy-verified.jpg';
import CertifiedBy from '../../assests/admin/assets/img/certified-by.jpg';
import SecurityVerified from '../../assests/admin/assets/img/security-verified.jpg';
import BusinessVerified from '../../assests/admin/assets/img/business-verified.jpg';
import Visa from '../../assests/admin/assets/img/visa-mc-discover-new.png';
import PostalLogo2 from '../../assests/admin/assets/img/postal-logos_ZKYhlZD.png';
import OnlineLock from '../../assests/admin/assets/img/shop-online-lock.png';
import { useParams } from 'react-router-dom';
import siteSecurity from '../../assests/admin/assets/img/site-security-img.png';
import {BASE_URL, GetCardType, stateList} from '../Config'
import Copyright from '../Copyright';
import { useRef } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PaypalImage from '../../assests/frontend/assets/images/paypal-01.png';
import paypal from '../../assests/frontend/assets/images/paypal.png';
import paypalBtn from '../../assests/frontend/assets/images/paypal-btn.png';


function MasterTemplate1(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [checkoutFunnelData, setCheckoutFunnelData] = useState({})
    const [SMToggle, setSMToggle] = useState(false)
    const [SMAddonToggle, setSMAddonToggle] = useState(true)
    const [selectedProduct, setSelectedProduct] = useState(1)

    const [eachFirstProductPrice, setEachFirstProductPrice] = useState(0);
    const [eachSecondProductPrice, setEachSecondProductPrice] = useState(0);
    const [eachThirdProductPrice, setEachThirdProductPrice] = useState(0);
    const [price, setPrice] = useState(0)
    const [retail, setReatil] = useState(0)
    const [save, setSave] = useState(0)
    const [total, setTotal] = useState(0)
    const [shipping, setShipping] = useState(0)
    const [sapphirePrice, setSapphirePrice] = useState(0.00);
    const [pID, setPID] = useState(0)
    const [FPDiv, setFPDiv] = useState(true)
    const [SPDiv, setSFPDiv] = useState(false)
    const [TPDiv, setTPDiv] = useState(false)
    const [loadershow, setloadershow] = useState(false)
    const [shippingDiv, setShippingDiv] = useState(false);
    const afterLogin = useStore((state) => state.afterLogin);
    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => currentYear + index);
    const [showDiv, setShowDiv] = useState(false);
    const [checkboxValue, setcheckboxValue] = useState(true);
    let imgURL = BASE_URL+"whc-custom/storage/app/public/";
    let localURL = window.location.origin.includes('localhost') ? 'http://localhost:3000/' : window.location.origin+'/';
    let affid = new URLSearchParams(location.search).get('affid');
    let sid = new URLSearchParams(location.search).get('sid');
    let s1 = new URLSearchParams(location.search).get('s1');
    let s2 = new URLSearchParams(location.search).get('s2');
    let s3 = new URLSearchParams(location.search).get('s3');
    let click_id = new URLSearchParams(location.search).get('click_id');
    let notes = new URLSearchParams(location.search).get('notes');
    let params = location.search;
    const [cardType, setCardType] = useState("");
    const [cc_number, setcc_number] = useState("");
    let URL = window.location.href;
    let url_slug = URL.substring(URL.lastIndexOf('/') + 0);
    let indexOfQuestionMark = url_slug.indexOf('?');
    let urlSlug;
    if (indexOfQuestionMark !== -1) {
        urlSlug = url_slug.substring(0, indexOfQuestionMark);
    } else {
        urlSlug = url_slug;
    }

    const [states, setStates] = useState([]);
    const params2 = useParams();
    
    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    const handleCountryChange2 = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    useEffect(()=>{
        if((urlSlug === '/brain4-checkout')) {
            setSMAddonToggle(false);
        }
        handleCountryChange2('239');
    },[params2.slug]);

    const handleCheckboxChange = (event) => {
        setcheckboxValue(event.target.checked);
        setShowDiv(!event.target.checked);
    };
    
    // State List 
    const options = stateList ;

    function getDateOneWeekFromNow() {
        // Create a new Date object for the current date
        const date = new Date();

        // Add 7 days to the date in milliseconds (1 week)
        date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

        // Get the month, day, and year from the date
        const month = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();

        // Convert the month number to the month's name
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthName = months[month];

        // Return the date in the format you specified
        return `${monthName} ${day}, ${year}`;
    }

    const dateOneWeekFromNow = getDateOneWeekFromNow();
   

    function setCreditCardType(CCNumber){
        if(CCNumber.length>=15){
            //console.log(GetCardType(CCNumber));
            setcc_number(CCNumber);
            setCardType(GetCardType(CCNumber));
            document.getElementById('card_type').value = GetCardType(CCNumber);
        }
    }

    function setIntialData() {
        setCheckoutFunnelData(props.data);
        setPrice(props.data[0].first_product_price);
        setReatil(props.data[0].first_product_retail);
        setSave(props.data[0].first_product_save);
        setShipping(0);
        setTotal(parseFloat((props.data[0].first_product_price)) + parseFloat(shipping));
        setPID((props.data[0].first_product_id));
        setSapphirePrice(0.00);
        localStorage.setItem('ProductId',props.data[0].first_product_id);
        setEachFirstProductPrice(props.data[0].first_product_each);
    }

    useEffect(() => { setIntialData() }, []);

    function setOrderSummery(productPrice, productId, productRetailPrice, productPriceSave, productShippingId, ProductDiv, eachPrice) {
        if (ProductDiv == 1) {
            setSelectedProduct(1);
            setFPDiv(true);
            setSFPDiv(false);
            setTPDiv(false);
            setShippingDiv(false);
            localStorage.setItem('ProductId',productId);

            setPrice(productPrice);
            setReatil(productRetailPrice);
            setSave(productPriceSave);
            setShipping(0);
            setTotal(parseFloat(productPrice)+parseFloat(0.00));
            setPID(productId);

            /* this section is for brain4 UI change for display sapphire membership discount */
            if(!SMAddonToggle){
                setEachFirstProductPrice(eachPrice);
            } else {
                if(urlSlug === '/brain4-checkout') {
                    setEachFirstProductPrice(parseFloat(26.40).toFixed(2));
                }
            }
            
        }
        if (ProductDiv == 2) {
            setSelectedProduct(2);
            setFPDiv(false);
            setSFPDiv(true);
            setTPDiv(false);
            setShippingDiv(false);
            localStorage.setItem('ProductId',productId);

            setPrice(productPrice);
            setReatil(productRetailPrice);
            setSave(productPriceSave);
            setShipping(0);
            setTotal(parseFloat(productPrice)+parseFloat(0.00));
            setPID(productId);

            /* this section is for brain4 UI change for display sapphire membership discount */
            if(!SMAddonToggle){
                setEachSecondProductPrice(eachPrice);
            } else {
                if(urlSlug === '/brain4-checkout') {
                    setEachSecondProductPrice(parseFloat(27.33));
                }
            }
        }
        if (ProductDiv == 3) {
            setSelectedProduct(3);
            setFPDiv(false);
            setSFPDiv(false);
            setTPDiv(true);

            if(!SMAddonToggle){
                setPrice(productPrice);
                setSave(productPriceSave);
                setShipping(9.95);
                setTotal(parseFloat(productPrice)+parseFloat(9.95));
                setReatil(parseFloat(productPrice)+parseFloat(9.95));
                setPID(productId);
                setShippingDiv(true);
                if(urlSlug === '/checkout-7'){
                    localStorage.setItem('ProductId',78);
                } else if((urlSlug === '/brain4-checkout') || (urlSlug === '/checkout-b')) {
                    localStorage.setItem('ProductId',148);
                } else if(urlSlug === '/brain10p-checkout') {
                    localStorage.setItem('ProductId',163);
                }
                setEachThirdProductPrice(eachPrice);
                
            } else {
                setPrice(productPrice);
                setSave(productPriceSave);
                setShipping(0);
                setTotal(parseFloat(productPrice)+parseFloat(0.00));
                setReatil(parseFloat(productPrice)+parseFloat(0.00));
                setPID(productId);
                setShippingDiv(false);
                localStorage.setItem('ProductId',productId);
                if(urlSlug === '/brain4-checkout') {
                    setEachThirdProductPrice(parseFloat(52).toFixed(2));
                }
            }
        }
    }

    function camelCase(str) {
        return str.split(' ').map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(' ');
    }

    function handleSPAddon(selectedProduct) {
        setSMAddonToggle(!SMAddonToggle);
        if (selectedProduct == 3) {
            if (!SMAddonToggle) {
                setShipping(0);
                setShippingDiv(false);
                setTotal(parseFloat(props.data[0].third_product_price)+parseFloat(0.00));
                setReatil(parseFloat(props.data[0].third_product_price)+parseFloat(0.00));
                localStorage.setItem('ProductId',checkoutFunnelData[0].third_product_id);
                if(urlSlug === '/brain4-checkout') {
                    setEachThirdProductPrice(parseFloat(52).toFixed(2));
                    if (!SMAddonToggle) {
                        setSapphirePrice(17.00);
                    } else {
                        setSapphirePrice(0.00);
                    }
                }
            } else {
                setShipping(9.95);
                setShippingDiv(true);
                setTotal(parseFloat(props.data[0].third_product_price)+parseFloat(9.95));
                setReatil(parseFloat(props.data[0].third_product_price)+parseFloat(9.95));
                if(urlSlug === '/checkout-7'){
                    localStorage.setItem('ProductId',78);
                } else if((urlSlug === '/brain4-checkout') || (urlSlug === '/checkout-b')) {
                    localStorage.setItem('ProductId',148);
                } else if(urlSlug === '/brain10p-checkout') {
                    localStorage.setItem('ProductId',163);
                }
                if(urlSlug === '/brain4-checkout') {
                    setEachThirdProductPrice(props.data[0].third_product_each);
                    if (!SMAddonToggle) {
                        setSapphirePrice(17.00);
                    } else {
                        setSapphirePrice(0.00);
                    }
                }
            }
        } else if(selectedProduct == 1) {
            /* this section is for brain4 UI change for display sapphire membership discount */
            if(!SMAddonToggle){
                if((urlSlug === '/brain4-checkout')) {
                    setEachFirstProductPrice(parseFloat(26.40).toFixed(2));
                    setSapphirePrice(17.00);
                }
            } else {
                setEachFirstProductPrice(props.data[0].first_product_each);
                setSapphirePrice(0.00);
            }
        } else if(selectedProduct == 2) {
            /* this section is for brain4 UI change for display sapphire membership discount */
            if(!SMAddonToggle){
                if(urlSlug === '/brain4-checkout') {
                    setEachSecondProductPrice(parseFloat(27.33));
                    setSapphirePrice(17.00);
                    
                }
            } else {
                setEachSecondProductPrice(props.data[0].second_product_each);
                setSapphirePrice(0.00);
            }
        } /*else {
            if(urlSlug === '/brain10p-checkout') {
                if (!SMAddonToggle) {
                    setSapphirePrice(17.00);
                } else {
                    setSapphirePrice(0.00);
                }
            }
        }*/
    }

    //PayPalSubmit Handler
    function PayPalHandler(){
        if(localStorage.getItem('firstName')){
            const formdata = new FormData();
            let product_id = localStorage.getItem('ProductId');
            let is_membership = '';
            if (checkoutFunnelData[0].membership_add_on == 1) {
                if (SMAddonToggle) {
                    is_membership = 41;
                    localStorage.setItem("is_membership",41);
                    if(urlSlug === '/brain10p-checkout'){
                        localStorage.setItem('combine_product', 1);
                        localStorage.setItem('combine_product_id', 177);
                    }
                }
                else {
                    is_membership = 0;
                    localStorage.setItem("is_membership",0);
                }
            }
            else {
                is_membership = 0;
            }

            let firstName = localStorage.getItem('firstName');
            let lastName = localStorage.getItem('lastName');
            let address = localStorage.getItem('shippingAddress1');
            let zip = localStorage.getItem('shippingZip');
            let state = localStorage.getItem('shippingState');
            let city = localStorage.getItem('shippingCity');
            let phone = localStorage.getItem('phone');
            let email = localStorage.getItem('email');
            let country = localStorage.getItem('country');
            let alt_pay_url = localURL+localStorage.getItem('up1_s');
            let prospectId = (localStorage.getItem('prospectId') ? localStorage.getItem('prospectId') : '');
            let combine_product = localStorage.getItem('combine_product');
            let combine_product_id = localStorage.getItem('combine_product_id');
            let funnel = 'brain10p';

            /* affiliate params*/
            let affid = new URLSearchParams(location.search).get('affid');
            let sid = new URLSearchParams(location.search).get('sid');
            let s1 = new URLSearchParams(location.search).get('s1');
            let s2 = new URLSearchParams(location.search).get('s2');
            let s3 = new URLSearchParams(location.search).get('s3');
            let click_id = new URLSearchParams(location.search).get('click_id');
            let notes = new URLSearchParams(location.search).get('notes');

            localStorage.setItem('affid',affid);
            localStorage.setItem('sid',sid);
            localStorage.setItem('s1',s1);
            localStorage.setItem('s2',s2);
            localStorage.setItem('s3',s3);
            localStorage.setItem('click_id',click_id);
            localStorage.setItem('notes',notes);
            localStorage.setItem('funnel',funnel);


            /*window.location.href=BASE_URL+'paypal?product_id='+product_id+'&is_membership='+is_membership+'&firstName='+firstName+'&lastName='+lastName+'&address='+address+'&zip='+zip+'&state='+state+'&city='+city+'&phone='+phone+'&email='+email+'&country='+country+'&alt_pay_url='+alt_pay_url;*/
            window.location.href=BASE_URL+
            'whc-custom/public/api/paypal?product_id='+product_id+
            '&is_membership='+is_membership+
            '&firstName='+firstName+
            '&lastName='+lastName+
            '&address='+address+
            '&zip='+zip+
            '&state='+state+
            '&city='+city+
            '&phone='+phone+
            '&email='+email+
            '&country='+country+
            '&alt_pay_url='+alt_pay_url+
            '&affid='+affid+
            '&sid='+sid+
            '&s1='+s1+
            '&s2='+s2+
            '&s3='+s3+
            '&click_id='+click_id+
            '&notes='+notes+
            '&prospectId='+prospectId+
            '&combine_product='+combine_product+
            '&combine_product_id='+combine_product_id+
            '&funnel='+funnel;
        } else {
            Swal.fire({
                title: "Please create prospect",
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
                cancelButtonText: "Cancel",
                confirmButtonColor: '#7066e0',
                cancelButtonColor: '#18aebf',
                showCloseButton: true,
    
            }).then((result) => {
                if (result.isConfirmed) {
                    if(urlSlug === '/checkout-7'){
                        window.location.href=BASE_URL+'brain3';
                    } else if((urlSlug === '/brain4-checkout') || (urlSlug === '/checkout-b')) {
                        window.location.href=BASE_URL+'brain4';
                    } else if(urlSlug === '/brain10p-checkout') {
                        window.location.href=BASE_URL+'brain10p';
                    }
                }
            })
        }
        
    }

    const confirmSubmit = (data) => {
        setloadershow(true);
        let expirationDate = data.cc_month+data.cc_year.slice(-2);
        localStorage.setItem('neobodPId',665);
        localStorage.setItem('neobodCid',668);
        localStorage.setItem('method','normal');
        const formdata = new FormData();
        formdata.append('product_id', localStorage.getItem('ProductId'));
        if (checkoutFunnelData[0].membership_add_on == 1) {
            if (SMAddonToggle) {
                formdata.append('is_membership', 41);
                if(urlSlug === '/brain10p-checkout'){
                    formdata.append('combine_product', 1);
                    formdata.append('combine_product_id', 177);
                }
                
            }
            else {
                formdata.append('is_membership', 0);
            }
        }
        else {
            formdata.append('is_membership', 0);
        }

        formdata.append('first_name', localStorage.getItem('firstName'));
        formdata.append('last_name', localStorage.getItem('lastName'));
        formdata.append('address', localStorage.getItem('shippingAddress1'));
        formdata.append('zip', localStorage.getItem('shippingZip'));
        formdata.append('state', localStorage.getItem('shippingState'));
        formdata.append('city', localStorage.getItem('shippingCity'));
        formdata.append('phone', localStorage.getItem('phone'));
        formdata.append('email', localStorage.getItem('email'));
        formdata.append('country', localStorage.getItem('country'));

        formdata.append('creditCardNumber', data.ccnumber);
        formdata.append('expirationDate', expirationDate);
        formdata.append('cvv', data.cvv);
        formdata.append('creditCardType', data.card_type);
        formdata.append('affid', data.affid);
        formdata.append('sid', data.sid);
        formdata.append('s1', data.s1);
        formdata.append('s2', data.s2);
        formdata.append('s3', data.s3);
        formdata.append('click_id', data.click_id);
        formdata.append('notes', data.notes);

        formdata.append('billingSameAsShipping', data.billingSameAsShipping);
        formdata.append('shipping_address', data.shipping_address);
        formdata.append('shipping_city', data.shipping_city);
        formdata.append('shipping_state', data.shipping_state);
        formdata.append('shipping_zip', data.shipping_zip);
        formdata.append('shipping_country', data.shipping_country);

        if(localStorage.getItem('prospectId')){
            formdata.append('prospectId', localStorage.getItem('prospectId'));
        }
        formdata.append('method',localStorage.getItem('method'));
        if(urlSlug === '/brain10p-checkout'){
            formdata.append('funnel', 'brain10p');
        } else if(urlSlug === '/brain4-checkout') {
            formdata.append('funnel', 'brain4');
        } else if(urlSlug === '/checkout-b') {
            formdata.append('funnel', 'nootropic');
        }

        localStorage.setItem('total', total);
        localStorage.setItem('affid', data.affid);
        localStorage.setItem('sid', data.sid);
        localStorage.setItem('s1', data.s1);
        localStorage.setItem('s2', data.s2);
        localStorage.setItem('s3', data.s3);
        localStorage.setItem('click_id', data.click_id);
        localStorage.setItem('notes', data.notes);

        //console.log(Object.fromEntries(formdata));
        axios.post(`api/place-funnel-order-with-prospect`, formdata).then(res => {
            //console.log(res);
            if (res.data.status === true) {
                //console.log(res.data.data);
                sessionStorage.setItem('yp', res.data.data.user_info.yp);
                localStorage.setItem('orderId', res.data.data.order_id);
                localStorage.setItem('membership_orderId', res.data.membership ? res.data.membership.order_id : false);
                localStorage.setItem('authToken', res.data.data.member_token);
                localStorage.setItem('permission', res.data.data.user_info.account_permission);
                localStorage.setItem('userId', res.data.data.user_info.user_id);
                localStorage.setItem('userEmal', res.data.data.user_info.email);


                axios.get(`api/check_user_membership/${localStorage.getItem('email')}`).then(result => {
                    let is_membership = 0;
                    if(parseInt(result.data) === 1){
                        is_membership = 1;
                    } else {
                        is_membership = 0;
                    }
                    afterLogin(res.data.data.user_info.account_permission, res.data.data.user_info.user_type,res.data.data.user_info.user_id,res.data.data.user_info,is_membership);
                    if (localStorage.getItem('up1_s')) {
                        window.location.href = '/' + localStorage.getItem('up1_s')+params;
                    }
                    else {
                        window.location.href = '/' + localStorage.getItem('con_s')+params;
                    }
                });
            }
            else {
                setloadershow(false);
                Swal.fire({
                    title: res.data.message,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33'
                })
            }
        })
    }

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const formRef = useRef(null);
    const handleRefClick = () => {

        isMobile && formRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            {urlSlug == '/brain10p-checkout' ?
            <iframe style={{display: 'none' }} src="https://www.sjdm2odks.com/?nid=1917&aid=1&adv_event_id=13" scrolling="no" frameborder="0" width="1" height="1"></iframe>
            :''}
            {Object.values(checkoutFunnelData).length !== 0 ? <><div className={loadershow ? 'loading' : 'd-none'}></div><div className={loadershow ? 'd-none' : 'card funnel_card'}>
                <div className="site-secure">
                    <img src={siteSecurity} alt="site security" width="112" />
                </div>
                <div className='checkoutPageWrapper templateMaster-1'>
                    <div className='checkout-container container '>
                        <div className='innerLogo text-center p-3 p-md-5'>
                            <h1 className='text-capitalize' style={{ color: 'white' }}>{checkoutFunnelData[0].funnel_name}</h1>
                        </div>
                        <div className='orderWrapper'>
                            {/* Left Section  */}
                            <div className='orderWrapper-left'>
                                <div className='newCheckout-wrapper'>

                                    <div className={""}>

                                        {/* Package 1  selected_pack */}

                                        <div className={FPDiv ? 'package1 plan-selection selected_pack' : 'package1 plan-selection'} onClick={() => { setOrderSummery(checkoutFunnelData[0].first_product_price, checkoutFunnelData[0].first_product_id, checkoutFunnelData[0].first_product_retail, checkoutFunnelData[0].first_product_save, checkoutFunnelData[0].first_product_shipping_id, 1, checkoutFunnelData[0].first_product_each);handleRefClick() }}>
                                            <div className='payProd'>
                                                <div className='payProd_header'>
                                                    <p className='payProd_title'>
                                                        {checkoutFunnelData[0].first_product_label}
                                                    </p>

                                                    <span className='shipping_msg'>{checkoutFunnelData[0].first_product_shipping_id}</span>
                                                </div>
                                                <div className='payProd_body'>
                                                    <span className='radio'></span>
                                                    <div className='product_figure'>
                                                        <img src={BASE_URL+"whc-custom/storage/app/public/" + checkoutFunnelData[1].first_product_img} className="product_img img-fluid" />
                                                    </div>
                                                    <div className='product_cont'>
                                                        <p className='prod_name'>
                                                            {checkoutFunnelData[0].first_product_name}
                                                        </p>
                                                        <p className='prod_offer_price'>
                                                            <span>Retail Price: <span className='text_red1'>${checkoutFunnelData[0].first_product_retail}</span></span><br />
                                                            <span>Your Price: ${checkoutFunnelData[0].first_product_price} – <strong>Save</strong>: ${checkoutFunnelData[0].first_product_save}</span>
                                                        </p>
                                                        <p className='prod_actual_price'>
                                                            <span className='price'>${eachFirstProductPrice ? eachFirstProductPrice : checkoutFunnelData[0].first_product_each} <em>each</em></span>
                                                        </p>
                                                        <p className='stock_status'>
                                                            In Stock. Current Sell-Out Risk: <span className="text_red1">HIGH</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Package 1 End  */}
                                        {/* Package 2  */}
                                        <div className={SPDiv ? 'package2 plan-selection mt-3 selected_pack' : 'package2 plan-selection mt-3'} onClick={() => { setOrderSummery(checkoutFunnelData[0].second_product_price, checkoutFunnelData[0].second_product_id, checkoutFunnelData[0].second_product_retail, checkoutFunnelData[0].second_product_save, checkoutFunnelData[0].second_product_shipping_id, 2,  checkoutFunnelData[0].second_product_each);handleRefClick() }}>
                                            <div className='payProd'>
                                                <div className='payProd_header'>
                                                    <p className='payProd_title'>{checkoutFunnelData[0].second_product_label}</p>
                                                    <span className='shipping_msg'> {checkoutFunnelData[0].second_product_shipping_id}</span>
                                                </div>
                                                <div className='payProd_body'>
                                                    <span className='radio'></span>
                                                    <div className='product_figure'>
                                                        <img src={BASE_URL+"whc-custom/storage/app/public/" + checkoutFunnelData[1].second_product_img} className="product_img img-fluid" />
                                                    </div>
                                                    <div className='product_cont'>
                                                        <p className='prod_name'> {checkoutFunnelData[0].second_product_name}</p>
                                                        <p className='prod_offer_price'>
                                                            <span>Retail Price: <span className='text_red1'>${checkoutFunnelData[0].second_product_retail}</span></span><br />
                                                            <span>Your Price: ${checkoutFunnelData[0].second_product_price} – <strong>Save</strong>: ${checkoutFunnelData[0].second_product_save}</span>
                                                        </p>
                                                        <p className='prod_actual_price'>
                                                            <span className='price'>${eachSecondProductPrice ? eachSecondProductPrice : checkoutFunnelData[0].second_product_each}</span><em> each</em>
                                                        </p>
                                                        <p className='stock_status'>
                                                            In Stock. Current Sell-Out Risk: <span className="text_red1">HIGH</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Package 2 End  */}
                                        {/* Package 3  */}

                                        <div className={TPDiv ? 'package3 plan-selection mt-3 selected_pack' : 'package3 plan-selection mt-3'} onClick={() => { setOrderSummery(checkoutFunnelData[0].third_product_price, checkoutFunnelData[0].third_product_id, checkoutFunnelData[0].third_product_retail, checkoutFunnelData[0].third_product_save, checkoutFunnelData[0].third_product_shipping_id, 3,  checkoutFunnelData[0].third_product_each);handleRefClick() }}>
                                            <div className='payProd'>
                                                <div className='payProd_header'>
                                                    <p className='payProd_title'>{checkoutFunnelData[0].third_product_label}</p>
                                                    <span className='shipping_msg'> {shippingDiv === false ? 'FREE' : '$9.95'} SHIPPING</span>
                                                </div>
                                                <div className='payProd_body'>
                                                    <span className='radio'></span>
                                                    <div className='product_figure'>
                                                        <img src={BASE_URL+"whc-custom/storage/app/public/" + checkoutFunnelData[1].third_product_img} className="product_img img-fluid" />
                                                    </div>
                                                    <div className='product_cont'>
                                                        <p className='prod_name'> {checkoutFunnelData[0].third_product_name} </p>
                                                        <p className='prod_offer_price'>
                                                            <span>Retail Price: <span className='text_red1'>${checkoutFunnelData[0].third_product_retail}</span></span><br />
                                                            <span>Your Price: ${checkoutFunnelData[0].third_product_price} – <strong>Save</strong>: ${checkoutFunnelData[0].third_product_save}</span>
                                                        </p>
                                                        <p className='prod_actual_price'>
                                                            <span className='price'>${eachThirdProductPrice ? eachThirdProductPrice : checkoutFunnelData[0].third_product_each}</span><em> each</em>
                                                        </p>
                                                        <p className='stock_status'>
                                                            In Stock. Current Sell-Out Risk: <span className="text_red1">HIGH</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Package 3 End  */}
                                    </div>

                                    {/* Order Delivery  */}
                                    <div className='order-delivery'>
                                        <img src={IconDelivery} className="icon_delivery img-fluid d-none" />
                                        <h3>USPS First-Class 2-3 Days Delivery</h3>
                                        <p>Parcels are shipped with insurance. Guaranteed Delivery.</p>
                                    </div>
                                    {/* Order Delivery end  */}


                                    {/* Order counter Begin  */}
                                    <div className="order-counter">
                                        {(urlSlug === '/brain4-checkout') ?
                                        <article className="clearfix">
                                            <div className="float_left">Product Total:</div>
                                            <div id="block2_shipping" className="float_right"><b>${(parseFloat(price)-(sapphirePrice)).toFixed(2)}</b></div>
                                            {/* <div className="clear">&nbsp;</div> */}
                                        </article>
                                        : ''}
                                        <article className="clearfix">
                                            <div className="float_left">Shipping Type:</div>
                                            <div id="block2_shipping" className="float_right">{shipping == 0 ? <b>FREE</b> : null} Priority Shipping</div>
                                            {/* <div className="clear">&nbsp;</div> */}
                                        </article>
                                        <article className="clearfix">
                                            <div className="float_left">Shipping Price:</div>
                                            <div id="block2_price" className="float_right"><b>${shipping.toFixed(2)}</b></div>
                                            {/* <div className="clear">&nbsp;</div> */}
                                        </article>
                                        <article id="" className="clearfix ws_vip">
                                            <div className="float_left">Sapphire Membership:</div>
                                            {/* <div id="block2_price" className="float_right"><b>$0.00</b></div> */}
                                            <div id="block2_price" className="float_right"><b>${sapphirePrice.toFixed(2)}</b></div>
                                            {/* <div className="clear">&nbsp;</div> */}
                                        </article>
                                        <article className="clearfix">
                                            <div className="float_left">Your Total:</div>
                                            <div id="block2_total" className="float_right">${total.toFixed(2)}</div>
                                            {/* <div className="clear">&nbsp;</div> */}
                                        </article>
                                        <article className="last clearfix">
                                            <div id="block2_total2" className="float_left">Retail: ${parseFloat(retail).toFixed(2)}</div>
                                            <div id="block2_save" className="float_right"><b>You Save: ${parseFloat(save).toFixed(2)}</b></div>
                                            {/* <div className="clear">&nbsp;</div> */}
                                        </article>
                                    </div>
                                    {/* Order counter End  */}

                                    {/* Gurantee Div  */}
                                    <div className="order-satisfaction">
                                        <img src={IconStisfaction} alt="" className="img-fluid gurantee_img d-none" />
                                        <h3>Satisfaction Guarantee!</h3>
                                        <p>We’re so confident that Neuro Tech IQ will work for you that we are offering a <b>30-Days Money Back
                                            Guarantee!</b> So, feel confident that you will get results, or your money back!</p>
                                    </div>
                                    {/* Gurantee Div End */}

                                    {/* security 1 begin  */}
                                    <div className="secu-1">
                                        <div className="row">
                                            <div className="col-md-9 mx-auto text-center">
                                                <div className="secImg-1">
                                                    <img src={PostalLogo} alt="" />
                                                </div>
                                                <div className="arrive-date">Your order is due to arrive on <span className="orange">{dateOneWeekFromNow}</span> .
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Security 1 end  */}

                                    {/* Security 2 Begin  */}
                                    <div className="secu-2">
                                        <div className="row">
                                            <div className="col-lg-9 mx-auto text-center">
                                                <div className="row secu-row">
                                                    <div className="col-3">
                                                        <div className="secImg-2">
                                                            <img className="img-fluid" src={SecurityBadge1} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="secImg-2">
                                                            <img className="img-fluid" src={SecurityBadge2} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="secImg-2">
                                                            <img className="img-fluid" src={SecurityBadge3} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="secImg-2">
                                                            <img className="img-fluid" src={SecurityBadge4} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Security 2 End  */}
                                </div>
                            </div>
                            {/* Left section end  */}
                            {/* Right Section  */}
                            <div className='orderWrapper-right'>
                                <div className='formBox'>
                                    <div className='formBox-hdr'>
                                        <img className="img-fluid" src={FormTop} alt="" />
                                    </div>
                                    <div className='formBox-body'>
                                        <div className='text-center payOption' ref={formRef}>
                                            <p className="allCards mb-0"><strong>We Accept:</strong></p>
                                            <img src={PaymentCards} className="img-fluid" alt="" />
                                        </div>
                                        {(urlSlug === '/brain10p-checkout') ?
                                            <div>
                                                <button  onClick={()=>PayPalHandler()} className='btn p-0'><img src={PaypalImage} className="img-fluid"/> </button>
                                            </div>
                                        : ''}
                                        {/* form Div Start  */}
                                        {(urlSlug === '/brain4-checkout') ?
                                        <>
                                            <div className={checkoutFunnelData[0].membership_add_on == 1 ? 'yellowbox mt-4' : 'd-none'} >
                                                <i>
                                                    <img className="pulse" src={Arrow2} alt="" />
                                                </i>
                                                <div className="check_area">
                                                    <input id="checkD" className="vip_shipping_desk" onChange={() => { handleSPAddon(selectedProduct) }} defaultChecked={SMAddonToggle} type="checkbox" />
                                                    <label htmlFor="checkD">YES, I want FREE Shipping With Sapphire Membership!</label>
                                                </div>
                                            </div>
                                            <div className={checkoutFunnelData[0].membership_add_on == 1 ? SMToggle ? 'top-terms open' : 'top-terms' : 'd-none'}>
                                                <h1 className="top-termsHdng" onClick={() => { setSMToggle(!SMToggle) }}>Sapphire Membership Terms</h1>
                                                <div className="top-trends-desc">
                                                    <p>BIG SAVINGS are ahead! Sapphire Membership comes loaded with benefits. At Wholesalehealthclub we pride ourselves on offering customers the best deals on the products they love. Your Sapphire Membership takes that one step further.</p>
                                                    <p>As a Sapphire Membership member we’ll send you a unique discount code each month to your inbox. Be sure to use this code at checkout to take advantage of the following benefits with your Sapphire Membership Today!:</p>
                                                    <p>-FREE shipping on all orders!</p>
                                                    <p>-Up to 80% off all of the great products you’ll find on wholesalehealthclub.com</p>
                                                    <p>-No hassle, no questions asked replacement on any items.</p>
                                                    <p>-Priority Processing – Have your orders placed in front of the line!</p>
                                                    <p>Some Notes About Your Membership:</p>
                                                    <p>Remember – while your shipping of all items from <Link to="/">Wholesalehealthclub</Link> with your Wholesalehealthclub Sapphire Membership is included with your purchase, you will be billed $17.00/ month to retain membership. If you wish to cancel your Sapphire Membership at any time, simply contact <br />our support using the below information: Support Email: <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a></p>
                                                </div>
                                            </div>
                                        </>
                                        : ''}
                                        <div className='checkout-form-div checkout-b'>
                                        <Formik
                                            enableReinitialize = {true}
                                            initialValues={{
                                                card_type: '',
                                                product_id:'',
                                                cc_month: 0,
                                                cc_year: '', 
                                                ccnumber: '', 
                                                cvv: '',
                                                shipping_address: '',
                                                shipping_city: '',
                                                shipping_state: '',
                                                shipping_zip: '',
                                                shipping_country: '239',
                                                affid: affid,
                                                sid: sid,
                                                s1: s1,
                                                s2: s2,
                                                s3: s3,
                                                click_id: click_id,
                                                notes: notes,
                                                shipCheckbox:true
                                            }}
                                            validationSchema={Yup.object({
                                                cc_year: Yup.string().required('Select valid year'),
                                                cc_month: Yup.number().moreThan(0,'Select valid month').required('Select month').when('cc_year', {
                                                    is: (cc_year) => (parseInt(moment().year()) === parseInt(cc_year)) ? true : false,
                                                    then: Yup.number().moreThan(parseInt(moment().format('MM'))-1, "Select valid month")
                                                }),
                                                card_type: Yup.string(),
                                                ccnumber: Yup.string().matches(/^\d+$/, 'Only numbers are allowed')
                                                .required('Card number is required')
                                                .max(16, 'Credit card should not be grater than 16 digits')
                                                .min(15, 'Credit card should not be less than 15 digits'),
                                                cvv: Yup.string().matches(/^[0-9]{3,4}$/,
                                                'Security code must be a 3 or 4 digit number')
                                                .required('Security code is required'),
                                                shipCheckbox:Yup.string(),
                                                    shipping_address: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping address is required")
                                                    }),
                                                    shipping_city: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping city is required")
                                                    }),
                                                    shipping_state: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping state is required")
                                                    }),
                                                    shipping_zip: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().when('shipping_country', {
                                                            is: '239',
                                                            then: Yup.string()
                                                              .required('Shipping zip code is required')
                                                              .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
                                                            otherwise: Yup.string().when('shipping_country', {
                                                              is: '41',
                                                              then: Yup.string()
                                                                .required('Shipping zip code is required')
                                                                .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                                                                .min(6, 'Zip code should contain at least 6 characters')
                                                                .max(6, 'Zip code should contain at most 6 characters'),
                                                            }),
                                                        }),
                                                    }),
                                                    shipping_country: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping country is required")
                                                    })
                                            })}

                                            onSubmit={(values, { setSubmitting }) => {
                                                setSubmitting (true);
                                                values.product_id=selectedProduct;
                                                values.card_type = GetCardType('"' + values.ccnumber + '"');
                                                //alert(JSON.stringify(values, null, 2));
                                                if(values.shipping_address !==''){
                                                    values.billingSameAsShipping = "NO";
                                                }else {
                                                    values.billingSameAsShipping = "YES";
                                                }
                                                if(values.shipping_country === '239'){
                                                    values.shipping_country = 'US';
                                                } else {
                                                    values.shipping_country = 'CA';
                                                }
                                                confirmSubmit(values);
                                            }}
                                        >
                                            {(formProps) => (
                                            
                                            <Form className='row form_row'>
                                            <Field type="hidden" className="form-control" id="card_type" name="card_type" value={cardType}/>
                                            <Field type="hidden" className="form-control" name="affid" value={affid}/>
                                            <Field type="hidden" className="form-control" name="sid" value={sid}/>
                                            <Field type="hidden" className="form-control" name="s1" value={s1}/>
                                            <Field type="hidden" className="form-control" name="s2" value={s2}/>
                                            <Field type="hidden" className="form-control" name="s3" value={s3}/>
                                            <Field type="hidden" className="form-control" name="click_id" value={click_id}/>
                                            <Field type="hidden" className="form-control" name="notes" value={notes}/>
                                            <div className='col-md-12 form_col'>
                                            <Field type="text" maxLength="16" className="form-control" placeholder="Card Number" name="ccnumber" onKeyUp={(event)=>setCreditCardType(event.target.value)}/>
                                                <CustomErrorMsg name="ccnumber" />
                                            </div>

                                            <div className='col-md-12 form_col'>
                                                <Field type="text" maxLength="4" className="mm-textField form-control" placeholder="CVV" name="cvv"/>
                                                <CustomErrorMsg name="cvv" />
                                            </div>

                                            <div className='col-md-12 form_col  mb-0 cc_form-holder'>
                                                <div className="cc-month">
                                                    <Field as="select" className="form-control mm-selectList" name="cc_month"  >
                                                        <option value="0">Select Month</option>
                                                        <option value="01">January</option>
                                                        <option value="02">February</option>
                                                        <option value="03">March</option>
                                                        <option value="04">April</option>
                                                        <option value="05">May</option>
                                                        <option value="06">June</option>
                                                        <option value="07">July</option>
                                                        <option value="08">August</option>
                                                        <option value="09">September</option>
                                                        <option value="10">October</option>
                                                        <option value="11">November</option>
                                                        <option value="12">December</option>
                                                    </Field>
                                                    <CustomErrorMsg name="cc_month" />
                                                </div>
                                                <div className="cc-year">
                                                    <Field as="select" className="form-control" name="cc_year" >
                                                        <option value="">Select Year</option>
                                                        {years.map(year => (
                                                            <option key={year} value={year}>
                                                                {year}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    <CustomErrorMsg name="cc_year" />
                                                </div>
                                            </div>
                                            <div className='col-md-12 form_col mt-3'>
                                                <div className='masterShipAddr'>
                                                    <label className="checkboxLabel mb-3">
                                                        <Field type="checkbox" id="shippingCheckbox" name="shipCheckbox" checked={checkboxValue===true ? 'checked' :""} onClick={(event) => formProps.setFieldValue("shipCheckbox", event.target.checked)}  onChange={handleCheckboxChange} />
                                                        <span className="">Shipping is the same as billing</span>
                                                    </label>
                                                    {showDiv &&
                                                    <div className="form-div-2">
                                                        <div className="form-holder">
                                                            <label className="formLabel">Shipping Address <span className='text-danger'>*</span></label>
                                                            <Field type="text" className="form-control" placeholder="Shipping Address" name="shipping_address"/>
                                                            <CustomErrorMsg name="shipping_address" />
                                                        </div>
                                                        <div className="form-holder">
                                                            <label className="formLabel">Shipping City <span className='text-danger'>*</span></label>
                                                            <Field type="text" className="form-control" placeholder="Shipping City" name="shipping_city"/>
                                                            <CustomErrorMsg name="shipping_city" />
                                                        </div>
                                                        <div className="form-holder">
                                                            <label className="formLabel">Shipping Country <span className='text-danger'>*</span></label>
                                                            <Field 
                                                                as="select" 
                                                                className="form-control" 
                                                                name="shipping_country"
                                                                onChange={(event) => {
                                                                    formProps.handleChange(event);
                                                                    handleCountryChange(event);
                                                                }}
                                                            >
                                                                <option value="239" >United States</option>
                                                                <option value="41" >Canada</option>
                                                            </Field>
                                                            <CustomErrorMsg name="shipping_country" />
                                                        </div>
                                                        <div className="form-holder">
                                                            <label className="formLabel">Shipping State <span className='text-danger'>*</span></label>
                                                            <Field 
                                                                as="select" 
                                                                className="form-control" 
                                                                name="shipping_state"
                                                            >
                                                            <option value="" >Select State</option>
                                                            {states.map((state) => (
                                                                <option key={state.id} value={state.abbreviation}>
                                                                    {state.state}
                                                                </option>
                                                            ))}
                                                            </Field>
                                                            <CustomErrorMsg name="shipping_state" />
                                                        </div>
                                                        <div className="form-holder">
                                                            <label className="formLabel">Shipping Zip Code <span className='text-danger'>*</span></label>
                                                            <Field type="text" maxLength="6" className="form-control" placeholder="Shipping Zip Code" name="shipping_zip"/>
                                                            <CustomErrorMsg name="shipping_zip" />
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-12 form_col'>
                                                <button type="submit" style={{ background: 'transparent' }} className='pay-btn text-center'><img src={PaymentButton} alt="" className="img-fluid" /></button>
                                            </div>
                                            </Form>
                                            )}
                                            </Formik>
                                        </div>
                                        {/* form Div End  */}

                                        {/* Trans Begin  */}
                                        <div className='trans text-center'>
                                            <img src={paymentMultiSecure} alt="" className="img-fluid w-100" />
                                        </div>
                                        {/* Trans End  */}

                                        {/* Safe Checkout Gurantee Begin  */}
                                        <div className="w_item w_item_1 section-guranteed">
                                            <div className="w_inner">
                                                <div className="w_thumb">
                                                    <figure>
                                                        <img className="img-fluid" alt="" src={Guaranteed} />
                                                    </figure>
                                                </div>
                                                <div className="w_desc">
                                                    <p>GUARANTEED <strong><span>SAFE</span></strong> CHECKOUT</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Safe Checkout Gurantee End  */}
                                        {(urlSlug === '/checkout-7') || (urlSlug === '/checkout-b') || (urlSlug === '/brain10p-checkout') ?
                                        <>
                                            <div className={checkoutFunnelData[0].membership_add_on == 1 ? 'yellowbox mt-4' : 'd-none'} >
                                                <i>
                                                    <img className="pulse" src={Arrow2} alt="" />
                                                </i>
                                                <div className="check_area">
                                                    <input id="checkD" className="vip_shipping_desk" onChange={() => { handleSPAddon(selectedProduct) }} defaultChecked={SMAddonToggle} type="checkbox" />
                                                    <label htmlFor="checkD">YES, I want FREE Shipping With Sapphire Membership!</label>
                                                </div>
                                            </div>
                                            <div className={checkoutFunnelData[0].membership_add_on == 1 ? SMToggle ? 'top-terms open' : 'top-terms' : 'd-none'}>
                                                <h1 className="top-termsHdng" onClick={() => { setSMToggle(!SMToggle) }}>Sapphire Membership Terms</h1>
                                                <div className="top-trends-desc">
                                                    <p>BIG SAVINGS are ahead! Sapphire Membership comes loaded with benefits. At Wholesalehealthclub we pride ourselves on offering customers the best deals on the products they love. Your Sapphire Membership takes that one step further.</p>
                                                    <p>As a Sapphire Membership member we’ll send you a unique discount code each month to your inbox. Be sure to use this code at checkout to take advantage of the following benefits with your Sapphire Membership Today!:</p>
                                                    <p>-FREE shipping on all orders!</p>
                                                    <p>-Up to 80% off all of the great products you’ll find on wholesalehealthclub.com</p>
                                                    <p>-No hassle, no questions asked replacement on any items.</p>
                                                    <p>-Priority Processing – Have your orders placed in front of the line!</p>
                                                    <p>-Get it all FREE for the first 14 days!</p>
                                                    <p>Some Notes About Your Membership:</p>
                                                    <p>Remember – while your 2 weeks of Wholesalehealthclub Sapphire Membership is free of charge, you will be billed $17.00/ month to retain membership after your first 2 weeks. If you wish to cancel your Sapphire Membership at any time, simply contact <br />our support using the below information: Support Email: <a href="mailto:support@wholesalehealthclub.com"> support@wholesalehealthclub.com </a></p>
                                                </div>
                                            </div>
                                        </>
                                        : ''}
                                    </div>
                                </div>
                            </div>
                            {/* Right section end  */}
                        </div>
                    </div>
                </div>

                {/* Security List Begin  */}
                <div className="securityIcon-sec pt-5 pb-5" style={{ background: '#000' }}>
                    <div className="container securityIcon-container" style={{ maxWidth: '1140px' }}>
                        <ul className="order-icons-list">
                            <li>
                                <img src={privacyVerified} alt="privacy verified" width="86" />
                            </li>
                            <li>
                                <img src={CertifiedBy} alt="certified" width="86" />
                            </li>
                            <li>
                                <img src={SecurityVerified} alt="security verified" width="86" />
                            </li>
                            <li>
                                <img src={BusinessVerified} alt="business verified" width="85" />
                            </li>
                            <li>
                                <img src={PaymentCards} alt="Visa, Mastercard, Discover accepted" width="160" height="36" />
                            </li>
                            <li>
                                <img src={PostalLogo2} alt="united states" width="299" />
                            </li>
                            <li>
                                <img src={OnlineLock} alt="shop online" width="187" />
                            </li>
                        </ul>
                    </div>
                </div>
                {/* Security List Begin  */}

                {/* Footer  */}
                <Copyright/>
                <Modal show={false} className="text-center">
                    <Modal.Header>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Spinner animation="grow" />
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal></div></> : null}

        </>

    )
}

export default MasterTemplate1