import therepyImg8 from '../../assests/Lander/assets/images/skincare6p/therepy1_img8.png';
import therepy2Img1 from '../../assests/Lander/assets/images/skincare6p/therepy2_img1.jpg';
import therepy2Img2 from '../../assests/Lander/assets/images/skincare6p/therepy2_img2.jpg';
import therepy2Img3 from '../../assests/Lander/assets/images/skincare6p/therepy2_img3.jpg';
import therepy2Img4 from '../../assests/Lander/assets/images/skincare6p/therepy2_img4.jpg';
import therepy2Img5 from '../../assests/Lander/assets/images/skincare6p/therepy2_img5.jpg';
import therepy2Img6 from '../../assests/Lander/assets/images/skincare6p/therepy2_img6.jpg';
import therepy2Img7 from '../../assests/Lander/assets/images/skincare6p/therepy2_img7.jpg';
import therepy2Img8 from '../../assests/Lander/assets/images/skincare6p/therepy2_img8.jpg';
import therepy2Img9 from '../../assests/Lander/assets/images/skincare6p/therepy2_img9.jpg';
import therepy2Img10 from '../../assests/Lander/assets/images/skincare6p/therepy2_img10.jpg';
import therepy2Img11 from '../../assests/Lander/assets/images/skincare6p/therepy2_img11.jpg';
import therepyImg47 from '../../assests/Lander/assets/images/skincare6p/therepy1_img47.jpg';
import therepyImg47_mob from '../../assests/Lander/assets/images/skincare6p/therepy1_img47-mob.jpg';
import truck from '../../assests/Lander/assets/images/skincare6p/truck.png';
import badge from '../../assests/Lander/assets/images/skincare6p/circle_tick.png';
import cardImg from '../../assests/Lander/assets/images/skincare6p/card_img.png';

function Skincare6pTherepy2(){
    return(
        <>
            <main className="thereapy1 bg-white">
                <div className="sk6Upsell_topbar">
                    <div className="cont_sec">
                        <h2>WAIT, YOUR ORDER IS NOT COMPLETE ...</h2>
                    </div>
                </div>

                <div className="sk6Upsell_banner thereapy2_banner">
                    <div className="cont_sec">
                        <div className="cont_sec">
                            <p className='sk6_subTitle text-center'>Women that purchased Age Brilliance Neck Cream have also upgraded the</p>
                            <h2 className='sk6_title2 text-center'><span className="txt_pink">“Age Brilliance Facial Massage Therapy”...</span><br />
                            As a Thank You, we’re happy to offer you
                            this exclusive upgrade and discount…</h2>
                        </div>
                    </div>
                </div>

                <div className="sthereapy2_sec2 py-5">
                    <div className="cont_sec upsellCont">
                        <p>Before you leave, I’d like to ask you a question — did you want the Age Brilliance Facial Massage Therapy Video Series but you didn’t have a choice but to say no?</p>  
                        <p>Lily and I want you to experience what other women, just like you, are enjoying today…</p>  
                        <p>And we don’t want you to pass on this exclusive solution just because of affordability…</p>
                        <h2 class="sk6_title3 text-center">And Maybe You Didn’t Want To Say No…</h2> 
                        <p>…but you didn’t have a choice because sometimes things get in the way of saying yes…		  </p>  
                        <p><strong className="yellowStrip1">…and we don’t want affordability to prevent you from getting firmer skin faster, and wrinkles removed, in</strong> <strong className="yellowStrip1">weeks instead of months.</strong></p>  
                        <p>Women deserve to experience for themselves the Age Brilliance Facial Massage Therapy Video Series so we want to offer you this special 50% off discount for today only. </p>  
                        <p>Because it’s something that could quickly help you look a decade younger by flushing out toxins and lowering cortisol levels to reveal a healthier and more radiant glow… </p>  
                        <p><strong>…So instead of $149 , you can get Age Brilliance Facial Massage Therapy Video Series for only $74.</strong></p>  
                        <p>That’s a saving of  $75  and you’ll get firmer skin and a smoother “wrinkle-free” face as the toxins are released and your cortisol levels drop.</p> 
                        <p>The Age Brilliance Facial Massage Therapy Video Series Includes Everything You Need To Firm-Up Your Sagging Skin & Iron-Out Wrinkles As Fast As Humanly Possible…</p> 
                    </div>
                    <img src={therepyImg8} alt='' className='w-100' />
                </div>

                <div className="sthereapy2_sec3 py-5">
                    <div className="cont_sec upsellCont">
                        <p><strong>You’ll now be able to look and feel beautiful in weeks instead of months because you’ll immediately:</strong></p>
                        <div className="row s6TextCard_row justify-content-center">
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepy2Img1} className="therepy2-Img1" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont text-center">
                                        <p>Remove dark circles and puffiness around the eyes...</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepy2Img2} className="therepy2-Img2" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont text-center">
                                        <p>Firm-up and lift droopy corners of the mouth...</p>
                                    </div>
                                </div>
                            </div>      
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepy2Img3} className="therepy2-Img3" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont text-center">
                                        <p>Smooth-out lines on the forehead...</p>
                                    </div>
                                </div>
                            </div> 
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepy2Img4} className="therepy2-Img4" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont text-center">
                                        <p>Remove marionette and smile lines...</p>
                                    </div>
                                </div>
                            </div>      
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepy2Img5} className="therepy2-Img2" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont text-center">
                                        <p>Sculpt the jawline and firm-up the neck area... </p>
                                    </div>
                                </div>
                            </div>      
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepy2Img6} className="therepy2-Img3" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont text-center">
                                        <p>Have fuller lips…			  </p>
                                    </div>
                                </div>
                            </div> 
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepy2Img7} className="therepy2-Img4" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont text-center">
                                        <p>Reduce fatty skin around the chin… </p>
                                    </div>
                                </div>
                            </div>                  
                        </div>
                    </div>
                </div>

                <div className="sthereapy2_sec4 py-5">
                    <div className="cont_sec upsellCont">
                        <div className='contentCard'>
                            <p><strong className='yellowStrip1'>And you’ll also get these free gifts:</strong></p>
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <img src={therepy2Img8} className="therepyImg2 img_shadow" alt="" />
                                </div>
                                <div className="col-md-4 mb-4">
                                    <img src={therepy2Img9} className="therepyImg2 img_shadow" alt="" />
                                </div>
                                <div className="col-md-4 mb-4">
                                    <img src={therepy2Img10} className="therepyImg2 img_shadow" alt="" />
                                </div>
                            </div>
                            <ul className='textList_1'>
                                <li>The “5-Day Quickstart Guide”, helping and guiding you on your beauty transformation…</li>
                                <li>The “Mastering Facial Massage”, that delves into the root causes of wrinkles and how to naturally remove them.</li>
                                <li>Mastering Facial Massage eBook</li>
                                <li>Luscious Lips Plumping Massage</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="sthereapy2_sec4 py-5">
                    <div className="cont_sec upsellCont">
                        <p>You Now Have A Second Chance To Make The Most Important Decision You’re Going To Make For Your Beauty Transformation…</p>
                        <p>We don’t want to let a few dollars come between you and a beautiful transformation…</p>
                        <div className='row mb-4'>
                            <div className='col-md-5 order-md-2 mb-3'>
                                <img src={therepy2Img11} className="therepyImg11 img_shadow d-block mx-auto" alt="" />
                            </div> 
                            <div className='col-md-7'>
                                <p>So instead of paying $149 ...</p>
                                <p>…you can now allow yourself to try out the Age Brilliance Facial Massage Therapy Video Series for only $69 .</p>
                                <p>And of course, this is backed by our love it or don’t pay for it 180 day money-back guarantee…</p>
                                <p>…so if you’re not happy for any reason, just let us know and we’ll refund every penny back to you.</p>
                                <p>...All you have to do is tap the upgrade button below and theAge Brilliance Facial Massage Therapy Video Series will be waiting for you in the members area.</p>
                            </div>     
                                                
                        </div>
                        <div className='priceCard-block mb-4'>
                            <div className='row align-items-center'>
                                <div className='col-md-8'>
                                    <img src={therepyImg47} className="therepyImg47 d-none d-md-block" alt="" /> 
                                    <img src={therepyImg47_mob} className="therepyImg47_mob mx-auto d-block d-md-none mb-4" alt="" />  
                                </div>
                                <div className='col-md-4'>
                                    <div className="proCard w-100"> 
                                        <div className="proCard_body">    
                                            <div className="proCard_pricePer">                               
                                                <p className='proCard_price'>$147</p>
                                            </div>                           
                                            <div className="save_price">                               
                                                SAVE <br /> <span className='saved'>$50.00</span>			  
                                            </div> 
                                            <div className='product_prices'>
                                                <p className='reg_price'>REGULARLY <span className='pr'>$89.00</span></p>
                                            </div>
                                            <div className='product_deal'>
                                                <div className='img_box'>
                                                    <img src={truck} className='' />
                                                    <span className='deal_txt'>FREE SHIPPING</span>
                                                </div>
                                                <div className='img_box'>
                                                    <img src={badge} className='' />
                                                    <span className='deal_txt'>180 DAY MONEY BACK GUARANTEE</span>
                                                </div>  
                                            </div>
                                            <button className='btn btn-primary btn-one-style btn-icon cart_btn'>Add to Cart</button>
                                            <img src={cardImg} className='card_img mx-auto d-block' alt='card_img' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <p className='text-center upsellSkip'><a href='#'><strong><u>No thanks Heather, I'll pass on this opportunity to take you up on this one-time offer where I can short-cut my beauty transformation.</u></strong></a></p>   
                       
                    </div>
                </div>

            </main>
        </>
    )
}


export default Skincare6pTherepy2;