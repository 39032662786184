import '../../assests/Lander/assets/css/dickPillUpsell.css';
import Copyright from "../Copyright";

import tag from '../../assests/Lander/assets/images/d-pill/tag.png';
import bar from '../../assests/Lander/assets/images/d-pill/bar.png';
import badge from '../../assests/Lander/assets/images/d-pill/badge.png';
import dickPillUpsell1 from '../../assests/Lander/assets/images/d-pill/upsell1_img_new.png';
import bodyPic from '../../assests/Lander/assets/images/d-pill/pic1_boy.png';
import pic2 from '../../assests/Lander/assets/images/d-pill/pic2.png';
import close from '../../assests/Lander/assets/images/d-pill/close.png';
import star from '../../assests/Lander/assets/images/d-pill/star.png';

function DickPillUpsell2(){
    return(
        <>
            <div className="dpillUpsell">
                <div className="header_upsell">
                    <div className="upsellContainer text-center">
                        <div className="offer_tag"><img src={tag} alt="" /></div>
                        <span><img src={bar} alt="" /></span>
                    </div>
                </div>
                <div className="dPillUpsellBody text-center">
                    <div className="upsellContainer">
                        <h5>This Offer Expires When Sold Out!</h5>
                        <h1>Wait, your order is not complete…</h1>
                        <p className='subTitle'>Customers that purchase <b>ALPHA DRIVE MEN ENHANCEMENT GUMMIES</b> also found results with <b>TOTAL MEN'S HEALTH GUMMIES</b>!<br />
                        With increased Testosterone levels, you will boost your <b>SEX DRIVE</b>. Keeping you ready to go morning, noon, or night!</p>
                        <div className="dPillUpsellBox">
                            <div className="product_wrap clearfix">
                                <div className="product_left">
                                    <span className="badge"><img src={badge} alt="" /></span>
                                    <div className="product_pic">
                                        <img src={dickPillUpsell1} alt="" />
                                    </div>
                                </div>
                                <div className="product_content">
                                    <h2>OUR #1 BEST SELLING</h2>
                                    <div className="price_tagD">
                                        <span className="pic1"><img src={bodyPic} alt="" /></span>
                                        <div className="pricepic">
                                            REG
                                            <span>$129.95</span>
                                        </div>
                                    </div>
                                    <div className="big_text big_text2">
                                        GET HARDER FOR <span> LONGER</span>          
                                    </div>                                
                                    <div className="sub_text">JUMPSTART YOUR LIBIDO</div>
                                    <p>Testo Gummies supplementation improves cardiac health, enhances performance during exercise, improves healing, reduces erectile dysfunction, reduces high blood pressure and improves stamina!</p>
                                    <div className="price_area">
                                        Claim your Bottles Today ONLY <span>$39.95<span className="title-block__retail">/bottle</span> or $79.90</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dPillUpsellBottom">
                            <div className="only_text">ONLY 14 LEFT!</div>
                            <form  className="is-upsell">
                                <div className="text-center"><button type="submit" className="checkout_btn pulse">Complete Checkout!</button></div>
                            </form>   
                            <div className="pic2"><img src={pic2} alt="" /></div>
                            <br />
                            <div className="dont_text">
                                <i><img src={close} alt="" /></i>
                                <span><a href="#">NO, I DON’T WANT TO TURBO CHARGE MY RESULTS</a></span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="dpillComnt_area">
                    <div className="upsellContainer">
                        <div className="connection_text">
                        </div>
                        <div className="star_blog">
                            <i><img src={star} alt="" /></i>
                            <h4>Get it, you won't regret it!</h4>
                            <span>by Rich</span>
                            <p>I love Testo Gummys products- I like this product a lot I believe it has made me have an increase in strength and energy levels. Would recommend!</p>
                        </div>
                        <div className="star_blog">
                            <i><img src={star} alt="" /></i>
                            <h4>It works.</h4>
                            <span>by Michael</span>
                            <p>I am now on my second bottle of this Gummys for men. The taste is amazing. It is like having a treat. I do feel like I am more energetic especially for my workouts. I take this with my pre workout routine and on a daily basis.</p>
                        </div>
                        <div className="star_blog">
                            <i><img src={star} alt="" /></i>
                            <h4>Solid Product</h4>
                            <span>by John</span>
                            <p>These helped me feel better all around from day to day activities to in the gym. Especially having to work all day. It really helped with fatigue mentally and physically.</p>
                        </div>
                        <div className="star_blog">
                            <i><img src={star} alt="" /></i>
                            <h4>Amazing!</h4>
                            <span>by Tyrael</span>
                            <p>I’ll say first that these gummies have a great taste and good consistency, as for the effects after staying consistent with them I did notice difference in energy and how I felt through the day, I would be happy to recommend these to anyone. Good quality product!</p>
                        </div>
                    </div>
                </div>
            </div>

            <Copyright/>
        
        </>
    )
}


export default DickPillUpsell2;