import React, { useState, useEffect, Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft, faEye } from '@fortawesome/free-solid-svg-icons'

function SapphireAddOn() {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [domainList, setDomainList] = useState([]);
    const [searchData, setsearchData] = useState("");
    const [search, setSearch] = useState([]);
    const [pending, setPending] = useState(true);
    const [id, setId] = useState(false);
    const [Details, setDetails] = useState([]);
    const [checkboxValue, setcheckboxValue] = useState(false);

    function createDomainModal() {
        setId(false);
        setDetails([]);
        handleShow();
    }

    const handleClose = () => {
        setShow(false);
    }

    let domainSubmit = (data) => {
        axios.post(`api/add-domain`, data).then(res => {
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getDomainList();
            } else {
                Swal.fire({
                    title: 'Warning',
                    html: res.data.message,
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        }).catch(err => {
            //console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    function updateStatus(id) {
        axios.put(`api/update-domain-status/${id}`).then(res => {
            Swal.fire({
                title: 'Updated',
                html: res.data.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1000
            })
            getDomainList();
        })
    }

    const columns = [
        {
            name: "Domain Name",
            selector: row => row.domain_name
        },
        {
            name: "Status",
            cell: row => (
                <label className="switch">
                    <input type="checkbox" defaultChecked={row.status == 1 ? true : false} name="status" onChange={() => { updateStatus(row.id) }}></input>
                    <span className="slider round"></span>
                </label>
            )
        },
        {
            name: "Created at",
            selector: row => row.created_at.split("T")[0]
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { showSingleData(row.id) }} ><FontAwesomeIcon icon={faGear} /></button>
                    <button className='btn btn-danger' onClick={() => { deleteData(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]

    function getDomainList() {
        setPending(true);
        axios.get(`api/domain-list`).then(res => {
            setDomainList(res.data.data);
            setSearch(res.data.data);
            setPending(false);
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    useEffect(() => {
        getDomainList();
    }, []);

    useEffect(() => {
        const result = domainList?.filter(domainName => {
            return domainName.domain_name.toLowerCase().match(searchData.toLowerCase());
        })
        setSearch(result);
    }, [searchData]);

    function showSingleData(id) {
        setId(id);
        axios.get(`api/domain-data/${id}`).then(res => {
            setDetails(res.data.data);
            console.log(res.data.data);
            handleShow();
        })
    }

    function deleteData(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this lnder?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`api/delete-domain/${id}`).then(res => {
                    if (res.data.status) {
                        Swal.fire({
                            title: 'Deleted',
                            html: res.data.message,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        })
                        getDomainList();
                    }
                    else {
                        Swal.fire({
                            title: 'Warning',
                            html: res.data.message,
                            icon: 'warning'
                        })
                    }

                })
            }
        })
    }

    const domainUpdate = (data) => {
        if(data.status === false){
            data.status = 0;
        } else {
            data.status = 1;
        }
        axios.post(`api/domain-update/${id}`, data).then(res => {
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getDomainList();
            }
            else {
                Swal.fire({
                    title: 'Warning',
                    html: res.data.message,
                    icon: 'warning'
                })
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });
    }

    const handleCheckboxChange = (event) => {
        setcheckboxValue(event.target.checked);
    };

    return (
        <div>
            <div>
                <div className='modal'>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Domain</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <div className='card'>
                                            <Formik
                                                enableReinitialize = {true}
                                                initialValues={{ domain: Details.domain_name, status:Details.status == 1 ? true : true}}
                                                validationSchema={Yup.object({
                                                    domain: Yup.string().required('Domain name is required'),
                                                })}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    setSubmitting(true);
                                                    // alert(JSON.stringify(values, null, 2));
                                                    {(id) ? domainUpdate(values) : domainSubmit(values) }
                                                }}
                                            >
                                                {(formProps) => (
                                                    <Form className='bonusForm'>
                                                        <div className='form-group md-3'>
                                                            <label> Domain Name </label>
                                                            <Field type="text" name="domain" className="form-control" />
                                                            <CustomErrorMsg name="domain" />
                                                        </div>
                                                        <div className='form-group md-3'>
                                                            <label> Status </label>
                                                            <label className="switch" style={{marginLeft:16}}>
                                                                <Field type="checkbox" name="status" onClick={(event) => formProps.setFieldValue("status", event.target.checked)}  onChange={handleCheckboxChange} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                        <div className='form-group md-3 text-end'>
                                                            {(id) ?
                                                                <Button type="submit" className='btn submitBtn btn-primary'>Update</Button>
                                                            :
                                                                <Button type="submit" className='btn submitBtn btn-primary'>Add</Button>
                                                            }
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Domain List</div>
                            <div className="buttun_div  col-md-9">
                                <Button variant="primary" onClick={createDomainModal}>
                                    Add domain
                                </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="No Data Available" //or your component
                                    columns={columns}
                                    data={search}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="Search" value={searchData} onChange={(e) => { setsearchData(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SapphireAddOn