import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Button, } from 'react-bootstrap'
import Swal from 'sweetalert2'
import Navbar from '../../../layouts/frontend/Navbar'
import { useNavigate } from "react-router-dom"; // v6
import logo from '../../../assests/admin/assets/img/Wholesale_Health_Club_Logo_440x180-01.png'
import useStore from '../../../store'

function Login() {
    const navigate = useNavigate();
    const isLogin = useStore((state) => state.isLogin)
    const userType = useStore((state) => state.userType)
    const afterLogin = useStore((state) => state.afterLogin)
    //const inputReference = useRef(null);

    useEffect(() => {
        //inputReference.current.focus();
        if (isLogin == 1) {
            if(userType == 2 || userType == 1){
                navigate("/admin/dashboard");
            }
            else{
                navigate("/");
            }
        }
    }, []);


    const [loginInput, setLoginInput] = useState({
        email: '',
        pass: '',
        error_email: '',
        error_pass: '',
        error_list: ''

    })
    const handelInputs = (e) => {
        e.persist();
        setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
    }
    const loginSubmit = (e) => {
        e.preventDefault();

        const data = {
            email: loginInput.email,
            password: loginInput.pass
        }


        axios.post(`api/loginuser`, data).then(res => {
            if (res.data.status === true) {
                localStorage.setItem('authToken', res.data.token);
                localStorage.setItem('permission', res.data.acoount_permission);
                axios.defaults.headers.post['Authorization'] = 'Bearer ' + res.data.token;
                afterLogin(res.data.acoount_permission,res.data.user_type);
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                if(res.data.user_type == 1 || res.data.user_type == 2){
                    navigate("/admin/dashboard")
                }
                else{
                    navigate("/")
                }
            }
        }).catch(err => {
            setLoginInput({ ...loginInput, error_list: err.response.data.message });
        })
    }


    return (
        <>
            <div className='consec'>
                <h1>Wholesale Health Club</h1>
                <div className="Auth-form-container">
                    <form className="Auth-form" onSubmit={loginSubmit}>
                        <div className="Auth-form-content">
                            <h3 className="Auth-form-title">Sign In</h3>
                            <div className="form-group mt-3">
                                <label>Email address</label>
                                <input
                                    name='email'
                                    type="email"
                                    className="form-control mt-1"
                                    //placeholder="Enter email" ref={inputReference} onChange={handelInputs} value={loginInput.email}
                                    placeholder="Enter email" onChange={handelInputs} value={loginInput.email}
                                />
                                {loginInput.error_list}
                            </div>
                            <div className="form-group mt-3">
                                <label>Password</label>
                                <input
                                    name='pass'
                                    type="password"
                                    className="form-control mt-1"
                                    placeholder="Enter password" onChange={handelInputs} value={loginInput.pass}
                                />
                            </div>
                            <div className="d-grid gap-2 mt-3">
                                <Button type="submit" className="btn btn-primary">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Login