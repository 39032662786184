import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assests/admin/css/checkout-funnel.css';
// import './assests/admin/template1/css/brain-app.css';
// import './assests/admin/template1/css/brain-main.css';
// import './assests/admin/template1/css/brain-style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Context from './context/Context';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Context>
      <BrowserRouter>
      <GoogleReCaptchaProvider
          reCaptchaKey="6Legt0UfAAAAAK0pJuaswZ4vni2Y5E1l19_EK3wZ"
        >
        <App />
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </Context>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
