import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import style from '../../assests/frontend/assets/css/CustomerService.module.css';


import logo from "../../assests/frontend/assets/images/cs/logo.png";
import ccicon from "../../assests/frontend/assets/images/cs/cc-icon.png";
import ic7icon from "../../assests/frontend/assets/images/cs/ic7.jpg";
import ic6icon from "../../assests/frontend/assets/images/cs/ic6.jpg";
import ic5icon from "../../assests/frontend/assets/images/cs/ic5.jpg";
import ic4icon from "../../assests/frontend/assets/images/cs/ic4.jpg";
import ic1icon from "../../assests/frontend/assets/images/cs/ic1.jpg";
import ic2icon from "../../assests/frontend/assets/images/cs/ic2.jpg";
import ic3icon from "../../assests/frontend/assets/images/cs/ic3.jpg";
import copy from "../../assests/frontend/assets/images/cs/download.png";
import send from "../../assests/frontend/assets/images/cs/send.png";
import paymentIcon from "../../assests/frontend/assets/images/cs/payment-icon.png";
import shipIcon from "../../assests/frontend/assets/images/cs/ship-icon.png";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import { useNavigate, useParams, Link } from 'react-router-dom';
import moment from "moment";
import {BASE_URL, GetCardType } from '../Config';
import useStore from '../../store';
import CryptoJS from 'crypto-js';

function CustomerService(){
    const [loadershow, setloadershow] = useState(false);
    const [billingAdd, setBillingAdd] = useState(true);
    const [visibleProductList, setVisibleProductLists] = useState([]);
    const [states, setStates] = useState([]);
    const [shippingStates, setShippingStates] = useState([]);
    const params2 = useParams();
    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => currentYear + index);
    const navigate = useNavigate();
    const [totalPrice, setTotalPrice] = useState(0);
    const [checkboxValue, setcheckboxValue] = useState(true);
    const afterLogin = useStore((state) => state.afterLogin);
    const [displayInput, setDisplayInput] = useState(false);
    let localURL = window.location.origin+'/guest-checkout';
    const [checkoutURL, setCheckoutURL] = useState('');
    function changeStatus(event){
        setcheckboxValue(event.target.checked);
        setBillingAdd(!billingAdd);
    }
    const [selectedProducts, setSelectedProducts] = useState([]);
    const membershipProductIds = [59, 60, 41];

    const [show, setShow] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isCopied, setIsCopied] = useState(false);
    const [isUpdateButtonClicked, setIsUpdateButtonClicked] = useState(false);

    async function getCsVisibleProductList() {
        setloadershow(true);
        axios.get(`api/listcsvisibleproduct`).then(res => {
            setVisibleProductLists(res.data.data);
            setloadershow(false);
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        })
    }

    useEffect(() => {
        getCsVisibleProductList();
    }, []);

    const updateTotalPrice = (products, allProducts) => {
        const selectedItems = allProducts.filter(item => products.includes(item.id));
        const total = selectedItems.reduce((acc, item) => acc + parseFloat(item.price), 0);
        setTotalPrice(total);
    };

    const handleCountryChange2 = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    const handleShippingCountryChange2 = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setShippingStates(res.data.data);
            }
        })
    };
    
    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    const handleShippingCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setShippingStates(res.data.data);
            }
        })
    };

    useEffect(()=>{
        handleCountryChange2('239');
        handleShippingCountryChange2('239');
    },[params2.slug]);


    const [copyText, setCopyText] = useState('http://localhost:3000/css');
    const [copied, setCopied] = useState(false);

    function handleCopy(){
        navigator.clipboard.writeText(copyText);
        setCopied(true);
        // alert('Url Copied!');
    }

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const handleCopyCodeClick = (e) => {
        e.preventDefault();
        copyTextToClipboard(checkoutURL)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                // console.log(err);
            });
    }

    const handleClick = () => {
        if (!selectedProducts || selectedProducts.length === 0) {
            setErrorMsg(true);
        } else {
            const productsString = JSON.stringify(selectedProducts);
            console.log(productsString);
            const secretKey = "SMGReactLive";
            const encryptedProducts = CryptoJS.AES.encrypt(productsString, secretKey).toString();
            const checkoutURLVar = `${localURL}?products=${encodeURIComponent(encryptedProducts)}`;
            setCheckoutURL(checkoutURLVar);
            setDisplayInput(true);
            setErrorMsg(false);
        }
    }

    const confirmEmail = (value, resetForm) => {
        setloadershow(true);
        const formdata = new FormData();
        localStorage.setItem('email',value.cemail);
        formdata.append('cname', value.cname);
        formdata.append('cemail', value.cemail);
        formdata.append('clink', value.clink);
        axios.post(`api/send-email-to-customer`, formdata).then(res => {
            //console.log(res);
            if (res.data.status === true) {
                resetForm();
                setShow(false);
                setDisplayInput(false);
                setSelectedProducts([]);
                setTotalPrice('');
                const initialForm = document.getElementById("initialForm");
                if (initialForm) {
                    initialForm.reset();
                }
                Swal.fire({
                    title: 'Success',
                    html: 'Email has been sent successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                setloadershow(false);
            }
            else {
                Swal.fire({
                    title: 'Warning',
                    html: res.data.message,
                    icon: 'warning'
                });

            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: 'Something went wrong! Please try again',
                icon: 'warning'
            })
        });
    }
  
    const confirmSubmit = (data, resetForm) => {
        if (!isUpdateButtonClicked) {
            setIsUpdateButtonClicked(true);
            if(data.products.length > 0) {
                setloadershow(true);
                let expirationDate = data.cc_month+data.cc_year.slice(-2);
                const formdata = new FormData();
                formdata.append('first_name', data.firstName);
                formdata.append('last_name', data.lastName);
                formdata.append('country', data.country === '239' ? 'US' : 'CA');
                formdata.append('address', data.shippingAddress1);
                formdata.append('city', data.shippingCity);
                formdata.append('state', data.shippingState);
                formdata.append('zip', data.shippingZip);
                formdata.append('phone', data.phone);
                formdata.append('email', data.email);
                formdata.append('creditCardNumber', data.ccnumber);
                formdata.append('creditCardType', data.card_type);
                formdata.append('expirationDate', expirationDate);
                formdata.append('cvv', data.cvv);
                formdata.append('cartData', data.products);

                formdata.append('billingSameAsShipping', data.billingSameAsShipping);
                formdata.append('shipping_address', data.shipping_address);
                formdata.append('shipping_city', data.shipping_city);
                formdata.append('shipping_state', data.shipping_state);
                formdata.append('shipping_zip', data.shipping_zip);
                formdata.append('shipping_country', data.shipping_country);

                //console.log(Object.fromEntries(formdata));
                axios.post(`api/place_customer_order`, formdata).then(res => {
                    //console.log(res.data.response_code);
                    if (res.data.response_code === '100') {
                        Swal.fire({
                            title: 'Success',
                            html: res.data.message,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        })
                        setloadershow(false);
                        setTotalPrice('');
                        resetForm();
                    }
                    else {
                        Swal.fire({
                            title: 'Warning',
                            html: res.data.message,
                            icon: 'warning'
                        });

                    }
                }).catch(err => {
                    Swal.fire({
                        title: 'Warning',
                        html: 'Something went wrong! Please try again',
                        icon: 'warning'
                    })
                });
            } else {
                setErrorMsg(true);
            }
        }
        
    }

    return (
        <>
            <div className={loadershow ? 'loading' : 'd-none'} style={{zIndex:9999}}></div>
            <div className={style.cartSection}>
                <div className="container">
                    <p className='text-center pb-4'>
                        <Link className="mwLogo" to="/"><img src={logo} alt="" className={style.mainLogo} /></Link>
                    </p>
                    
                        <Formik
                            initialValues={{
                                agent: '',
                                firstName: '',
                                lastName: '',
                                shippingAddress1: '',
                                shippingZip: '',
                                shippingCity: '',
                                shippingState: '',
                                email: '',
                                phone: '',
                                country:'239',
                                products: [],
                                card_type: '',
                                cc_month: 0,
                                cc_year: '', 
                                ccnumber: '', 
                                cvv: '',
                                shipping_address: '',
                                shipping_city: '',
                                shipping_state: '',
                                shipping_zip: '',
                                shipping_country: '239',
                                acceptTerms: false,
                                shipCheckbox:true,
                            }}
                            validationSchema={Yup.object({
                                products: Yup.array().min(1, 'At least one product must be selected'),
                                agent: Yup.string().required('Agent name is required'),
                                firstName: Yup.string().required('First name is required'),
                                lastName: Yup.string().required('Last name is required'),
                                shippingAddress1: Yup.string().required('Address is required'),
                                shippingZip: Yup.string().when('country', {
                                    is: '239',
                                    then: Yup.string()
                                        .required('Zip code is required')
                                        .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
                                    otherwise: Yup.string().when('country', {
                                        is: '41',
                                        then: Yup.string()
                                        .required('Zip code is required')
                                        .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                                        .min(6, 'Zip code should contain at least 6 characters')
                                        .max(6, 'Zip code should contain at most 6 characters'),
                                    }),
                                }),
                                shippingCity: Yup.string().required('City is required'),
                                shippingState: Yup.string().required('State is required'),
                                email: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
                                phone: Yup.string().required('Phone no is required')
                                    .matches(/^[0-9]+$/, "Please enter only number!")
                                    .min(10, 'Please enter a valid contact number!')
                                    .max(10, 'Please enter a valid contact number!'),
                                country: Yup.string().required('Country is required'),
                                cc_year: Yup.string().required("Select valid year"),
                                cc_month: Yup.number().moreThan(0,'Select valid month').required('Select month').when('cc_year', {
                                    is: (cc_year) => (parseInt(moment().year()) === parseInt(cc_year)) ? true : false,
                                    then: Yup.number().moreThan(parseInt(moment().format('MM'))-1, "Select valid month")
                                }),
                                ccnumber:  Yup.string().matches(/^\d+$/, 'Only numbers are allowed')
                                    .required('Card number is required')
                                    .max(16, 'Credit card should not be grater than 16 digits')
                                    .min(15, 'Credit card should not be less than 15 digits'),
                                cvv: Yup.string().matches(/^[0-9]{3,4}$/,
                                    'Security code must be a 3 or 4 digit number')
                                    .required('Security code is required'),
                                acceptTerms: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
                                shipCheckbox:Yup.string(),
                                    shipping_address: Yup.string().when("shipCheckbox", {
                                        is: (val)=> (val==='false') ? true:false,
                                        then: Yup.string().required("Please enter your shipping address!")
                                    }),
                                    shipping_city: Yup.string().when("shipCheckbox", {
                                        is: (val)=> (val==='false') ? true:false,
                                        then: Yup.string().required("Please enter your shipping city!")
                                    }),
                                    shipping_state: Yup.string().when("shipCheckbox", {
                                        is: (val)=> (val==='false') ? true:false,
                                        then: Yup.string().required("Please enter your shipping state!")
                                    }),
                                    shipping_zip: Yup.string().when("shipCheckbox", {
                                        is: (val)=> (val==='false') ? true:false,
                                        then: Yup.string().when('shipping_country', {
                                            is: '239',
                                            then: Yup.string()
                                              .required('Shipping zip code is required')
                                              .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
                                            otherwise: Yup.string().when('shipping_country', {
                                              is: '41',
                                              then: Yup.string()
                                                .required('Shipping zip code is required')
                                                .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                                                .min(6, 'Zip code should contain at least 6 characters')
                                                .max(6, 'Zip code should contain at most 6 characters'),
                                            }),
                                        }),
                                    }),
                                    shipping_country: Yup.string().when("shipCheckbox", {
                                        is: (val)=> (val==='false') ? true:false,
                                        then: Yup.string().required("Please enter your shipping country!")
                                    })
                            })}
                            onSubmit={(values, {resetForm} ) => {
                                values.card_type = GetCardType('"' + values.ccnumber + '"');
                                //alert(JSON.stringify(values, null, 2));
                                if(values.shipping_address !==''){
                                    values.billingSameAsShipping = "NO";
                                }else {
                                    values.billingSameAsShipping = "YES";
                                }
                                if(values.shipping_country === '239'){
                                    values.shipping_country = 'US';
                                } else {
                                    values.shipping_country = 'CA';
                                }
                                confirmSubmit(values,resetForm);
                            }}
                        >
                            {(formProps, values) =>(
                            <Form id='initialForm'>
                                <span className="mainProductcampaign"></span>
                                <div className={style.cartBox}>
                                    <div className={style.cartBox__right}>
                                        <div className={style.cartHeadings}>
                                            <div className={style.prodDesc}>Alpha Drive</div>
                                            <div className={style.unitPrc}>Price</div>
                                        </div>
                                        {visibleProductList && visibleProductList.length > 0 ? (
                                            visibleProductList.map((item, index) => (
                                                item.name.includes("Alpha Drive RX") ?
                                                <div key={index}>
                                                    <div className={style.cartRow}>
                                                        <div className={style.prodDesc}>
                                                        <Field
                                                                type="checkbox"
                                                                id={`product_${item.id}`}
                                                                className={style.cusCheckbox}
                                                                name="products"
                                                                value={item.id}
                                                                checked={formProps.values.products.includes(item.id)}
                                                                onChange={(event) => {
                                                                    const currentProducts = formProps.values.products;
                                                                    const newProducts = event.target.checked
                                                                        ? [...currentProducts, item.id]
                                                                        : currentProducts.filter(id => id !== item.id);
                                                                    newProducts.length > 0 ? setErrorMsg(false) :  setErrorMsg(true);
                                                                    formProps.setFieldValue('products', newProducts);
                                                                    updateTotalPrice(newProducts, visibleProductList);
                                                                    setSelectedProducts(newProducts);
                                                                }}
                                                            />
                                                            <label htmlFor={`product_${item.id}`} className={style.cartProName}>
                                                                {item.name.replace("Alpha Drive RX ", "")}
                                                            </label>
                                                        </div>
                                                        <div className={style.unitPrc}>
                                                            ${item.price}
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                            ))
                                        ) : (
                                            <div>No Alpha Drive RX products available</div>
                                        )}
                                    </div>
                                    <div className={style.cartBox__mid}>
                                        <div className={style.cartHeadings}>
                                            <div className={style.prodDesc}>Synaptic XR</div>
                                            <div className={style.unitPrc}>Unit Price</div>
                                        </div>
                                        {visibleProductList && visibleProductList.length > 0 ? (
                                            visibleProductList.map((item, index) => (
                                                item.name.includes("Synaptic XR") ?
                                                <div key={index}>
                                                    <div className={style.cartRow}>
                                                        <div className={style.prodDesc}>
                                                        <Field
                                                                type="checkbox"
                                                                id={`product_${item.id}`}
                                                                className={style.cusCheckbox}
                                                                name="products"
                                                                value={item.id}
                                                                checked={formProps.values.products.includes(item.id)}
                                                                onChange={(event) => {
                                                                    const currentProducts = formProps.values.products;
                                                                    const newProducts = event.target.checked
                                                                        ? [...currentProducts, item.id]
                                                                        : currentProducts.filter(id => id !== item.id);
                                                                    newProducts.length > 0 ? setErrorMsg(false) :  setErrorMsg(true);
                                                                    formProps.setFieldValue('products', newProducts);
                                                                    updateTotalPrice(newProducts, visibleProductList);
                                                                    setSelectedProducts(newProducts);
                                                                }}
                                                            />
                                                            <label htmlFor={`product_${item.id}`} className={style.cartProName}>
                                                                {item.name.replace("Synaptic XR ", "")}
                                                            </label>
                                                        </div>
                                                        <div className={style.unitPrc}>
                                                            ${item.price}
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                            ))
                                        ) : (
                                            <div>No Synaptic XR products available</div>
                                        )}
                                    </div>
                                    <div className={style.cartBox__left}>
                                        <div className={style.cartHeadings}>
                                            <div className={style.prodDesc}>Upsells</div>
                                            <div className={style.unitPrc}>Price</div>
                                        </div>
                                        {visibleProductList && visibleProductList.length > 0 ? (
                                            visibleProductList.map((item, index) => {
                                            const isMembershipProduct = membershipProductIds.includes(item.id);
                                            const isAnyMembershipSelected = formProps.values.products.some(productId => membershipProductIds.includes(productId));
                                            
                                            return !item.name.includes("Alpha Drive RX") && !item.name.includes("Synaptic XR") ? (
                                                <div key={index}>
                                                    <div className={style.cartRow}>
                                                        <div className={style.prodDesc}>
                                                            <Field
                                                                type="checkbox"
                                                                id={`product_${item.id}`}
                                                                className={style.cusCheckbox}
                                                                name="products"
                                                                value={item.id}
                                                                checked={formProps.values.products.includes(item.id)}
                                                                // Disable other membership checkboxes if one is already selected
                                                                disabled={isMembershipProduct && isAnyMembershipSelected && !formProps.values.products.includes(item.id)}
                                                                onChange={(event) => {
                                                                    const currentProducts = formProps.values.products;
                                                                    const newProducts = event.target.checked
                                                                        ? [...currentProducts, item.id]
                                                                        : currentProducts.filter(id => id !== item.id);
                                                                    newProducts.length > 0 ? setErrorMsg(false) :  setErrorMsg(true);
                                                                    formProps.setFieldValue('products', newProducts);
                                                                    updateTotalPrice(newProducts, visibleProductList);
                                                                    setSelectedProducts(newProducts);
                                                                }}
                                                            />
                                                            <label htmlFor={`product_${item.id}`} className={style.cartProName}>
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                        <div className={style.unitPrc}>
                                                            ${item.price}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null;
                                        })
                                    ) : (
                                        <div>No Upsell products available</div>
                                    )}
                                    </div>
                                    <div style={{ marginTop: 20 }}></div>
                                    <div className={style.cartHeadingsBottom}>
                                        <div className={style.unitPrc}>Grand Total</div>
                                        <div className="subTot">${totalPrice ? totalPrice : '0.00'} </div>
                                    </div>
                                </div>
                                <div className="clearall" />
                                <div className={style.outterBox}>
                                    <div className={style.outterBox__left}>
                                        <div className={style.leftBox}>
                                            <div className={style.frmHeading}>
                                                <img src={shipIcon} alt=""/>
                                                <p>Shipping Information</p>
                                            </div>
                                            <div className={style.frmBox}>
                                                <div className={style.inputBox}>
                                                    <img src={ic5icon} alt="ic7" className={style.fldicon} />
                                                    <Field
                                                        type="text"
                                                        name="agent"
                                                        className="field-all"
                                                        placeholder="Agent"
                                                        required=""
                                                    />
                                                </div>
                                                <CustomErrorMsg name="agent" />
                                                <div className={style.inputBox}>
                                                    <img src={ic1icon} alt="" className={style.fldicon} />
                                                    <Field
                                                        type="text"
                                                        name="firstName"
                                                        placeholder="First Name"
                                                        className="form-control mb-2 required field-all"
                                                    />
                                                </div>
                                                <CustomErrorMsg name="firstName" />
                                                <div className={style.inputBox}>
                                                    <img src={ic1icon} alt="" className={style.fldicon} />
                                                    <Field
                                                        type="text"
                                                        name="lastName"
                                                        placeholder="Last Name"
                                                        className="form-control mb-2 required field-all"
                                                    />
                                                </div>
                                                <CustomErrorMsg name="lastName" />
                                                <div className={style.inputBox}>
                                                    <img src={ic2icon} alt="ic1" className={style.fldicon} />
                                                    <Field
                                                        type="text"
                                                        name="email"
                                                        placeholder="Please enter your email address!"
                                                        className="required field-all"
                                                    />
                                                </div>
                                                <CustomErrorMsg name="email" />
                                                <div className={style.inputBox}>
                                                    <img src={ic3icon} alt="ic3" className={style.fldicon} />
                                                    <Field
                                                        type="text"
                                                        name="phone"
                                                        placeholder="Phone"
                                                        className="field-all required"
                                                        maxLength={10}
                                                    />
                                                </div>
                                                <CustomErrorMsg name="phone" />
                                                <div className={style.inputBox}>
                                                    <img src={ic4icon} alt="ic6" className={style.fldicon} />
                                                    <Field
                                                        className="required field-all"
                                                        name="shippingAddress1"
                                                        placeholder="Address*"
                                                        title="Address"
                                                        type="text"
                                                    />
                                                </div>
                                                <CustomErrorMsg name="shippingAddress1" />
                                                {/* <div className={style.inputBox}>
                                                    <img src={ic4icon} alt="ic6" className={style.fldicon} />
                                                    <Field
                                                        className="field-all"
                                                        name="shippingAddress2"
                                                        placeholder="Apartment, Suite, Floor, etc. (Optional)"
                                                        title="Address2"
                                                        type="text"
                                                    />
                                                </div> */}
                                                <div className={style.inputBox}>
                                                    <img src={ic7icon} alt="ic7" className={style.fldicon} />
                                                    <Field
                                                        className="form-control required"
                                                        name="shippingZip"
                                                        placeholder="ZIP/Postal Code*"
                                                        title="Postal Code"
                                                        type="text"
                                                        maxLength="6"
                                                    />
                                                </div>
                                                <CustomErrorMsg name="shippingZip" />
                                                <div className={style.inputBox}>
                                                    <img src={ic4icon} alt="ic7" className={style.fldicon} />
                                                    <Field
                                                        className="required field-all"
                                                        name="shippingCity"
                                                        placeholder="City*"
                                                        title="City"
                                                        type="text"
                                                    />
                                                </div>
                                                <CustomErrorMsg name="shippingCity" />
                                                <div className={style.inputBox}>
                                                    <img src={ic4icon} alt="ic1" className={style.fldicon} />
                                                    <Field
                                                        as="select"
                                                        name="country"
                                                        className="form-control mb-2 required field-all"
                                                        onChange={(event) => {
                                                            formProps.handleChange(event);
                                                            handleCountryChange(event);
                                                        }}
                                                    >
                                                        <option value="239">United States</option>
                                                        <option value="41" >Canada</option>
                                                    </Field>
                                                </div>
                                                <CustomErrorMsg name="country" />
                                                <div className={style.inputBox}>
                                                    <img src={ic6icon} alt="ic1" className={style.fldicon} />
                                                    <Field
                                                        name="shippingState"
                                                        as="select"
                                                        placeholder="Select an option*"
                                                        className="field-all mb-2 required remove-class custom-select"
                                                    >
                                                        <option value="" >Select state</option>
                                                        {states.map((state) => (
                                                            <option key={state.id} value={state.abbreviation}>
                                                                {state.state}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                </div>
                                                <CustomErrorMsg name="shippingState" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.outterBox__right}>
                                        <div className={style.rgtBox}>
                                            <div className={style.frmHeading}>
                                                <img src={paymentIcon} alt=""/>
                                                <p>Payment Information</p>
                                            </div>
                                            <div className={style.frmBox}>
                                                <div className={style.prodDesc}>
                                                    <Field
                                                        type="checkbox"
                                                        name="shipCheckbox"
                                                        id="billShipSame"
                                                        className={style.cusCheckbox}
                                                        checked={checkboxValue===true ? 'checked' :""}
                                                        onChange={changeStatus}
                                                        onClick={(event) => formProps.setFieldValue("shipCheckbox", event.target.checked)}
                                                    />
                                                    <label htmlFor="billShipSame" className={style.cartProName}>
                                                        Billing Address same as Shipping
                                                    </label>
                                                </div>
                                            
                                                <div className="clearall" />
                                                
                                                {!billingAdd && (

                                                    <div
                                                        className="billing billing-info pt-2 mb-4"
                                                        style={{ display: "block" }}
                                                    >
                                                        
                                                        <div className={style.inputBox}>
                                                            <img src={ic4icon} alt="ic1" className={style.fldicon} />
                                                            <Field
                                                                as="select"
                                                                name="shipping_country"
                                                                className="form-control mb-2 field-all"
                                                                onChange={(event) => {
                                                                    formProps.handleChange(event);
                                                                    handleShippingCountryChange(event);
                                                                }}
                                                            >
                                                                <option value="239" >United States</option>
                                                                <option value="41" >Canada</option>
                                                            </Field>
                                                        </div>
                                                        <CustomErrorMsg name="shipping_country" />
                                                        <div className={style.inputBox}>
                                                            <img src={ic6icon} alt="ic1" className={style.fldicon} />
                                                            <Field
                                                                as="select"
                                                                name="shipping_state"
                                                                className="form-control mb-2 custom-select"
                                                                placeholder="Shipping state"
                                                            >
                                                                <option value="" >Select an option</option>
                                                                {shippingStates.map((state) => (
                                                                    <option key={state.id} value={state.abbreviation}>
                                                                        {state.state}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                        </div>
                                                        <CustomErrorMsg name="shipping_state" />
                                                        <div className={style.inputBox}>
                                                            <img src={ic4icon} alt="ic6" className={style.fldicon} />
                                                            <Field
                                                                type="text"
                                                                name="shipping_address"
                                                                placeholder="Shipping address"
                                                                className="form-control mb-2  field-all"
                                                            />
                                                        </div>
                                                        <CustomErrorMsg name="shipping_address" />
                                                        <div className={style.inputBox}>
                                                            <img src={ic4icon} alt="ic7" className={style.fldicon} />
                                                            <Field
                                                                type="text"
                                                                name="shipping_city"
                                                                placeholder="Shipping city"
                                                                className="form-control mb-2  field-all"
                                                            />
                                                        </div>
                                                        <CustomErrorMsg name="shipping_city" />
                                                        <div className={style.inputBox}>
                                                            <img src={ic7icon} alt="ic7" className={style.fldicon} />
                                                            <Field
                                                                type="text"
                                                                name="shipping_zip"
                                                                placeholder="Shipping zip Code"
                                                                className="form-control mb-2 field-all"
                                                                maxLength="6"
                                                            />
                                                        </div>
                                                        <CustomErrorMsg name="shipping_zip" />
                                                    </div>
                                                )}
                                            
                                                <div className={style.cardAccept}>
                                                    We Accept: <img src={ccicon} alt="" />
                                                </div>

                                                <div className={style.paymentDiv}>
                                                    <div className={style.inputBox}>
                                                        <Field
                                                            type="text"
                                                            name="ccnumber"
                                                            //className="required field-all"
                                                            placeholder="Card Number"
                                                            maxLength="16"
                                                        />
                                                    </div>
                                                    <CustomErrorMsg name="ccnumber" />
                                                    <div className={`${style.payRow} row`}>
                                                        <div className='col-md-6'>
                                                            <div className={style.inputBox}>
                                                                <Field
                                                                    as="select"
                                                                    name="cc_month"
                                                                    className="required form-control col field-all"
                                                                >
                                                                    <option value="0">Select Month</option>
                                                                    <option value="01">January</option>
                                                                    <option value="02">February</option>
                                                                    <option value="03">March</option>
                                                                    <option value="04">April</option>
                                                                    <option value="05">May</option>
                                                                    <option value="06">June</option>
                                                                    <option value="07">July</option>
                                                                    <option value="08">August</option>
                                                                    <option value="09">September</option>
                                                                    <option value="10">October</option>
                                                                    <option value="11">November</option>
                                                                    <option value="12">December</option>
                                                                </Field>
                                                            </div>
                                                            <CustomErrorMsg name="cc_month" />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className={style.inputBox}>
                                                                <Field
                                                                    as="select"
                                                                    name="cc_year"
                                                                    className="required form-control col field-all"
                                                                >
                                                                    <option value="">Select Year</option>
                                                                    {years.map(year => (
                                                                        <option key={year} value={year}>
                                                                            {year}
                                                                        </option>
                                                                    ))}
                                                                </Field>
                                                            </div>
                                                            <CustomErrorMsg name="cc_year" />
                                                        </div>
                                                    </div>                                               
                                                    
                                                    <div className={style.inputBox}>
                                                        <Field
                                                            type="text"
                                                            maxLength="4"
                                                            name="cvv"
                                                            className="form-control fcheckout-field required col-12 field-all"
                                                            placeholder="CVV"
                                                        />
                                                    </div>
                                                    <CustomErrorMsg name="cvv" />
                                                </div>

                                                <div className="clearall mb-4" />

                                                <div className={style.prodDesc}>
                                                    <Field name="acceptTerms" type="checkbox" id="trms" className={style.cusCheckbox} />
                                                    <label htmlFor="trms" className={style.cartProName}>
                                                        <p className='mb-0'>
                                                            Client agrees to the Terms &amp; Conditions and Privacy Policy.
                                                        </p>
                                                    </label>
                                                    <p className='mb-0' style={{paddingLeft: 12}}><CustomErrorMsg  name="acceptTerms" /></p>
                                                </div>   
                                                <div className='text-center mb-2'>{!errorMsg ? <CustomErrorMsg  name="products" /> : ''}</div>
                                                <button className={style.frmBtn} type="submit">
                                                    <span>Order</span>
                                                </button>

                                                <div className={style.urlBtn} type="button" onClick={handleClick}>
                                                    <span> Generate checkout link</span>
                                                </div>
                                                {errorMsg ? <span className='text-center d-block text-danger bold'>Please select atleast one product</span> : ''}
                                                {displayInput && selectedProducts.length > 0 ? (
                                                    <div className={style.linkDiv}>
                                                        <p className={style.urlLink}>
                                                            <Field readOnly={true} type="text" value={checkoutURL}/>
                                                        </p>
                                                        <div className={style.urlBtnGrps}>
                                                            <div className={`${style.urlIcon} ${copied ? style.active : ''}`} title='Copy' onClick={handleCopyCodeClick}>
                                                                <img src={copy} alt='copy' />
                                                                <span className={style.copyTxtTooltip}>{isCopied ? 'Copied!' : 'Copy'}</span>
                                                            </div>
                                                            <div className={`${style.urlIcon}`} title='Send' onClick={handleShow}>
                                                                <img src={send} alt="Send" />
                                                                <span className={style.copyTxtTooltip}>Send</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ''}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </Form>
                            )}
                        </Formik>
                    
                </div>
            </div>


            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                >
                <Modal.Header closeButton>
                <Modal.Title>Customer details:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            cname: '',
                            cemail: '',
                            clink: ''
                        }}
                            validationSchema={Yup.object({
                                cname: Yup.string().required('Customer name is required'),
                                cemail: Yup.string()
                                    .email("Please enter a valid email address")
                                    .max(255)
                                    .required("Customer email is required"),
                                clink: Yup.string().required('Checkout link is required'),
                            })}
                            onSubmit={(values, {resetForm} ) => {
                                confirmEmail(values,resetForm);
                            }}
                        >
                        <Form>
                            <div className='mb-3'>
                                <label>Customer name:</label>
                                <Field 
                                    className="form-control" 
                                    type="text"
                                    placeholder="Customer name"
                                    name="cname"
                                />
                            </div>
                            <CustomErrorMsg name="cname" />
                            <div className='mb-3'>
                                <label>Customer email:</label>
                                <Field 
                                    className="form-control"
                                    type="email"
                                    placeholder="Customer email"
                                    name="cemail" 
                                />
                            </div>
                            <CustomErrorMsg name="cemail" />
                            <div className='mb-3'>
                                <label>Checkout link:</label>
                                <Field 
                                    className={`${style.checkoutLink} form-control`}
                                    type="text"
                                    placeholder="Checkout link"
                                    name="clink"
                                    
                                />
                                <CustomErrorMsg name="clink" />
                            </div>
                            
                            <div className='mb-3'>
                                <button type="submit" className={`${style.btn_submit}`}>Submit</button>
                            </div>
                        </Form>
                    </Formik>   
                </Modal.Body>
            </Modal>

            <p id="loading-indicator" style={{ display: "none" }}>
                Processing...
            </p>
        </>
    )
}
export default CustomerService;