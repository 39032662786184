import React  from "react";
import { Link }  from 'react-router-dom';
import { useEffect,useState } from 'react';

import accessDeniedImg from '../assests/frontend/assets/images/access-denied.jpg';

function RestrictionPage(){

    const [loadershow, setloadershow] = useState(true);

    return(
        <>
            {/* {loadershow  ? <div className="loader-new loading"></div> : ''} */}
            <div className="accessDeniedPage">
                <div className="container">
                    <div className="deniedBox text-center">
                        <img className="de-img" src={accessDeniedImg} alt="" />
                        <div className="accessDenied-cont">
                            <h2>Access Denied !</h2>
                            <p>Your country don’t have access to view this page.</p>
                        </div>
                    </div>
                </div>
            </div>        
        </>
    )
}


export default RestrictionPage;