import { Accordion } from 'react-bootstrap';

import therepyImg1 from '../../assests/Lander/assets/images/skincare6p/therepy1_img1.png';
import therepyImg1_mob from '../../assests/Lander/assets/images/skincare6p/therepy1_img1-mob.jpg';
import therepyImg2 from '../../assests/Lander/assets/images/skincare6p/therepy1_img2.jpg';
import therepyImg3 from '../../assests/Lander/assets/images/skincare6p/therepy1_img3.png';
import therepyImg4 from '../../assests/Lander/assets/images/skincare6p/therepy1_img4.jpg';
import therepyImg5 from '../../assests/Lander/assets/images/skincare6p/therepy1_img5.jpg';
import therepyImg6 from '../../assests/Lander/assets/images/skincare6p/therepy1_img6.jpg';
import therepyImg7 from '../../assests/Lander/assets/images/skincare6p/therepy1_img7.png';
import therepyImg8 from '../../assests/Lander/assets/images/skincare6p/therepy1_img8.png';
import therepyImg9 from '../../assests/Lander/assets/images/skincare6p/therepy1_img9.jpg';
import therepyImg10 from '../../assests/Lander/assets/images/skincare6p/therepy1_img10.jpg';
import therepyImg11 from '../../assests/Lander/assets/images/skincare6p/therepy1_img11.jpg';
import therepyImg12 from '../../assests/Lander/assets/images/skincare6p/therepy1_img12.jpg';
import therepyImg13 from '../../assests/Lander/assets/images/skincare6p/therepy1_img13.jpg';
import therepyImg14 from '../../assests/Lander/assets/images/skincare6p/therepy1_img14.jpg';
import therepyImg15 from '../../assests/Lander/assets/images/skincare6p/therepy1_img15.jpg';
import therepyImg16 from '../../assests/Lander/assets/images/skincare6p/therepy1_img16.jpg';
import therepyImg17 from '../../assests/Lander/assets/images/skincare6p/therepy1_img17.jpg';
import therepyImg18 from '../../assests/Lander/assets/images/skincare6p/therepy1_img18.jpg';
import therepyImg19 from '../../assests/Lander/assets/images/skincare6p/therepy1_img19.jpg';
import therepyImg20 from '../../assests/Lander/assets/images/skincare6p/therepy1_img20.jpg';
import therepyImg21 from '../../assests/Lander/assets/images/skincare6p/therepy1_img21.jpg'; 
import therepyImg22 from '../../assests/Lander/assets/images/skincare6p/therepy1_img22.jpg'; 
import therepyImg23 from '../../assests/Lander/assets/images/skincare6p/therepy1_img23.jpg'; 
import therepyImg24 from '../../assests/Lander/assets/images/skincare6p/therepy1_img24.jpg'; 
import therepyImg25 from '../../assests/Lander/assets/images/skincare6p/therepy1_img25.jpg'; 
import therepyImg26 from '../../assests/Lander/assets/images/skincare6p/therepy1_img26.jpg'; 
import therepyImg27 from '../../assests/Lander/assets/images/skincare6p/therepy1_img27.jpg'; 
import therepyImg28 from '../../assests/Lander/assets/images/skincare6p/therepy1_img28.jpg'; 
import therepyImg29 from '../../assests/Lander/assets/images/skincare6p/therepy1_img29.jpg'; 
import therepyImg30 from '../../assests/Lander/assets/images/skincare6p/therepy1_img30.jpg'; 
import therepyImg31 from '../../assests/Lander/assets/images/skincare6p/therepy1_img31.jpg'; 
import therepyImg32 from '../../assests/Lander/assets/images/skincare6p/therepy1_img32.jpg';
import therepyImg33 from '../../assests/Lander/assets/images/skincare6p/therepy1_img33.jpg';
import therepyImg34 from '../../assests/Lander/assets/images/skincare6p/therepy1_img34.jpg';
import therepyImg35 from '../../assests/Lander/assets/images/skincare6p/therepy1_img35.jpg';
import therepyImg36 from '../../assests/Lander/assets/images/skincare6p/therepy1_img36.jpg';
import therepyImg37 from '../../assests/Lander/assets/images/skincare6p/therepy1_img37.jpg';
import therepyImg38 from '../../assests/Lander/assets/images/skincare6p/therepy1_img38.jpg';
import therepyImg39 from '../../assests/Lander/assets/images/skincare6p/therepy1_img39.jpg';
import therepyImg40 from '../../assests/Lander/assets/images/skincare6p/therepy1_img40.jpg';
import therepyImg41 from '../../assests/Lander/assets/images/skincare6p/therepy1_img41.jpg';
import therepyImg42 from '../../assests/Lander/assets/images/skincare6p/therepy1_img42.jpg';
import therepyImg43 from '../../assests/Lander/assets/images/skincare6p/therepy1_img43.jpg';
import therepyImg44 from '../../assests/Lander/assets/images/skincare6p/therepy1_img44.jpg';
import therepyImg45 from '../../assests/Lander/assets/images/skincare6p/therepy1_img45.jpg';
import therepyImg46 from '../../assests/Lander/assets/images/skincare6p/therepy1_img46.jpg';
import therepyImg47 from '../../assests/Lander/assets/images/skincare6p/therepy1_img47.jpg';
import therepyImg47_mob from '../../assests/Lander/assets/images/skincare6p/therepy1_img47-mob.jpg';
import truck from '../../assests/Lander/assets/images/skincare6p/truck.png';
import badge from '../../assests/Lander/assets/images/skincare6p/circle_tick.png';
import cardImg from '../../assests/Lander/assets/images/skincare6p/card_img.png';

function Skincare6pTherepy1(){
    return(
        <>
            <main className="thereapy1 bg-white">
                <div className="sk6Upsell_topbar">
                    <div className="cont_sec">
                        <h2>WAIT, YOUR ORDER IS NOT COMPLETE – DO NOT CLICK THE BACK BUTTON</h2>
                    </div>
                </div>

                <div className="sk6Upsell_banner thereapy1_banner">
                    <div className="cont_sec">
                        <div className="row">
                            <div className="col-md-8 col-lg-7">
                                <div className="sk6Upsell_banner__cont">
                                    <h2 className="sk6_title">This <span className="txt_pink">Stress Hormone</span> Is Standing Between <span className="txt_pink">You and a Complete Beauty Makeover</span></h2>                                   
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <img src={therepyImg1} className="sk6Upsell_banner__img d-none d-md-block" alt="" />   
                                <img src={therepyImg1_mob} className="sk6Upsell_banner__img_mob d-md-none mx-auto d-block mt-3" alt="" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="sthereapy1_sec2 py-5">
                    <div className="cont_sec">
                        <div className="row">                            
                            <div className="col-md-8 col-lg-8 order-md-2">  
                                <div className='upsellCont'>                          
                                    <p className='f-30 trirong_font'>Hey it's <strong>Kai here…</strong></p>
                                    <p>And we saved the best for last , and it’s ONLY for the woman who wants more than a nice complexion, because “average” isn’t an option for her…</p>
                                    <p>…who want to defy age and achieve the exceptional…</p>
                                    <p>…And who want more than surface level beauty.</p>
                                    <p>This is for a woman that wants the ultimate advantage, and not just any advantage…</p>
                                    <p><strong>…an unfair advantage that gives you </strong> <strong className='yellowStrip1'>an edge above the rest. </strong></p>
                                    <p>Because this <strong>goes</strong> beyond creams, serums or cosmetics, and has <strong>nothing to do with pills</strong> , supplements or complex gadgets…</p>
                                </div>    
                            </div>    
                            <div className="col-md-4 col-lg-4">
                                <img src={therepyImg2} className="therepyImg2 img_shadow" alt="" />   
                            </div>                        
                        </div>
                    </div>
                </div>

                <div className="sthereapy1_sec3">
                    <div className="cont_sec">
                        <div className="row align-items-center">                            
                            <div className="col-md-8 col-lg-7">  
                                <div className='upsellCont'>                          
                                    <p>…but, if you do use any of those, <strong>this just makes everything so much better</strong> – it even works if you don’t use any of them.</p>
                                    <p>Here’s what’s going on…</p>
                                    <p>The biggest culprit of women aging isn’t only about getting older…</p>
                                    <p>…it isn’t only about sun exposure…</p>
                                    <p>…it isn’t only about genetics…</p>
                                    <p>…and it isn’t only about diet or your lifestyle.</p>
                                    <p>There’s something else…</p>
                                    <p>…that a certain percentage of women have.</p>
                                    <p>Because if you're experiencing puffy eyes, skin that feels bloated, uneven texture, blotchy patches, and the need to use more makeup as a cover-up…</p>
                                </div>    
                            </div>   
                            <div className="col-md-4 col-lg-5 mb-4">
                                <img src={therepyImg3} className="therepyImg3 mx-auto d-block" alt="" />   
                            </div>                         
                        </div>
                    </div>
                </div>


                <div className="thereapy1_sec4 py-4">
                    <div className="cont_sec contentCard">
                        <div className="row align-items-center">                            
                            <div className="col-md-8 col-lg-7">  
                                <div className='upsellCont'>                          
                                    <p>…it’s not a matter of poor hydration or exercise, applying face masks, or even the active ingredients in anti-aging products…</p>
                                    <p>…because it has nothing to do with any of those either.</p>
                                    <p>There’s something else that’s going on that you’re probably unaware of…</p>
                                    <p>…an “X Factor” of aging…</p>
                                    <p><strong>…and the “X Factor” of aging is the stress hormone called Cortisol.</strong></p>
                                    <p>…Women with higher than normal cortisol levels tend to have a more tired-looking complexion, uneven skin texture, and more pronounced wrinkles.</p>                                    
                                </div>    
                            </div> 
                            <div className="col-md-4 col-lg-5 mt-3  mt-md-0 mb-4">
                                <img src={therepyImg4} className="therepyImg4 img_shadow" alt="" />   
                            </div>                           
                        </div>
                        <div className='upsellCont'>
                            <p>The stress hormone Cortisol “locks up” toxins in the skin, and that's a problem because…</p>
                            <p>If you carry any amount of stress <i>(and who doesn’t these days)</i>...</p>
                            <p>…that stress releases localized Cortisol into the skin which ages you faster than anything else.</p>
                            <p>Because if you’ve ever started the morning, looking in the mirror and seeing a swollen face and puffy eyes staring back at you…</p>
                            <p>…and feeling exhausted, sluggish and a bit irritable…</p>
                            <p>That's an example of Cortisol getting trapped in your skin.</p>
                            <p>And it’s not only Cortisol that’s building up in your skin…</p>
                        </div>
                    </div>
                </div>

                <div className="thereapy1_sec5">
                    <div className="cont_sec">
                        <h2 className="sk6_title3 text-center">These Other Toxins Are Stuck In Your Body <br /><span className="txt_pink">And Accelerating Premature Aging…</span></h2>                                   
                        <div className="row">
                            <div className="col-md-4 col-lg-5 order-md-2 mb-4">
                                <img src={therepyImg5} className="therepyImg5" alt="" />   
                            </div>
                            <div className="col-md-8 col-lg-7"> 
                                <div className='upsellCont'>  
                                    <p>Our bodies are also exposed to other toxins like air pollution, vehicle exhaust fumes and other harmful chemicals…</p>
                                    <p>…and these toxins build-up and accumulate in our skin as well.</p>
                                    <p>…because when you were younger, your internal “Detox System”, called the Lymphatic System, did a great job flushing out all those toxins…</p>
                                    <p><strong>But as we get older, our bodies become less efficient and our Lymphatic System slows down…</strong></p>
                                    <p>…and fails to flush out all those toxins…</p>
                                    <p>…so they build up in the surface layer of our skin, leading to a dull, tired, and worn out looking complexion.</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>



                <div className="thereapy1_sec6 py-4">
                    <div className="cont_sec contentCard">
                        <h2 className="sk6_title3 text-center">Cortisol & Toxins Are Blocking Active Ingredients From Getting <br /><span className="txt_pink">To The Root Cause Of Sagging Skin And Wrinkles</span></h2>                                   
                        <div className="row align-items-center mt-4">
                            <div className="col-md-4 col-lg-5 order-md-2 mb-4">
                                <img src={therepyImg6} className="therepyImg6 img_shadow" alt="" />   
                            </div>
                            <div className="col-md-8 col-lg-7"> 
                                <div className='upsellCont'>  
                                    <p>These toxins act as a “barrier”, preventing absorption of nutrients that are essential to repairing, restoring, and rejuvenating your skin.</p>
                                    <p>That’s the bad news… </p>
                                    <p><strong>But thankfully there’s an easy way to naturally reduce</strong> Cortisol and remove those toxins…</p>
                                    <p>…that’s simple, safe, and effective for women of any age.</p>
                                    <p>Because when Cortisol levels are reduced and released from the body…</p>
                                    <p>…you’ll feel happier, healthier and more relaxed.</p>
                                    <p>And once the toxins have been flushed out of your skin...</p>                                    
                                </div>
                            </div>                           
                        </div>
                        <div className='upsellCont'>
                            <p><strong>Then the ingredients proven to remove wrinkles and firm-up loose skin can “100% get to work” and begin your beauty transformation.</strong></p>
                        </div>
                    </div>
                </div>


                <div className="thereapy1_sec7 py-4">
                    <div className="cont_sec">
                        <div className="row align-items-center">
                            <div className="col-md-3 col-lg-3 order-md-2 mb-4">
                                <img src={therepyImg7} width="150" className="therepyImg7 mx-auto d-block" alt="" />   
                            </div>
                            <div className="col-md-8 col-lg-9"> 
                                <div className='upsellCont'>
                                    <p>And this is why Lily created <strong>Age Brilliance Therapy Facial Massage.</strong></p>
                                    <p><strong>Age Brilliance Therapy Facial Massage helps the Lymphatic System to naturally flush out all those toxins quickly and easily…</strong></p>
                                </div> 
                            </div>                            
                        </div>
                    </div>
                </div>

                <div className="thereapy1_sec8">
                    <div className="imgDiv">
                        <img src={therepyImg8} className="therepyImg8 w-100" alt="" />                             
                    </div>
                </div>


                <div className="thereapy1_sec9 py-4">
                    <div className="cont_sec">
                        <h2 className="sk6_title3 text-center">Age Brilliance Therapy Reduces Your Cortisol Levels Naturally,<br /><span className="txt_pink">To Restore Your Skin To A Youthful Glow By…</span></h2>        
                        <div className="row s6TextCard_row">
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepyImg9} className="therepyImg9" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont">
                                        <p>Reducing puffiness, wrinkles and dark circles by stimulating lymphatic drainage and detoxifying the skin…</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepyImg10} className="therepyImg10" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont">
                                        <p>Improving skin tone and texture by increasing circulation that promotes oxygen and nutrients to the skin…</p>
                                    </div>
                                </div>
                            </div>      
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepyImg11} className="therepyImg11" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont">
                                        <p>Stimulating collagen for improved elasticity by increasing blood flow to promote essential proteins…</p>
                                    </div>
                                </div>
                            </div> 
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepyImg12} className="therepyImg12" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont">
                                        <p>And promoting new skin cell growth by encouraging cell turnover and regeneration, resulting in fresher, healthier, and more radiant skin that glows from within.</p>
                                    </div>
                                </div>
                            </div>                     
                        </div>
                    </div>
                </div>


                <div className="thereapy1_sec10 py-4">
                    <div className="cont_sec">
                        <p className="f-30 trirong_font text-center"><strong><i>…and is incredibly beneficial for a woman's overall health by…</i></strong></p>        
                        <div className="row s6TextCard_row">
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepyImg13} className="therepyImg13" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont">
                                        <p>Boosting the body’s immune system to help reduce the risk of chronic disease…</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepyImg14} className="therepyImg14" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont">
                                        <p>Reducing harmful inflammation that can lead to skin problems like psoriasis and eczema…</p>
                                    </div>
                                </div>
                            </div>      
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepyImg15} className="therepyImg15" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont">
                                        <p>Improving sleep by removing stress and tension build up…</p>
                                    </div>
                                </div>
                            </div> 
                            <div className="col-md-6 col-lg-3 s6TextCard_col">
                                <div className="s6TextCard">
                                    <div className="s6TextCard_fig">
                                        <img src={therepyImg16} className="therepyImg16" alt="" />   
                                    </div>
                                    <div className="s6TextCard_cont">
                                        <p>And improving overall mental health.</p>
                                    </div>
                                </div>
                            </div>                     
                        </div>
                    </div>
                </div>


                <div className="tthereapy1_sec11 py-4">
                    <div className="cont_sec contentCard upsellCont px-0">
                        <div className='side_gap'>                                                                      
                            <h2 className="sk6_title3 text-center">“Lymphatic Drainage Is A Simple Process That Takes Just 5 Minutes A Day And… Any Woman Can Do It!”</h2>   
                            <p>With self-facial massage that not only stimulates the Lymphatic System to flush out toxins…</p>
                            <p>…but also reduces levels of the “X Factor”, Cortisol so you’ll have</p>
                        </div>
                        <div className="wrapper">
                            <div className="row imgTextCard_row">
                                <div className="col imgTextCard_col">
                                    <div className="imgTextCard">
                                        <img src={therepyImg17} className="therepyImg17" alt="" />
                                        <p>Improved Sleep</p> 
                                    </div>
                                </div><div className="col imgTextCard_col">
                                    <div className="imgTextCard">
                                        <img src={therepyImg18} className="therepyImg18" alt="" />
                                        <p>Better Moods</p> 
                                    </div>
                                </div>
                                <div className="col imgTextCard_col">
                                    <div className="imgTextCard">
                                        <img src={therepyImg19} className="therepyImg19" alt="" />
                                        <p>Radiant & Healthier Looking Skin</p> 
                                    </div>
                                </div>
                                <div className="col imgTextCard_col">
                                    <div className="imgTextCard">
                                        <img src={therepyImg20} className="therepyImg20" alt="" />
                                        <p>Reduced Brain Fog</p> 
                                    </div>
                                </div>
                                <div className="col imgTextCard_col">
                                    <div className="imgTextCard">
                                        <img src={therepyImg21} className="therepyImg21" alt="" />
                                        <p>and Better Weight Management</p> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='side_gap mt-4'>
                            <p><strong>…and with this special facial massage you’ll experience a smoother, firmer, and a more contoured profile..</strong></p>
                            <p>Because in order for the active ingredients in anti-aging creams to get to the root cause of sagging and wrinkles that are in the deeper layers of the skin…</p>
                            <p>The toxins in the skin need to be removed first before applying creams…</p>
                            <p className='f-30 trirong_font text-center'>“Women need to prepare their skin <u>first</u> <br /> before applying any facial cream or serum.”</p>
                            <div className="row">
                                <div className="col-md-4 col-lg-5 order-md-2 mb-4">
                                    <img src={therepyImg22} className="therepyImg22 mx-auto d-block" alt="" />   
                                </div>
                                <div className="col-md-8 col-lg-7"> 
                                    <div className='upsellCont'>  
                                        <p>Age Brilliance Facial Massage is simple and easy…</p>
                                        <p>…so simple and easy, that it takes just a few minutes…</p>
                                        <p>…for your skin to immediately feel rejuvenated, refreshed and energized.</p>
                                        <p><strong>Age Brilliance Therapy is a facial self-massage, that you treat yourself to once every day…</strong></p>
                                        <p>Because if you’ve ever felt a little tired waking up starting the day…</p>
                                        <p>…or if you’ve noticed your face showing signs of fatigue, more than you were comfortable with...</p>
                                        <p>…or if you’ve been discouraged with your complexion because it just didn’t feel as radiant as you hoped…</p>                                        
                                    </div> 
                                </div>                            
                            </div>
                        </div>   
                    </div>
                </div>


                
                <div className="thereapy1_sec12 py-4">
                    <div className="cont_sec upsellCont">
                        <div className=''>                            
                            <p>Then Age Brilliance Therapy will dramatically change those feelings within just a few minutes.</p>
                            <p>Because, as you gently move your hands and fingers along targeted areas of your face and neck…</p>
                            <p>…your skin will feel stimulated, refreshed, and you’ll recharge not only the skin that you're touching…</p> 
                            <p>…it will also:</p>
                        </div>
                        <div className="row imageCard_row mb-3 mb-md-5">
                            <div className="col-lg-6 col-xl-3 imageCard_col">
                                <div className="imageCard1">                               
                                    <div className="imageCard1_fig">                            
                                        <img src={therepyImg23} className="therepyImg23" alt="" />   
                                    </div>
                                    <div className="imageCard1_cont text-center">                            
                                        <p>Reduce Cortisol levels for an immediate sense of peacefulness…</p>
                                    </div>                                
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3 imageCard_col">
                                <div className="imageCard1">                               
                                    <div className="imageCard1_fig">                            
                                        <img src={therepyImg24} className="therepyImg24" alt="" />   
                                    </div>
                                    <div className="imageCard1_cont text-center">                            
                                        <p>Dissolve any stress you may be carrying in your face…</p>
                                    </div>                                
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3 imageCard_col">
                                <div className="imageCard1">                               
                                    <div className="imageCard1_fig">                            
                                        <img src={therepyImg25} className="therepyImg25" alt="" />   
                                    </div>
                                    <div className="imageCard1_cont text-center">                            
                                        <p>Improve circulation by boosting blood flow giving you a healthy glow to your skin…</p>
                                    </div>                                
                                </div>
                            </div>      
                            <div className="col-lg-6 col-xl-3 imageCard_col">
                                <div className="imageCard1">                               
                                    <div className="imageCard1_fig">                            
                                        <img src={therepyImg26} className="therepyImg26" alt="" />   
                                    </div>
                                    <div className="imageCard1_cont text-center">                            
                                        <p>And enhance your mood with the natural release of endorphins.</p>
                                    </div>                                
                                </div>
                            </div>                   
                        </div>
                        <p><strong>Once you begin your first facial massage you’ll notice the difference immediately…</strong></p>
                        <p>Because after just your first massage using Lily’s technique, you’ll feel an instant sense of calmness, relaxation, and clarity while your skin begins to feel softer and smoother…</p>
                        <p>Then after the second week your skin's elasticity will feel “bouncy” and “springier” because of enhanced collagen production your body is now naturally releasing..</p>  

                        <div className="row">
                            <div className="col-md-4 col-lg-5 order-md-2 mb-4">
                                <img src={therepyImg27} className="therepyImg27 mx-auto d-block" alt="" />   
                            </div>
                            <div className="col-md-8 col-lg-7"> 
                                <div className='upsellCont'>  
                                    <h2 className="sk6_title3"><span className="txt_pink"></span></h2>                                   
                                    <p>And after the first 30 days your skin will look and feel firmer, more sculpted with fewer wrinkles…</p>
                                    <p>…and you’ll have a new sense of self-worth and self confidence so you can experience get-togethers with friends and family knowing that you look as good on the outside, that you’re feeling on the inside.</p>
                                    <p>Age Brilliance Therapy is always ready to help you and guide you in an easy-to-follow, step by step video tutorial…</p>
                                    <p><strong>…that’s because it was designed so that a woman of any age, and any skin type, can easily master these amazing facial massage videos…</strong></p>
                                </div> 
                            </div>                            
                        </div>

                        <div className='upsellCont contentCard '>                                                                
                            <p>And best of all, Age Brilliance Therapy is always “on” because it’s:</p>
                            <ul className='textList_1'>
                                <li>Accessible on your phone or computer – so you can watch it “as a guide” at the same time you’re doing the massage…</li>
                                <li>Easy to follow along – because you can rewind, pause, and watch it as many times as is needed to master the facial massage…</li>
                                <li>Designed for women aged 30 to 80 – making it simple for anyone to do…</li>
                                <li>And it's made so that any woman can truly afford it – because we’ve dramatically reduced the cost so that you can take advantage of the incredible health and beauty benefits of it today!</li>
                            </ul>
                        </div> 
                    </div>
                </div>



                <div className="thereapy1_sec13 py-4">
                    <div className="cont_sec upsellCont">
                        <h2 className="sk6_title3 text-center">Our Goal Is To Create 1,000 Beauty Transformations…
                            <br /><span class="txt_pink">To Restore Your Skin To A Youthful Glow By…</span>
                        </h2>   
                        <p>So instead of worrying about “feeling your age”… or if your complexion shows a worn-out and tired look next time you leave the house to meet up to visit friends or family…</p>
                        <p>…don’t you deserve to feel beautiful and confident? Don’t you deserve to feel and look younger?</p>
                        <p>Here’s how you can trade in the tiredness, the tension, and the stress and instead experience feeling refreshed, relaxed and invigorated…</p>
                        <div className="contentCard">
                            <h2 className="sk6_title3  text-center">Age Brilliance Therapy, The ONLY Facial Massage Designed to Overcome The Signs Of Aging While <br /> <span className="txt_pink">Restoring Your Body Back To Its Natural Healthy State</span></h2>                                   
                            <p>AgeBrilliance Therapy will show you, in exact detail and step-by-step, how to do Lilly’s special facial massages…</p>
                            <p>…designed so you can go at your own pace.</p>
                            <p>And as you easily master these facial movements...</p>
                            <p>…you’ll have the power in your own hands and fingers to:</p>   
                            <div className="row imageCard_row mb-3 mb-md-5">
                                <div className="col-md-4 imageCard_col">
                                    <div className="imageCard1">                               
                                        <div className="imageCard1_fig">                            
                                            <img src={therepyImg28} className="therepyImg28" alt="" />   
                                        </div>
                                        <div className="imageCard1_cont text-center">                            
                                            <p>Reduce Cortisol levels for an immediate sense of peacefulness…</p>
                                        </div>                                
                                    </div>
                                </div>
                                <div className="col-md-4 imageCard_col">
                                    <div className="imageCard1">                               
                                        <div className="imageCard1_fig">                            
                                            <img src={therepyImg29} className="therepyImg29" alt="" />   
                                        </div>
                                        <div className="imageCard1_cont text-center">                            
                                            <p>Firm-up your skin…</p>
                                        </div>                                
                                    </div>
                                </div>
                                <div className="col-md-4 imageCard_col">
                                    <div className="imageCard1">                               
                                        <div className="imageCard1_fig">                            
                                            <img src={therepyImg30} className="therepyImg30" alt="" />   
                                        </div>
                                        <div className="imageCard1_cont text-center">                            
                                            <p>And Reshape your face to a new and beautiful contoured look.</p>
                                        </div>                                
                                    </div>
                                </div>                      
                            </div>       
                            <p><strong>And you can start watching in the next 2 minutes!</strong></p>
                            <p>It took Lilly a “lifetime”to perfect these facial massages…</p>
                            <p>…but, it'll only take you "minutes" to master!</p>
                            <p>Simply complete the 5 minute facial massage during your morning or evening routine…</p>
                            <p>…and then you’ll experience and feel the “radiance effect” taking place on your face and neck area…</p>   
                            <p>…instantly feeling the firmness begin to shape your skin and the wrinkles start to fade…</p>        
                        </div>
                    </div>
                </div>


                <div className="thereapy1_sec14">
                    <div className="cont_sec upsellCont">
                        <p>Below are the instructional facial massage videos you’ll have once you begin Age Brilliance Therapy…so you can focus on the specific area and region of your face and neck that need the most attention today.</p>
                        <div className="row s6TextCard2-row">
                            <div className="col-md-6 s6TextCard2-col">
                                <div className='s6TextCard2'>
                                    <div className='s6TextCard2-hdr'>
                                        <h2>Facial Massage Video 1</h2>
                                        <h2 className='txt_pink'>The Rapid Radiance Ultimate Face Massage</h2>
                                    </div>
                                    <div className='s6TextCard2-body'>                                        
                                        <div className='s6TextCard2-fig'>
                                            <img src={therepyImg31} className="therepyImg31" alt="" /> 
                                        </div>
                                        <div className='s6TextCard2-cont'>
                                            <p>The Ultimate 5-Minute Face Massage. Get a revitalized complexion with this full-facial massage! This massage targets lymphatic drainage, releases toxins and reduces cortisol levels to boost circulation. Witness the magic of youthful radiance as you tone, lift, and refresh your skin in just 5 minutes…</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 s6TextCard2-col">
                                <div className='s6TextCard2'>
                                    <div className='s6TextCard2-hdr'>
                                        <h2>Facial Massage Video 2</h2>
                                        <h2 className='txt_pink'>Sculpted Jawline: Jowl-Firming Massage</h2>
                                    </div>
                                    <div className='s6TextCard2-body'>                                        
                                        <div className='s6TextCard2-fig'>
                                            <img src={therepyImg32} className="therepyImg32" alt="" /> 
                                        </div>
                                        <div className='s6TextCard2-cont'>
                                            <p>Redefine and contour your profile. This advanced massage is specifically designed to firm up the skin below the jawline creating a more sculpted, youthful appearance. Unleash the potential of a refined silhouette as you celebrate a lifted, invigorated profile. Radiate self-assurance with every glance!</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 s6TextCard2-col">
                                <div className='s6TextCard2'>
                                    <div className='s6TextCard2-hdr'>
                                        <h2>Facial Massage Video 3</h2>
                                        <h2 className='txt_pink'>Double Chin Reduction Massage</h2>
                                    </div>
                                    <div className='s6TextCard2-body'>                                        
                                        <div className='s6TextCard2-fig'>
                                            <img src={therepyImg33} className="therepyImg33" alt="" /> 
                                        </div>
                                        <div className='s6TextCard2-cont'>
                                            <p>Sculpt your perfect profile with this amazing massage! Easily master this massage designed to eliminate fat and reshape the chin area. See the power of rejuvenation as you tone and tighten sagging skin, unveiling a more defined jawline.</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 s6TextCard2-col">
                                <div className='s6TextCard2'>
                                    <div className='s6TextCard2-hdr'>
                                        <h2>Facial Massage Video 4</h2>
                                        <h2 className='txt_pink'>Droopy Mouth Corner Lifting Massage</h2>
                                    </div>
                                    <div className='s6TextCard2-body'>                                        
                                        <div className='s6TextCard2-fig'>
                                            <img src={therepyImg34} className="therepyImg34" alt="" /> 
                                        </div>
                                        <div className='s6TextCard2-cont'>
                                            <p>Bring back your radiant smile with this massage! Easily master this massage to counteract drooping corners of the mouth. Experience the joy of a rejuvenated, captivating grin as you restore firmness and lift sagging skin!</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 s6TextCard2-col">
                                <div className='s6TextCard2'>
                                    <div className='s6TextCard2-hdr'>
                                        <h2>Facial Massage Video 5</h2>
                                        <h2 className='txt_pink'>Forehead & Frown Lines Reduction Massage</h2>
                                    </div>
                                    <div className='s6TextCard2-body'>                                        
                                        <div className='s6TextCard2-fig'>
                                            <img src={therepyImg35} className="therepyImg35" alt="" /> 
                                        </div>
                                        <div className='s6TextCard2-cont'>
                                            <p>Smooth-out stubborn forehead wrinkles and soften deep-set frown lines around the mouth. Say goodbye to aging lines and hello to a revitalized, confident you!</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 s6TextCard2-col">
                                <div className='s6TextCard2'>
                                    <div className='s6TextCard2-hdr'>
                                        <h2>Facial Massage Video 6</h2>
                                        <h2 className='txt_pink'>Ageless Smile: Wrinkle-Erasing Massage</h2>
                                    </div>
                                    <div className='s6TextCard2-body'>                                        
                                        <div className='s6TextCard2-fig'>
                                            <img src={therepyImg36} className="therepyImg36" alt="" /> 
                                        </div>
                                        <div className='s6TextCard2-cont'>
                                            <p>Illuminate your smile with our transformative video! Follow along and do these massages that focus on erasing wrinkles around the mouth. Rediscover the joy of a youthful, captivating grin as you soften lines and enhance suppleness. Embrace your ageless smile and let your radiant confidence shine through!</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 s6TextCard2-col">
                                <div className='s6TextCard2'>
                                    <div className='s6TextCard2-hdr'>
                                        <h2>Facial Massage Video 7</h2>
                                        <h2 className='txt_pink'>Softening Marionette & Smile Lines Massage</h2>
                                    </div>
                                    <div className='s6TextCard2-body'>                                        
                                        <div className='s6TextCard2-fig'>
                                            <img src={therepyImg37} className="therepyImg32" alt="" /> 
                                        </div>
                                        <div className='s6TextCard2-cont'>
                                            <p>Embrace Ageless Beauty with this massage! Delve into these targeted massage techniques to stimulate collagen, firm up the face, and sculpt your features. Elevate your beauty routine and radiate with timeless elegance!</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 s6TextCard2-col">
                                <div className='s6TextCard2'>
                                    <div className='s6TextCard2-hdr'>
                                        <h2>Facial Massage Video 8</h2>
                                        <h2 className='txt_pink'>Eye Refresh & De-Puffing Massage</h2>
                                    </div>
                                    <div className='s6TextCard2-body'>                                        
                                        <div className='s6TextCard2-fig'>
                                            <img src={therepyImg38} className="therepyImg32" alt="" /> 
                                        </div>
                                        <div className='s6TextCard2-cont'>
                                            <p>Illuminate your smile with our transformative video! Follow along and do these massages that focus on erasing wrinkles around the mouth. Rediscover the joy of a youthful, captivating grin as you soften lines and enhance suppleness. Embrace your ageless smile and let your radiant confidence shine through!</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 s6TextCard2-col">
                                <div className='s6TextCard2'>
                                    <div className='s6TextCard2-hdr'>
                                        <h2>Facial Massage Video 9</h2>
                                        <h2 className='txt_pink'>Soothing Head, Face & Shoulders Massage</h2>
                                    </div>
                                    <div className='s6TextCard2-body'>                                        
                                        <div className='s6TextCard2-fig'>
                                            <img src={therepyImg39} className="therepyImg32" alt="" /> 
                                        </div>
                                        <div className='s6TextCard2-cont'>
                                            <p>Indulge in the ultimate relaxation experience with this luxurious massage... Soothe tension in the head, face, and shoulders while promoting circulation and stimulating hair growth. Unwind and immerse yourself in a blissful journey to rejuvenation, revitalizing both body and spirit. Radiate with newfound serenity and lush locks!</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 s6TextCard2-col">
                                <div className='s6TextCard2'>
                                    <div className='s6TextCard2-hdr'>
                                        <h2>Facial Massage Video 10</h2>
                                        <h2 className='txt_pink'>Eye Lift & Dark Circle Reduction Massage</h2>
                                    </div>
                                    <div className='s6TextCard2-body'>                                        
                                        <div className='s6TextCard2-fig'>
                                            <img src={therepyImg40} className="therepyImg32" alt="" /> 
                                        </div>
                                        <div className='s6TextCard2-cont'>
                                            <p>Reawaken your eyes with a rejuvenating boost, restoring brightness and youthfulness. Revive tired eyes and restore firmness.Master this specialized massage tailored to combat droopy eyelids, diminish dark circles and enhance your natural beauty.</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                           
                                                       
                        </div>
                    </div>
                </div>



                <div className="thereapy1_sec15">
                    <div className="cont_sec upsellCont">
                        <div className='contentCard2'>
                            <div className="row align-items-center">                                
                                <div className="col-md-8 col-lg-10"> 
                                    <div className='upsellCont'>  
                                        <h2 className="sk6_title3"><span className="txt_pink">Age Brilliance Premium Facial Massage Oil</span></h2>                                   
                                        <p><strong>Nourishment in every drop, tailored for the ultimate facial massage experience</strong></p>
                                        <p><strong>And to elevate your facial massage experience even further, we're including our premium facial massage serum with your order...</strong></p>
                                        <p><strong>The perfect companion for every stroke and movement you'll now have is our Age Brilliance Facial Massage Oil.</strong></p>
                                        <p><strong>Expertly blended from a potent fusion of oils – Rosehip, Castor, Sweet Almond, Argan Kernel, and Avocado – each drop is a symphony of rejuvenation. Enriched with the antioxidant power of Vitamins C and E and the soothing touch of Aloe extract, this serum is designed to revitalize tired skin and elevate your massage ritual to a lavish indulgence.</strong></p>
                                    </div> 
                                </div>     
                                <div className="col-md-4 col-lg-2 mt-4">
                                    <img src={therepyImg41} className="therepyImg41" alt="" />   
                                </div>                       
                            </div>
                        </div>
                    </div>
                </div>


                <div className="thereapy1_sec16">
                    <div className="cont_sec upsellCont">
                        <p><strong className='yellowStrip1'><em>...and I have a delightful surprise for you…</em></strong></p>
                        <p><strong><em>...offering even more tips, techniques, and indulgent experiences!</em></strong></p>
                        <p><strong><em>Your journey continues…</em></strong></p>
                        <div className='contentCard'>
                            <h2 className="sk6_title3 text-center mb-4">And Enjoy These<span className="txt_pink">Free Gifts </span>...</h2> 
                            <div className="row mb-4">
                                <div className="col-md-6 s6TextCard2-col">
                                    <div className='s6TextCard2'>
                                        <div className='s6TextCard2-hdr'>
                                            <h2>Free Bonus Gift Video 1</h2>
                                            <h2 className='txt_pink'>Luscious Lips Plumping Massage</h2>
                                        </div>
                                        <div className='s6TextCard2-body'>                                        
                                            <div className='s6TextCard2-fig'>
                                                <img src={therepyImg42} className="therepyImg42" alt="" /> 
                                            </div>
                                            <div className='s6TextCard2-cont'>
                                                <p>Discover the secret to irresistible lips! Quickly master this massage technique that naturally plumps and volumizes your lips without injections. Unveil the secret to fuller, smoother, and irresistibly luscious lips that exude confidence and allure. Let your captivating, kissable smile take center stage!</p>
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 s6TextCard2-col">
                                    <div className='s6TextCard2'>
                                        <div className='s6TextCard2-hdr'>
                                            <h2>Free Bonus Gift Video 2</h2>
                                            <h2 className='txt_pink'>Silent Serenity: Tinnitus & TMJ Relief Massage</h2>
                                        </div>
                                        <div className='s6TextCard2-body'>                                        
                                            <div className='s6TextCard2-fig'>
                                                <img src={therepyImg43} className="therepyImg43" alt="" /> 
                                            </div>
                                            <div className='s6TextCard2-cont'>
                                                <p>Find Lasting Relief: Conquer Tinnitus & Ear Ringing with this Life-Changing Massage for Peaceful Ears…Master these targeted massage techniques designed to alleviate ringing in the ears. Experience the bliss of silence as you discover inner peace and serenity.</p>
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 s6TextCard2-col">
                                    <div className='s6TextCard2'>
                                        <div className='s6TextCard2-hdr'>
                                            <h2>Free Gift Bonus #1:</h2>
                                            <h2 className='txt_pink'>The 5 Day Quick Start Guide</h2>
                                        </div>
                                        <div className='s6TextCard2-body'>                                        
                                            <div className='s6TextCard2-fig'>
                                                <img src={therepyImg44} className="therepyImg44" alt="" /> 
                                            </div>
                                            <div className='s6TextCard2-cont'>
                                                <p>It's like having your own personal coach showing you what to do and how to do it. It means you can target stubborn areas smarter...and optimize your facial massage without sacrificing time. </p>
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 s6TextCard2-col">
                                    <div className='s6TextCard2'>
                                        <div className='s6TextCard2-hdr'>
                                            <h2>Free Gift  Bonus #2</h2>
                                            <h2 className='txt_pink'>Mastering Facial Massage eBook</h2>
                                        </div>
                                        <div className='s6TextCard2-body'>                                        
                                            <div className='s6TextCard2-fig'>
                                                <img src={therepyImg45} className="therepyImg45" alt="" /> 
                                            </div>
                                            <div className='s6TextCard2-cont'>
                                                <p>This companion eBook discusses the Anatomy of the Face & Neck and the preparation of facial massage…Understand how targeted facial massage goes to the root cause of wrinkles and learn how consistency in these targeted massages can lead to years of youthfulness.</p>
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>                            
                            </div> 
                            <p><strong>So let’s talk about the price of being beautiful…</strong></p>
                            <p>…Every year women spend on average over $3,750 on their looks… and as they get older, more of that goes towards skincare products and makeup.</p>
                            <p>And all of these things are great, but they don’t address the root issue which is toxin buildup in the layers of the skin – and that’s why all of those things just “cover up” the imperfections, hiding them…</p>
                            <p>…Age Brilliance Therapy removes the imperfections and restores the natural radiance and vitality of the skin while also lowering stress-induced cortisol levels.</p>
                        </div>
                        
                    </div>
                </div>

                <div className="thereapy1_sec17 py-4">
                    <div className="cont_sec upsellCont">
                        <div className="row">
                            <div className="col-md-4 col-lg-5 order-md-2 mb-4">
                                <img src={therepyImg46} className="therepyImg46" alt="" />   
                            </div>
                            <div className="col-md-8 col-lg-7"> 
                                <div className='upsellCont'>                                                                    
                                    <p>So, here’s the thing…</p>
                                    <p>Even though it can cost thousands of dollars to be beautiful, we’re not going to ask you for that today.</p>
                                    <p>We’re not even going to ask you for a fraction of that….</p>
                                    <p>It’s only $149.</p>
                                    <p>If you were to access each of these instructional videos separately, the normal pricing for each would be $27, which would mean a total of $324…</p>
                                    <p>Which is a great price when you think about how it will improve your health, skin, and optimize your anti-aging products…</p>
                                    <p>…but you’re not going to pay anything close to that.</p>
                                </div> 
                            </div>                            
                        </div>
                        <p>Because when we decided to offer these Age Brilliance Therapy videos, we wanted you to have every resource at your fingertips to get the beauty transformation you deserve…</p>
                        <p>And since these videos are available online instantly, in the Age Brilliance Membership area, we save on shipping and postage costs… and so we initially decided to sell them for half that price for $162…</p>
                        <p>…Which was great, but we were certain this would <strong>help your beauty transformation happen faster</strong> and lead to more women seeing another success story, which is what’s most important to us…</p>
                        <p><strong>…So by lowering the price to only $149 it allows us to impact more women than ever, and help them get the confidence and beauty they truly deserve and desire.</strong></p>
                        
                        <div className='priceCard-block mb-4'>
                            <div className='row align-items-center'>
                                <div className='col-md-8'>
                                    <img src={therepyImg47} className="therepyImg47 d-none d-md-block" alt="" /> 
                                    <img src={therepyImg47_mob} className="therepyImg47_mob mx-auto d-block d-md-none mb-4" alt="" />  
                                </div>
                                <div className='col-md-4'>
                                    <div className="proCard w-100"> 
                                        <div className="proCard_body">    
                                            <div className="proCard_pricePer">                               
                                                <p className='proCard_price'>$147</p>
                                            </div>                           
                                            <div className="save_price">                               
                                                SAVE <br /> <span className='saved'>$50.00</span>			  
                                            </div> 
                                            <div className='product_prices'>
                                                <p className='reg_price'>REGULARLY <span className='pr'>$89.00</span></p>
                                            </div>
                                            <div className='product_deal'>
                                                <div className='img_box'>
                                                    <img src={truck} className='' />
                                                    <span className='deal_txt'>FREE SHIPPING</span>
                                                </div>
                                                <div className='img_box'>
                                                    <img src={badge} className='' />
                                                    <span className='deal_txt'>180 DAY MONEY BACK GUARANTEE</span>
                                                </div>  
                                            </div>
                                            <button className='btn btn-primary btn-one-style btn-icon cart_btn'>Add to Cart</button>
                                            <img src={cardImg} className='card_img mx-auto d-block' alt='card_img' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <p className='text-center upsellSkip'><a href='#'><strong><u>No thank you. I’ll pass on this huge savings now and realize I will never have this opportunity again.</u></strong></a></p>   
                        <p></p>
                    </div>
                </div>


                <div className="thereapy1_sec18 py-4">
                    <div className="cont_sec">
                        <h2 className="sk6_title3 text-center">Frequently Asked Question</h2> 
                        <div className='accordianWrapper'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>I want this, what exactly am I getting?</Accordion.Header>
                                <Accordion.Body>
                                    <p>You're getting Lilly’s AgeBrilliance Facial Massage Instructional Video Series, available instantly. We wanted to make it so good that you’d feel silly by not getting it if reducing facial lines, firming up your neck area or having healthy lymphatic drainage is of any interest to you. You're getting the full breakdown by video, and the step-by-step process to completing daily facial massages that will help reduce fine lines and firm up your facial skin while also preparing it properly before you apply any creams or serums..</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Who is this for?</Accordion.Header>
                                <Accordion.Body>
                                    <p>This is for women that are searching for ways to fight back against the signs of aging without resorting to expensive creams or surgery. This video series will help women remove facial wrinkles naturally and helps support a healthy Lymphatic System, important for women of any age to make part of their daily routine.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>What is Lily's AgeBrilliance Instructional Facial Massage Video Series?</Accordion.Header>
                                <Accordion.Body>
                                    <p>AgeBrilliance Therapy is a Digital Facial Massage Course that shows you how to properly massage areas of your face that helps reduce wrinkles, firm up the skin, stimulate collagen and help drain the Lymphatic System so creams and serums can work to their full potential. This video series expertly walks you through each facial massage with a “step-by-step” process that any woman can follow along to. With “Lifetime Access” you can watch, re-watch, pause… as many times as necessary as you complete these easy to follow video tutorial exercises.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Do I need any special equipment when I do these facial massages?</Accordion.Header>
                                <Accordion.Body>
                                    <p>No, all you need is a computer, laptop or a phone, no other equipment is needed.</p>                                    
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>How do I access the Instructional Videos?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Your instructional videos will be available inside the member’s area immediately after payment. You’ll be able to complete your first routine within minutes!</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>How hard is it to do Lily’s AgeBrilliance Facial Massages?</Accordion.Header>
                                <Accordion.Body>
                                    <p>It’s not hard at all because we’ve designed this course specifically for women of any age to easily follow along and complete in the privacy of their own home.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Is there a guarantee?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, you get a 180 day money back guarantee in case Lymphatic drainage, collagen stimulation, wrinkle reduction or skin firming is not for you.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>            
                    </div>
                    </div>
                </div>


            </main>
        </>
    )
}


export default Skincare6pTherepy1;