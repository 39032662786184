import React, {useCallback, useState, useEffect } from 'react';
import FooterV2 from './FooterV2'
import NavbarV2 from './NavbarV2'
import '../../assests/admin/css/styles.css'
import '../../assests/admin/js/scripts'
import { Outlet, useNavigate } from 'react-router-dom'
import { useNavbar } from '../../context/Context';
import ShopSidebar from './ShopSidebar';
import axios from "axios";

function MasterLayoutV2() {
    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    // const result = routes.map((el) => el)
    const [isOpenNavbar, setIsOpenNavbar] = useState(false);
    const { navbarState: { activeTab }, navbarDispatch } = useNavbar();
    let URL = window.location.href;
    let URLparam = decodeURIComponent(URL.substring(URL.lastIndexOf('/') + 1));

    function addClassForMobileMenu(value){
        setShow(!value);
    }


    return (
        <>
            <div className='sb-nav-fixed'>
                <NavbarV2 valuefornav={activeTab}/>
                <div id="">
                    <div id="layoutSidenav_content">
                        {/* <ShopSidebar/> */}
                        <Outlet />
                        <FooterV2 /> 
                    </div>
                </div>
            </div>
        </>
    )
}

export default MasterLayoutV2