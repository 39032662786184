import React, { useCallback } from 'react'
import wholeSaleLogo from '../../assests/frontend/assets/images/Wholesale_Health_Club_Logo.png';
import searchIcon from '../../assests/frontend/assets/images/search.png';
import cartIcon from '../../assests/frontend/assets/images/bag.png';
import Swal from 'sweetalert2'
import { Link, useLocation  } from 'react-router-dom'
import useStore from '../../store';
import { useState, useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap'
import { Stack } from '@mui/system';
import { CartState, useNavbar, useSidebar } from '../../context/Context';
import {BASE_URL} from '../../components/Config';
import axios from 'axios';
import Bottleneck from 'bottleneck';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

/*images*/
import serchIcon from '../../assests/frontend/assets/images/search-icon-white.png';
import prod1 from '../../assests/frontend/assets/images/Complete-multivitamin-1.png';
import prod2 from '../../assests/frontend/assets/images/HL-Immuboost.png';
import prod3 from '../../assests/frontend/assets/images/TB-GoImmunitySupport.png';
import close from '../../assests/frontend/assets/images/close-white.png';
import { result } from 'lodash';

// Images V2
import mainLogo from '../../assests/new/frontend/images/ghc-logo.png';
import homeIcon from '../../assests/new/frontend/images/home-icon.png';
import shopBag from '../../assests/new/frontend/images/shop-bag.png';
import search from '../../assests/new/frontend/images/search_hires.png';


function NavbarV2({ onClick,valuefornav }) {
    const afterLogout = useStore((state) => state.afterLogout)
    const cartCount = useStore((state) => state.cartCount)
    const cartProducts = useStore((state) => state.cartProductId)
    const isLogin = useStore((state) => state.isLogin);
    var d = (localStorage.getItem("useData"));
    const [cartCountNo, setCartCountNo] = useState(0)
    let URL = window.location.href;
    let URLparam = URL.substring(URL.lastIndexOf('/') + 1);
    //let urlSlug = URL.substring(URL.lastIndexOf('/') + 0);
    //let urlSlug2 =URL.split('/');
    let imgURL = BASE_URL+"whc-custom/storage/app/public/";

    const logoutSubmit = e => {
        e.preventDefault();
        toggleNav();
        sessionStorage.removeItem('yp');
        localStorage.removeItem('method');
        localStorage.removeItem('userEmal');
        localStorage.removeItem('userId');

        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('phone');
        localStorage.removeItem('email');
        localStorage.removeItem('shippingAddress1');
        localStorage.removeItem('shippingCity');
        localStorage.removeItem('shippingState');
        localStorage.removeItem('shippingZip');
        localStorage.removeItem('order_id');

        if(localStorage.getItem('loggedInAs') === 'admin'){
            //console.log(localStorage.getItem('loggedInAs'));
        } else {
            localStorage.removeItem('authToken');
            localStorage.removeItem('bearer');
            localStorage.removeItem('permission');
            localStorage.removeItem('useData');
            afterLogout()
        }
        Swal.fire({
            title: 'Logout!',
            html: 'Logout Successful',
            icon: 'success'
        })
        window.location.href = '/login'
    }
    const [isOpen, setIsOpen] = useState(false)
    const [total, setTotal] = useState()
    const [isOpenSidebar, setIsOpenSidebar] = useState(false);
    //const [isOpenNavbar, setIsOpenNavbar] = useState(false);

    const { state: { cart }, dispatch } = CartState();
    const { sidebarState: { sidebarOpen }, sidebarDispatch } = useSidebar();
    const { navbarState: { activeTab }, navbarDispatch } = useNavbar();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const [category, setCategoryList] = useState([]);
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [results, setResults] = useState([]);
    const [isDisplay, setIsDisplay] = useState(false);
    const [loadershow, setloadershow] = useState(false);
    const [showDiv, setShowDiv] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);

    useEffect(() => {
        if(cart.length>=0 && isLogin)
        {
            if(localStorage.getItem('userId')){
                //Update/Insert Cart Items to the DB
                axios.post(`api/update-cart`, {user_id:localStorage.getItem('userId'),items:cart}).then(res => {});
                // console.log(JSON.parse(d).state.userId);
            }
        }
        setTotal(cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0))

    }, [cart]);

    const handleButtonClick = () => {
        navbarDispatch({ type: "CHANGE_TAB" })
        if (sidebarOpen === true) {
            sidebarDispatch({ type: 'TOGGLE_SIDEBAR' });
        }
    }

    const handleClickSidebar = () => {
        sidebarDispatch({ type: 'TOGGLE_SIDEBAR' });
        if (activeTab === true) {
            navbarDispatch({ type: "CHANGE_TAB" })
        }
    };

    const toggleSubMenu = () => {
        setSubMenuOpen(!subMenuOpen);
    }

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
        /*if (activeTab === false) {
            navbarDispatch({ type: "CHANGE_TAB" })

        }

        if (sidebarOpen) {
            sidebarDispatch({ type: "TOGGLE_SIDEBAR" })

        }*/
    }

    async function getCategoryList() {
        axios.get(`api/frontEndListCategory`).then(res => {
            setCategoryList(res.data.data);
        })
    }

    useEffect(() => {
        if(isMobile) {
            getCategoryList();
        }
    }, []);

    /* Code for global search */

    const limiter = new Bottleneck({
        maxConcurrent: 1, // limit the number of concurrent requests to 1
        minTime: 2000 // wait for 2 second between each request
    });

    const handleKeyUp = async (event) => {
        try {
            let value = event.target.value;
            if(value != ''){
                if(value.length > 3){
                    setloadershow(true);
                    const response = await limiter.schedule(() =>
                        axios.post(`api/search`,{data : value})
                    );
                    if(response.data.status === true){
                        setloadershow(false);
                        setResults(response.data.data);
                    } else {
                        setloadershow(false);
                        setShowDiv(true);
                    }
                }
            } else {
                setResults('');
                setShowDiv(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // do something with the form data
    };

    const handleClose = () => {
        setResults('');
        setIsDisplay(false)
    }

    return (
        <>
           {/* Header Begins  */}
           <header className="nwHeader">
                {/* Top  */}
                <div className="nwHdr-top">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-8 logo_col">
                                <Link className="mwLogo" to="/"><img src={mainLogo} className="img-fluid" alt="" /></Link>
                            </div>
                            <div className="col mobSearchCol d-none">
                                <button className="mob-serchBtn" ><img src={search} className="img-fluid" alt="" /></button>
                            </div>
                            <div className="col-md-6 col-4 deskSearch_col">
                                {/* <form className="nwSearch ms-auto"> */}
                                    {/* <input type="text" placeholder="Search..." className="form-control" /> */}
                                    <button className="serchBtn" onClick={() => setIsDisplay(true)}><img src={search} className="img-fluid" alt="" /></button>
                                {/* </form> */}
                            </div>
                            {/* Global Search HTML */}
            
                            {isDisplay === true ?
                            
                            <div className="FrontSearchWrapper">
                                <div className={loadershow ? 'loading' : 'd-none'}></div>                
                                <div className="container searchCont">      
                                    <div className="row">
                                        <div className="col-lg-11 mx-auto">
                                            <button className="close" onClick={() => handleClose()}><img src={close} className="crossIcon" /></button>
                                            <div className="searchHeader">                
                                                <h2>Search</h2>
                                            </div>
                                            <div className="searchField">                
                                                <form className="serachForm" onSubmit={handleSubmit}>
                                                    <input type="text" autoFocus placeholder="Type Here..." className="form-control" name="search" onKeyUp={handleKeyUp} autoComplete="off"/>
                                                    <button className="serchBtn" type="submit"><img src={serchIcon} className="icon" /></button>
                                                </form>
                                            </div>
                                            <div className="searchResults">                
                                                <div className="searchResultsInner">    
                                                    <div className="row">
                                                        {results.length ? (
                                                            results.map((item) => (
                                                                <div className="col-md-4">                
                                                                    <Link to={'/product/'+item.id} className="searchRes-div" onClick={() => handleClose()}>                
                                                                        <span className="serchPro-img"><img src={imgURL+item.image} className="searchProd" /></span>
                                                                        <span className="serchPro-name">{item.name}</span>
                                                                    </Link>
                                                                </div>
                                                            ))
                                                        ) : (showDiv === true) ? (
                                                            <p className="no-data">No product found</p>
                                                        ) : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                            </div>
                            : ''}
                        </div>
                    </div>                    
                </div>               
            </header>
                {/* Main Header  */}
                <div className="nwHdr-main">
                    <div className="container-fluid">
                        <Navbar collapseOnSelect expand="md">
                            <div className="navHome"><Link className="hdrBlue-link" to="/"><img src={homeIcon} className="img-fluid" alt="" /></Link></div>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav"onClick={toggleNav}/>
                            <Navbar.Collapse id="responsive-navbar-nav" in={isNavOpen} >                            
                                <Nav>
                                    <Link to="/shop/supplements" onClick={toggleNav}>Shop</Link>
                                    <Link to="/create-membership" onClick={toggleNav}>MEMBERSHIP PLANS</Link>
                                    {(isLogin) ?
                                        <Link to="/my-account-info" onClick={toggleNav}>MY ACCOUNT</Link>
                                    :
                                        <Link to="/login" onClick={toggleNav}>MY ACCOUNT</Link>
                                    }
                                    {isLogin == 1 ? 
                                        <Link to="#" onClick={logoutSubmit}>LOGOUT</Link>
                                    :<Link to="/login" onClick={toggleNav}>LOGIN</Link>}
                                </Nav>
                            </Navbar.Collapse>
                            <div className="navCart-div">
                                <div onClick={() => setIsOpen(true)}>
                                    <Link className="navCart hdrBlue-link" to="#">
                                        <img src={shopBag} className="img-fluid" alt="" />
                                        <span className='cartCount'>{isLogin == 1 ? cart.length : 0}</span>
                                    </Link>
                                </div>
                                <Offcanvas show={isOpen} placement="end" onHide={() => setIsOpen(false)}>
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title>
                                            Cart
                                        </Offcanvas.Title>

                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Stack gap={3}>
                                            {cart.length > 0 ? (
                                                <>
                                                    {cart.map(function (item) {
                                                        return (
                                                            < Stack gap={2} direction="horizontal" className="cartProList d-flex aling-item-center" key={item.id} >
                                                                <div className='cartPro-left'>
                                                                    <div className="proImage">
                                                                        <img src={BASE_URL+'whc-custom/storage/app/public/' + item.image} style={{ width: "", height: "", objectFit: "cover" }} ></img>
                                                                    </div>
                                                                    <div className='me-auto'>
                                                                        <div className='cartPro-name'>{item.name} {" "} </div>
                                                                        <div className='text-muted' style={{ frontSize: ".60rem" }}><span>QTY: <input type="number" min={1} className='form-control' defaultValue={item.qty} style={{
                                                                            width: " 60px",
                                                                            display: 'inline-block'
                                                                        }} onChange={e => {
                                                                            dispatch({
                                                                                type: "CHANGE_QTY",
                                                                                payload: {
                                                                                    id: item.id,
                                                                                    qty: e.target.value
                                                                                },
                                                                            })
                                                                        }} />  </span>     <strong> <span className='d-inline-block ps-2'> {'$' + parseFloat(item.price).toFixed(2) * item.qty}</span></strong></div>
                                                                    </div>
                                                                </div>
                                                                <div className="btn-close" onClick={() => {
                                                                    dispatch({
                                                                        type: "REMOVE_FROM_CART",
                                                                        payload: item
                                                                    })
                                                                }}> </div>
                                                            </Stack>
                                                        )
                                                    })}
                                                    <div className='cartSubTotal ms-auto fw-bold'><span className='sTitle'>SUBTOTAL:</span> <span className='total'> {'$' + parseFloat(total).toFixed(2)}</span></div>
                                                    <div className='cartBtn-list'>
                                                        <Link className='btn ' to="cart" onClick={() => setIsOpen(!isOpen)}>Cart</Link>
                                                        <Link className='btn btn_solid' to="checkout-2" onClick={() => setIsOpen(!isOpen)}>Checkout</Link>
                                                    </div>
                                                </>
                                            ) : (<> <Stack className="emptyCart"> Cart is empty!</Stack>    </>)}
                                        </Stack>
                                    </Offcanvas.Body>
                                </Offcanvas>
                                {/* <div className='cartWrapper' onClick={() => setIsOpen(true)}>
                                    <Link to='#'>
                                        <img src={cartIcon} className="cartIcon" />
                                        <span className='cartCount'>{isLogin == 1 ? cart.length : 0}</span>
                                    </Link>
                                </div> */}
                            </div>
                        </Navbar>
                    </div>                    
                </div>
            
            {/* Header End  */}
        </>
    )
}

export default NavbarV2