import DickPillDesktop from './DickPillDesktop';
import DickPillMobile from './DickPillMobile';



function DickPillHome(){

    function getDeviceType() {
        const width = window.innerWidth;
        const height = window.innerHeight;
    
        // Define breakpoints for different device types
        // const isMobile = width <= 767;  // Mobile devices
        // const isTablet = width > 767 && width <= 1024; // Tablets
        // const isDesktop = width > 1024; // Desktops

        const isMobile = width <= 700;  // Mobile devices
        const isTablet = width > 700 && width <= 1024; // Tablets
        const isDesktop = width > 1024; // Desktops
    
        if (isMobile) {
            return 'mobile';
        } else if (isTablet) {
            return 'tablet';
        } else if (isDesktop) {
            return 'desktop';
        }
    }
    
    // Usage example
    const deviceType = getDeviceType();
    //console.log(`The device type is: ${deviceType}`);
    
    // if (deviceType === 'mobile') {
    //     // Load mobile version
    // } else if (deviceType === 'tablet') {
    //     // Load tablet version
    // } else if (deviceType === 'desktop') {
    //     // Load desktop version
    // }
    //const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || (window.innerWidth <= 800 && window.innerHeight <= 600);
    return(
        <>
            {/* {(deviceType === 'mobile') || (deviceType === 'tablet') ? <DickPillMobile/> : <DickPillDesktop/>} */}
            {(deviceType === 'mobile')  ? <DickPillMobile/> : <DickPillDesktop/>}
            
        </>
    )
}

export default DickPillHome;