import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import ReactPlayer from 'react-player';
import { Link, useNavigate } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import axios from "axios";


// Css 
import '../../assests/new/frontend/css/custom2.css'
import headlineImg from '../../assests/new/frontend/images/home-headline.png';
import productGrp from '../../assests/new/frontend/images/product-grp.png';
import point1 from '../../assests/frontend/assets/images/point1.png';
import image6 from '../../assests/frontend/assets/images/image6.png';
import image7 from '../../assests/frontend/assets/images/image7.png';
import membershipNew from '../../assests/new/frontend/images/member-ship.jpg';
import mailboxNew from '../../assests/new/frontend/images/mailbox-new.png';
import cartNew from '../../assests/new/frontend/images/shopping-cart-new.png';
import useStore from '../../store';


function FrontPage2() {
    const navigate = useNavigate();
    const isLogin = useStore((state) => state.isLogin);
    const [showComponent, setShowComponent] = useState(false);
    const [country, setCountry] = useState("");
    const [loadershow, setloadershow] = useState(true);
    let url = "https://sapphiremediallc.wistia.com/medias/hvni68pyu7";
    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setShowComponent(true);
        }, 2000);
        const formScript = document.createElement('script');
        formScript.type = "text/javascript";
        formScript.async = true;
        formScript.src = "https://optin.chd01.com/uploads/1523/acquisition/builder_4/script.js";
        document.head.appendChild(formScript);

        const inlineScript = document.createElement('script');
        inlineScript.async = true;
        inlineScript.innerHTML = `
          var tag=document.createElement("script"),first_script=document.getElementsByTagName("script")[0];
          tag.async=!0;var id="get_script";
          tag.id=id;tag.src="https://api.maropost.com/accounts/1523/acquisition/builders/4/get_latest_script";
          first_script.parentNode.insertBefore(tag,first_script);
          document.getElementById(id)?.remove();
        `;
        document.head.appendChild(inlineScript);

        return () => {
            document.head.removeChild(formScript);
            document.head.removeChild(inlineScript);
        };
    }, []);


    return(
        <>
            <div id="embedded_form_4"></div>
            {/* Main Begin  */}
            <main>
                {/* Banner Begin  */}
                <section className="nwFront_banner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="bannerCont text-center">
                                    <div className="headlineImage"><img src={headlineImg} className="img-fluid" alt="" /></div>
                                    <Link to="/shop/supplements" className="btn nwBtn">Shop Now</Link>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="videoWrapper">
                                    {/* Video Box  */}
                                    {showComponent && 
                                        <div className="videoPlayer-box">
                                        <ReactPlayer
                                            url={url}
                                            config={{
                                                wistia: {
                                                    playerId: { uuid }
                                                },
                                            }}
                                        />
                                        </div>
                                    }                                
                                    {/* Video Box End */}
                                    {isLogin != 1 ?
                                        <Link to="/login" className="btn nwBtn">Login</Link>
                                    :''}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Banner End  */}

                {/* How it works  */}
                <section className="howItWorks">
                    <div className="container">
                        <div className='nw_titleDiv mb-3 mb-md-5'>
                            <h2 className='nw_title'>How It Works!</h2>
                        </div>

                        <div className='howItWork-display'>
                            <div className='row hw-row'>
                                    <div className='col-md-5'>
                                        <div className='hw-div'>
                                            <div className='hw-fig'>
                                                <figure className='hwStep-fig'>
                                                    <img src={membershipNew} className="img-fluid" alt="" />
                                                </figure>
                                            </div>
                                            <h2 className='hw-title'>
                                            Choose Our Subscription Plans
                                            </h2>
                                            <div className='hw-text'>
                                                <p>-Your subscription gives you access to the highest quality and lowest priced supplements available</p>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div className="col-md-2 hid-on-mob pointCol">
                                        <figure className="pointingImg"><img src={point1} className="img-fluid" alt="" /></figure>
                                        <figure className="pointingImg-1"><img src={image6} className="img-fluid" alt="" /></figure>
                                    </div>

                                    <div className='col-md-5'>
                                        <div className='hw-div'>
                                            <div className='hw-fig'>
                                                <figure className='hwStep-fig'>
                                                    <img src={productGrp} className="img-fluid" alt="" />
                                                </figure>
                                            </div>
                                            <h2 className='hw-title'>
                                            Select Your Product From Our Products Page
                                            </h2>
                                            <div className='hw-text'>
                                                <p>
                                                    -Indivisual Supplements and Combo Packs Available
                                                    <br></br>
                                                    -All Supplements are available in 30-days supply bottle
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                            </div>

                            <div className="row hw-row">
                                <div className="col-md-5">
                                    <div className="hw-div">
                                        <figure className="hw-fig"><img src={cartNew} className="img-fluid" alt="" /></figure>
                                        <h2 className="hw-title">Check Out</h2>
                                        <div className="hw-text">
                                            <p>
                                                -Fill Out Your Payment Information&nbsp;and click “Complete Purchase.”<br />
                                                You’re All Set!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 hid-on-mob pointCol">
                                    <figure className="pointingImg"><img src={image7} className="img-fluid" alt="" /></figure>
                                </div>
                                <div className="col-md-5">
                                    <div className="hw-div">
                                        <figure className="hw-fig"><img src={mailboxNew} className="img-fluid" alt="" /></figure>
                                        <h2 className="hw-title">Your Wholesale Health Club Products are shipped to You!</h2>
                                        <div className="hw-text">
                                            <p>
                                                -You’ll receive your first shipment soon<br />
                                                -Then come back to restock at the best price when you are running low.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className='hwStep hwStep-1 mb-3 mb-md-4'>
                            <h2 className='nw_title'>1. Choose Your Membership Plan</h2>
                            <Link to="" className="mem_plan sapphire">
                                <p>SAPPHIRE</p>
                            </Link>
                            <Link to="" className="mem_plan gold">
                                <p>Gold</p>
                            </Link>
                            <Link to="" className="mem_plan silver">
                                <p>Silver</p>
                            </Link>
                            <Link to="" className="mem_plan basic">
                                <p>Basic</p>
                            </Link>

                        </div>
                        <div className='hwStep hwStep-2 mb-3 mb-md-4'>
                            <h2 className='nw_title'>2. Select Your Products</h2>
                            <figure className='hwStep-fig'>
                                <img src={productGrp} className="img-fluid" alt="" />
                            </figure>
                            
                        </div>
                        <div className='hwStep hwStep-3 mb-3 mb-md-4'>
                            <h2 className='nw_title'>3. Check Out</h2>
                            <figure className='hwStep-fig'>
                                <img src={onlineShoping} className="img-fluid" alt="" />
                            </figure>
                            
                        </div>
                        <div className='hwStep hwStep-4'>
                            <h2 className='nw_title'>4. Your Products Are Shipped To You!</h2>
                            <figure className='hwStep-fig'>
                                <img src={mailbox} className="img-fluid" alt="" />
                            </figure>
                            
                        </div> */}
                    </div>
                </section>
                {/* How it works End */}
                
                <section className='buyMore text-center'>
                    <div className='container'>
                        {/* <Link to="" className='buyMore-cta nw_title'>BUY MORE SAVE MORE</Link> */}
                        <div class="text-center">
                            <h2 class="sv-title">BUY MORE SAVE MORE</h2>
                            <p class="sv-subtitle">FOR EVERY 5 PRODUCTS YOU BUY, YOU GET 1 FREE!</p>
                            <h2 class="sv-title">NO LONG-TERM COMMITMENTS</h2>
                            <p class="sv-subtitle">FEEL FREE TO CANCEL OUR SUBSCRIPTION AT ANY TIME</p>
                            <h2 class="sv-title">WHOLESALE HEALTH CLUB PRODUCTS</h2>
                            <p class="sv-subtitle">IF YOU’RE NOT COMPLETELY SATISFIED WITH YOUR WHOLESALE HEALTH CLUB PRODUCTS, SIMPLY RETURN YOUR UNUSED PORTION AND GET YOUR MONEY BACK, NO QUESTIONS ASKED</p>
                        </div>
                    </div>
                </section>
            </main>
            {/* Main End  */}
        </>
    );
}

export default FrontPage2

